import { AxiosResponse } from 'axios'
import { Action, ActionCreator } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'

import { IUser } from '@store/modules/users/types'
import { EmptyString, IApplicationState, ICreator, ILink, Nullable } from '@store/types/commonTypes'
import * as IAccountsActions from './typesActions'
import { IServiceCalcAlgorithm } from '@store/modules/metadata/types'
import { ObjectType } from '@const/consts'

export enum AccountsActionTypes {
  ADD_ACCOUNT = 'ADD_ACCOUNT',
  ADD_ADDRESS_ACCOUNT = 'ADD_ADDRESS_ACCOUNT',
  ADD_SUBACCOUNT = 'ADD_SUBACCOUNT',
  ADD_USER_VALID_TO_ACCOUNT = 'ADD_USER_VALID_TO_ACCOUNT',
  CLEAN_SUB_ACCOUNTS = 'CLEAN_SUB_ACCOUNTS',
  SET_ACCOUNT = 'SET_ACCOUNT',
  SET_ACCOUNTS = 'SET_ACCOUNTS',
  SET_ACCOUNT_SERVICES = 'SET_ACCOUNT_SERVICES',
  SET_ACCOUNT_SERVICE = 'SET_ACCOUNT_SERVICE',
  SET_SUBACCOUNT = 'SET_SUBACCOUNT',
  SET_SUBACCOUNTS = 'SET_SUBACCOUNTS',
  SET_USERS_ACCOUNT = 'SET_USERS_ACCOUNT',
  REMOVE_ADDRESS_ACCOUNT = 'REMOVE_ADDRESS_ACCOUNT',
  REMOVE_SUBACCOUNT = 'REMOVE_SUBACCOUNT',
  REMOVE_USER_FROM_ACCOUNT = 'REMOVE_USER_FROM_ACCOUNT',
  UPDATE_ACCOUNT = 'UPDATE_ACCOUNT',
  UPDATE_ADDRESS_ACCOUNT = 'UPDATE_ADDRESS_ACCOUNT',
  UPDATE_SUBACCOUNT = 'UPDATE_SUBACCOUNT',
  SET_ACCOUNT_CUSTOM_FIELDS = 'SET_ACCOUNT_CUSTOM_FIELDS',
  CLEAN_ACCOUNT_CUSTOM_FIELDS = 'CLEAN_ACCOUNT_CUSTOM_FIELDS',
  SET_ACCOUNT_CUSTOM_FIELD = 'SET_ACCOUNT_CUSTOM_FIELD',
  SET_ACCOUNT_ACTIVE_HANDBOOK = 'SET_ACCOUNT_ACTIVE_HANDBOOK',
  SET_ACCOUNT_DICTIONARIES = 'SET_ACCOUNT_DICTIONARIES',
  SET_OBJECT_ACCOUNT_DICTIONARIES = 'SET_OBJECT_ACCOUNT_DICTIONARIES',
  CLEAN_OBJECT_ACCOUNT_DICTIONARIES = 'CLEAN_OBJECT_ACCOUNT_DICTIONARIES'
}

export interface IAccountData {
  oguid: string
  id: number
  name: string
  users_count: number
  sub_accounts_count: number
  addresses: IAddress[]
  created_date: string
  created_user: ICreator
}

export interface IAddress {
  oguid: string
  city: string
  address: string
  pass_needed: boolean
  route: string
}

export interface IAccountsState {
  data: IAccountData[]
  sub_accounts: ISubAccount[]
  users_list: IUser[]
  users_select: IUser[]
  account_services: IAccountService[]
  account_service: IEditedAccountService
  custom_fields: ICustomField[]
  custom_field: IEditedCustomField
  active_handbook: string
  dictionaries: string[]
  object_account_dictionaries: IObjectAccountDictionary
}

export interface ISubAccount {
  oguid: string
  name: string
  users_count: number
  account: ILink
  created_date: string
  created_user: ICreator
}

export type Actions =
  | IAccountsActions.IActionAddAccount
  | IAccountsActions.IActionAddAddressAccount
  | IAccountsActions.IActionAddSubAccount
  | IAccountsActions.IActionAddUserValidToAccount
  | IAccountsActions.IActionCleanSubAccounts
  | IAccountsActions.IActionRemoveAddressAccount
  | IAccountsActions.IActionRemoveSubAccount
  | IAccountsActions.IActionRemoveUserFromAccount
  | IAccountsActions.IActionSetAccount
  | IAccountsActions.IActionSetAccounts
  | IAccountsActions.IActionSetSubAccount
  | IAccountsActions.IActionSetSubAccounts
  | IAccountsActions.IActionSetUsersAccount
  | IAccountsActions.IActionUpdateAccount
  | IAccountsActions.IActionUpdateAddressAccount
  | IAccountsActions.IActionUpdateSubAccount
  | IAccountsActions.IActionSetAccountService
  | IAccountsActions.IActionSetAccountServices
  | IAccountsActions.IActionSetAccountCustomField
  | IAccountsActions.IActionSetAccountCustomFields
  | IAccountsActions.IActionCleanAccountCustomFields
  | IAccountsActions.IActionSetAccountActiveHandbook
  | IAccountsActions.IActionSetAccountDictionaries
  | IAccountsActions.IActionSetObjectAccountDictionaries
  | IAccountsActions.IActionCleanObjectAccountDictionaries

type ActionKeys =
  | 'addAccount'
  | 'addAddressAccount'
  | 'addSubAccount'
  | 'addUserValidToAccount'
  | 'removeAddressAccount'
  | 'removeSubAccount'
  | 'removeUserFromAccount'
  | 'setAccount'
  | 'setAccounts'
  | 'setSubAccount'
  | 'setSubAccounts'
  | 'setUsersAccount'
  | 'updateAccount'
  | 'updateAddressAccount'
  | 'updateSubAccount'
  | 'setAccountService'
  | 'setAccountServices'
  | 'setAccountCustomField'
  | 'setAccountCustomFields'
  | 'setAccountActiveHandbook'
  | 'setAccountDictionaries'
  | 'setObjectAccountDictionaries'
  | 'cleanObjectAccountDictionaries'

export type AccountsActions = { [key in ActionKeys]: ActionCreator<Action<string>> }

export type AccountsThunkAction = ThunkAction<void, IAccountsState, null, Action<string>>

export type AccountsThunkDispatch = ThunkDispatch<IApplicationState, void, Action<string>>

export interface IAccountService {
  contract_name: Nullable<string>
  contract_number: Nullable<string>
  contract_date: Nullable<string>
  date_from: Nullable<number>
  date_to: Nullable<number>
  quantity: number
  min_sum: Nullable<number>
  oguid: string
  service_calc_algorithm: IServiceCalcAlgorithm
  service_code: string
  service_name: string
  service_name_invoice: Nullable<string>
  service_oguid: string
  unit_price: Nullable<number>
  unit_price_discounts: Nullable<IUnitPriceDiscount[]>
}

export interface IUnitPriceDiscount {
  description: string
  unit_price: number
  quantity_from: Nullable<number>
  quantity_to: Nullable<number>
}

export interface IUpdateAccountService {
  contract_name: Nullable<string>
  contract_number: Nullable<string>
  contract_date: Nullable<string>
  quantity: Nullable<number>
  unit_price: Nullable<number>
  min_sum: Nullable<number>
  date_from: Nullable<number>
  unit_price_discounts: Nullable<IUnitPriceDiscount[]>
  service_name_invoice: Nullable<string>
}

export interface INewAccountService extends IUpdateAccountService {
  service_oguid: string
}

export type IEditedAccountService = INewAccountService & {
  oguid: string
  service_calc_algorithm: IServiceCalcAlgorithm
  service_code: string
}

export interface IUSerAccountData {
  resp: AxiosResponse<IUSerAccountData>
  isParamsEq: boolean
}

export interface INewCustomField {
  object_type: ObjectType
  field_code: string
  field_caption: string
  field_type: FieldType
  sort_order: Nullable<number>
}

export interface IEditedCustomField {
  object_type: EmptyString<ObjectType>
  field_code: string
  field_caption: string
  field_type: FieldType | ''
  sort_order: Nullable<number>
  oguid: string
}

export interface ICustomField {
  oguid: string
  object_type: ObjectType
  field_code: string
  field_caption: string
  field_type: FieldType
  sort_order: Nullable<number>
}

export enum FieldType {
  NUMBER = 'number',
  STRING = 'string',
  DATE = 'date',
  DATETIME = 'datetime',
  BOOLEAN = 'boolean',
  DICTIONARY = 'dictionary'
}

export interface IObjectAccountDictionary {
  [key: string]: string[]
}

export interface IGetAccountsWithPagination {
  search: string
  page?: number | string
  perPageKey?: string
  filterName?: string
}
