import { Actions, IOperationsState, OperationsActionTypes } from './types'

const initialState: IOperationsState = {
  data: []
}

export function operationsReducer (state: IOperationsState = initialState, action: Actions): IOperationsState {
  switch (action.type) {
    case OperationsActionTypes.SET_OPERATIONS: {
      return { ...state, data: [...action.payload] }
    }
    default:
      return { ...state }
  }
}
