import { initialState } from '@store/modules/objects/const'
import { Actions, IObjectsState, ObjectsActionTypes } from '@store/modules/objects/types'

export function objectsReducer (state: IObjectsState = initialState, action: Actions): IObjectsState {
  switch (action.type) {
    case ObjectsActionTypes.SET_OBJECTS: {
      return { ...state, data: [...action.payload] }
    }
    case ObjectsActionTypes.RESET_OBJECTS: {
      return {
        ...state,
        data: [],
        quantity: { ...initialState.quantity },
        statuses: { ...initialState.statuses }
      }
    }
    case ObjectsActionTypes.UPDATE_OBJECTS: {
      return { ...state, updatedObjects: { ...action.payload } }
    }
    case ObjectsActionTypes.RESET_UPDATED_OBJECTS: {
      return { ...state, updatedObjects: { ...initialState.updatedObjects } }
    }
    case ObjectsActionTypes.SEND_FILE: {
      return { ...state, data: [...action.payload] }
    }
    case ObjectsActionTypes.SET_OBJECT_HISTORY: {
      return { ...state, objectHistory: [...action.payload], paginationHistory: { ...action.pagination } }
    }
    case ObjectsActionTypes.SET_STATUSES: {
      return { ...state, statuses: action.payload }
    }
    case ObjectsActionTypes.SET_QUANTITY: {
      return { ...state, quantity: action.payload }
    }
    case ObjectsActionTypes.SET_OBJECT: {
      return { ...state, editedObject: action.payload }
    }
    default:
      return { ...state }
  }
}
