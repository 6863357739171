import { INewOrganization } from '@store/modules/organizations/types'
import { AxiosResponse } from 'axios'

import AbstractHttpService from '../abstractHttpService'
import urls from '@const/urls'

import { ISuccessfulRequest } from '@store/types/commonTypes'

class OrganizationsService extends AbstractHttpService {
  private readonly url = urls.organizations

  public async addOrganization (data: INewOrganization): Promise<AxiosResponse<ISuccessfulRequest>> {
    const url = this.url.list

    return await this._http.post(url, data, {}, false)
  }

  public async updateOrganization (orgOguid: string, data: INewOrganization): Promise<AxiosResponse> {
    const url = this.url.single.replace('{{ orgOguid }}',orgOguid)

    return await this._http.put(url, data, {}, false)
  }
}

export default new OrganizationsService()
