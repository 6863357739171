import React, { FC, ReactNode, ReactNodeArray } from 'react'
import { withTranslation } from 'react-i18next'

import {
  ActionButtons,
  Ellipsis, ILibAction,
  ILibHeading,
  ILibPagination,
  LibFixedType,
  Table
} from '@infologistics/frontend-libraries'

import Pagination from '@common/PaginationWrapper'
import { ITEMS_PER_PAGE_FOR_CART, LAST_PAGE, PAGE_DEFAULT_VALUE } from '@const/consts'
import { ObjectStates, ObjectTypes } from '@const/translations'
import { getObjectTypeIcon } from '@utils/utils'
import { stateToIcon } from '@views/archive/components/Archive/Item'

import { IObject } from '@store/modules/objects/types'
import { ICartModalTableProps as IProps } from './types'

const styles = require('./CartTable.module.css')

const CartTable: FC<IProps> = ({ page, items, t, onRemove, onSwitchPagination }) => {
  const renderNotes = (notes: string): ReactNode => (notes ? <Ellipsis>{notes}</Ellipsis> : '')

  const firstHandbook = ITEMS_PER_PAGE_FOR_CART * (page !== LAST_PAGE ? +page - PAGE_DEFAULT_VALUE : PAGE_DEFAULT_VALUE)
  const lastHandbook = firstHandbook + ITEMS_PER_PAGE_FOR_CART

  const renderItems = (): ReactNodeArray => {
    return items.slice(firstHandbook, lastHandbook).map((item: IObject) => {
      const { alt_barcode, barcode, notes, object_type, state } = item

      const deleteButton: ILibAction = {
        callback: onRemove(barcode, item),
        title: t('common:buttons.delete'),
        icon: 'IconTrash'
      }

      const buttons = [deleteButton]

      return (
        <tr className={styles.row} key={barcode}>
          <td title={t(ObjectStates[state])}>{stateToIcon[state]}</td>
          <td title={t(ObjectTypes[object_type])}>{getObjectTypeIcon(object_type, t)}</td>
          <td>{barcode}</td>
          <td>{alt_barcode}</td>
          <td>
            {renderNotes(notes)}
            <ActionButtons elements={buttons} />
          </td>
        </tr>
      )
    })
  }

  const getHeadings = (): ILibHeading[] => [
    {
      class: 'text-center',
      content: t('common:status')
    },
    {
      class: 'text-center',
      content: t('common:type')
    },
    { content: t('common:barcode') },
    { content: t('common:alt_barcode') },
    { content: t('common:description') }
  ]

  const pagination: ILibPagination = {
    itemsPerPage: ITEMS_PER_PAGE_FOR_CART,
    nextPage: items.length - ITEMS_PER_PAGE_FOR_CART * +page > 0 ? +page + 1 : null,
    pageIndex: page !== LAST_PAGE ? +page : 1,
    prevPage: page !== 1 ? +page - 1 : null,
    total: items.length
  }

  return (
    <div>
      <Table headings={getHeadings()} fixedType={LibFixedType.MODAL} isUpdateTableY={true}>
        {renderItems()}
      </Table>

      {items.length > 0 && <Pagination isCart={true} pagination={pagination} changePage={onSwitchPagination} />}
    </div>
  )
}

export default withTranslation(['object', 'archive', 'common'])(CartTable)
