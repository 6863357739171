import { Action, ActionCreator } from 'redux'

import { UtilsActionTypes } from './types'
import * as IUtilsActions from './typesActions'
import { Instance } from '@const/consts'

export const toggleMenus: ActionCreator<Action> = (data: boolean): IUtilsActions.IActionToggleMenus => ({
  payload: data,
  type: UtilsActionTypes.TOGGLE_MENUS
})

export const toggleTabsLocking: ActionCreator<Action> = (): IUtilsActions.IActionTabsLocking => ({
  type: UtilsActionTypes.TOGGLE_TABS_LOCK
})

export const toggleRefreshLocking: ActionCreator<Action> = (): IUtilsActions.IActionRefreshLocking => ({
  type: UtilsActionTypes.TOGGLE_REFRESH_LOCK
})

export const setError404: ActionCreator<Action> = (): IUtilsActions.IActionSetError404 => ({
  type: UtilsActionTypes.SET_ERROR404
})

export const resetError404: ActionCreator<Action> = (): IUtilsActions.IActionResetError404 => ({
  type: UtilsActionTypes.RESET_ERROR404
})

export const setUserExist: ActionCreator<Action> = (): IUtilsActions.IActionSetUserExist => ({
  type: UtilsActionTypes.SET_USER_EXIST
})

export const resetUserExist: ActionCreator<Action> = (): IUtilsActions.IActionResetUserExist => ({
  type: UtilsActionTypes.RESET_USER_EXIST
})

export const setLanguage: ActionCreator<Action> = (language: string): IUtilsActions.IActionSetLanguage => ({
  payload: language,
  type: UtilsActionTypes.SET_LANGUAGE
})

export const setInstance: ActionCreator<Action> = (instance: Instance): IUtilsActions.IActionSetInstance => ({
  payload: instance,
  type: UtilsActionTypes.SET_INSTANCE
})
