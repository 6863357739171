import {
  Actions,
  IInitialService,
  IServiceInRequestAndOrder,
  IServicesState,
  QuantityType,
  ServicesActionTypes
} from '@store/modules/services/types'
import { calcAlgorithm } from '@store/modules/accounts/reducer'
import { EmptyString } from '@store/types/commonTypes'

export const service: IInitialService = {
  oguid: '',
  service_code: '',
  service_group: '',
  service_name: '',
  service_hint: null,
  unit: '',
  is_default: false,
  request_available: '',
  request_category_available: [],
  is_tsd_available: false,
  service_calc_algorithm: { ...calcAlgorithm },
  sort_order: '',
  quantity_type: ''
}

export const activeService: IServiceInRequestAndOrder<EmptyString<QuantityType>> = {
  service_oguid: '',
  service_code: '',
  quantity: 0,
  service_group: '',
  service_name: '',
  unit: '',
  quantity_type: ''
}

export const initialState: IServicesState = {
  data: [],
  service,
  servicesInRequestAndOrder: [],
  activeServiceInRequestAndOrder: { ...activeService },
  availableServices: []
}

export default function servicesReducer (state: IServicesState = initialState, action: Actions): IServicesState {
  switch (action.type) {
    case ServicesActionTypes.SET_SERVICES: {
      return {
        ...state,
        data: action.payload
      }
    }
    case ServicesActionTypes.SET_SERVICE: {
      return {
        ...state,
        service: action.payload
      }
    }
    case ServicesActionTypes.SET_SERVICES_IN_REQUEST_AND_ORDER: {
      return { ...state, servicesInRequestAndOrder: action.payload }
    }
    case ServicesActionTypes.SET_AVAILABLE_SERVICES: {
      return { ...state, availableServices: action.payload }
    }
    case ServicesActionTypes.SET_ACTIVE_SERVICE_IN_REQUEST_AND_ORDER: {
      return { ...state, activeServiceInRequestAndOrder: { ...action.payload } }
    }
    default: {
      return state
    }
  }
}
