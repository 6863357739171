import * as IOrganizationsActions from '@store/modules/organizations/typesActions'
import { Action, ActionCreator } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'

import { Role } from '@const/consts'
import { EmptyString, IApplicationState } from '@store/types/commonTypes'

export enum OrganizationsActionTypes {
  ADD_ORGANIZATION = 'ADD_ORGANIZATION',
  CHANGE_ORGANIZATION = 'CHANGE_ORGANIZATION',
  UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION'
}

export type Actions =
  | IOrganizationsActions.IActionAddOrganization
  | IOrganizationsActions.IActionChangeOrganization
  | IOrganizationsActions.IActionUpdateOrganization

type ActionKeys = 'addOrganization' | 'changeOrganization' | 'updateOrganization'

export type OrganizationsActions = { [key in ActionKeys]: ActionCreator<Action<string>> }

export type OrganizationsThunkAction = ThunkAction<void, IOrganizationsState, null, Action<string>>

export type OrganizationsThunkDispatch = ThunkDispatch<IApplicationState, void, Action<string>>

export interface IOrganizationsState {
  activeOrganization: IOrganization
  organizationsList: IOrganization[]
}

export interface IOrganization {
  alias: string
  inn?: string
  kpp?: string
  name: string
  oguid: string
  user_role: EmptyString<Role>
  isClientOrMasterClient: boolean
}

export interface INewOrganization {
  alias: string
  name: string
}

export interface IUpdateOrganization {
  orgOguid: string
  data: INewOrganization
}
