import { Action, ActionCreator } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'

import { IApplicationState, ILink } from '@store/types/commonTypes'

export enum OperationsActionTypes {
  SET_OPERATIONS = 'SET_OPERATIONS'
}

export interface IActionSetOperations extends Action<string> {
  payload: IOperationItem[]
  type: OperationsActionTypes.SET_OPERATIONS
}

export type Actions = IActionSetOperations

type ActionKeys = 'setOperations'

export type OperationsActions = { [key in ActionKeys]: ActionCreator<Action<string>> }

export type OperationsThunkAction = ThunkAction<void, IOperationsState, null, Action<string>>

export type OperationsThunkDispatch = ThunkDispatch<IApplicationState, void, Action<string>>

export interface IOperation {
  number: string
  order: string
  request: string
  type: string
  count: string
  subcount: string
  previous: string
  current: string
  user: {
    oguid: string
    surname: string
    name: string
    patronymic: string
  }
  sent_date: string
  objects: string[]
}

export interface IOperationItem {
  id: number
  oguid: string
  operation_type: string
  request: {
    oguid: string
    id: number
  }
  order: {
    oguid: string
    id: number
  }
  terminal: ILink
  old_destination: string
  new_destination: string
  object_quantity: number
  operation_user: {
    oguid: string
    surname: string
    name: string
    patronymic: string
    position: string
  }
  operation_date: string
  objects: string
}

export interface IOperationsState {
  data: IOperationItem[]
}

export interface INewOperation {
  operation_type: string
  destination: string
  terminal: string
  objects: string[]
}

export interface IAddOperationParams {
  order_oguid: string
}
