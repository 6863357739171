import { lazy } from 'react'

import { IRoute } from '@common/Routing/types'
import { RolesWithoutClientAndTSD, RouteName } from '@const/consts'
import getLazyComponent from '@common/lazyLoading/lazyLoadingFunctions'

const Operations = lazy(() => import('./components/Operations'))

export const routes: IRoute[] = [
  {
    component: getLazyComponent(Operations),
    exact: true,
    name: 'RequestsDetails',
    path: RouteName.OPERATIONS,
    roles: RolesWithoutClientAndTSD
  }
]
