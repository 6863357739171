import { IReportsState } from './types'
import { Actions, ReportsActionTypes } from './typesActions'

const initialState: IReportsState = {
  data: [],
  types: []
}

export function reportsReducer (state: IReportsState = initialState, action: Actions): IReportsState {
  switch (action.type) {
    case ReportsActionTypes.SET_REPORTS:
      return { ...state, data: [...action.payload] }

    case ReportsActionTypes.SET_REPORT_TYPES:
      return { ...state, types: [...action.payload] }

    default:
      return state
  }
}
