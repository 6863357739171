import { lazy } from 'react'

import { IRoute } from '@common/Routing/types'
import { RolesWithoutClientAndTSD, RouteName } from '@const/consts'
import getLazyComponent from '@common/lazyLoading/lazyLoadingFunctions'

const Barcodes = lazy(() => import('./components/Barcodes'))

export const routes: IRoute[] = [
  {
    component: getLazyComponent(Barcodes),
    exact: true,
    name: 'Barcodes',
    path: RouteName.BARCODES,
    roles: RolesWithoutClientAndTSD
  }
]
