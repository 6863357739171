import { Actions, IPaginationState, PaginationActionTypes } from './types'

export const initialState: IPaginationState = {
  itemsPerPage: 25,
  nextPage: null,
  pageIndex: 1,
  prevPage: null,
  total: 25
}

export default function paginationReducer (state: IPaginationState = initialState, action: Actions): IPaginationState {
  switch (action.type) {
    case PaginationActionTypes.RESET_PAGINATION:
      return { ...initialState }
    case PaginationActionTypes.SET_PAGINATION:
      return { ...action.payload }
    default:
      return state
  }
}
