import { cloneDeep, get } from 'lodash'
import { DateTime } from 'luxon'
import queryString, { ParsedQuery } from 'query-string'

import {
  FilterCondition,
  FilterRules,
  ILibFilter,
  ILibFilterSelectOptions,
  ILibFilters,
  ILibOption,
  ILibRangeValue,
  ILibTranslations,
  LibFilterSelectOptions,
  getSurnameWithInitials,
  getFormattedDateForDateFilter,
  getFormattedDate,
  getFormattedDateAndTime,
  getDateForServerFormat,
  TypesFilterRules,
  getFiltersOrderFromLocalStorage,
  formatDay,
  formatWeek,
  formatMonth
} from '@infologistics/frontend-libraries'
import { IApplicationState as IAppState, IGroupType, IParams, ISearchParams } from '@store/types/commonTypes'
import {
  IFillFilters,
  IFilterFromState,
  IFilterGroupedFromState,
  IGetAdditionalOptions,
  IRangeFilterOptions
} from '@utils/types'
import { BooleanOptions, Filters, DayOptions, MonthOptions, WeekOptions } from '@const/translations'
import { Day, DayValues, Month, MonthValues, Week, WeekValues } from '@const/filters'
import { FieldType, ICustomField } from '@store/modules/accounts/types'
import { BooleanValues, DAYS_IN_YEAR } from '@const/consts'
import { ISubAccount } from '@store/modules/users/types'

export const dayOptions: ILibOption[] = DayValues.map((value) => ({
  label: DayOptions[value],
  value
}))

export const monthOptions: ILibOption[] = MonthValues.map((value) => ({
  label: MonthOptions[value],
  value
}))

export const weekOptions: ILibOption[] = WeekValues.map((value) => ({
  label: WeekOptions[value],
  value
}))

export const booleanOptions: ILibOption[] = BooleanValues.map(value => ({
  label: BooleanOptions[value],
  value: value
}))

/**
 * Генерация значения с диапазоном значени "от-до".
 * Такие фильтры по диапазону, как Currency или Date, работают не с полем value,
 * а с полем RangeValue.
 * value результат парсинга части Search исходной URL строки.
 * В случае если значение from отсутствует или же пустое, то переданный value записывается в поле from.
 * Если же оно существует и имеет значение, то value записывается в to.
 * После происходит проверка.
 * Если значение to меньше значения from, что не логично, они меняются местами.
 * @param value - Значение, которое нужно добавить в диапазон.
 * @param rangeValue - исходное значение с диапазоном.
 * @param isDate - признак того, что значение фильтра является датой и его необходимо отформатировать как дату
 * @param isTime - признак того, что значение фильтра является датой-временем и его необходимо отформатировать как дату с временем
 * @param condition - условие
 */
const getRangeValue = (
  value: string | number,
  { from }: ILibRangeValue,
  condition?: string,
  isDate: boolean = false,
  isTime: boolean = false
): ILibRangeValue => {
  const tempValue = isDate || isTime
    ? getFormattedDateForDateFilter(+value, isDate)
    : value

  if (!from) return { from: tempValue, to: '' }

  if (condition === 'between') {
    if (+from < +tempValue) return { from, to: tempValue }
    return { from: tempValue, to: from }
  } else return { from: tempValue, to: '' }
}

const getArrayRangeValue = (
  values: Array<string | number>,
  isDate: boolean = false,
  isTime: boolean = false
): ILibRangeValue => {
  const [tempFrom, tempTo] = values.map((value) =>
    isDate || isTime
      ? getFormattedDateForDateFilter(+value, isDate)
      : value
    )

  return tempFrom > tempTo
    ? { from: tempTo, to: tempFrom }
    : { from: tempFrom, to: tempTo }
}

/**
 * Функция заполнения данными объекта фильтров.
 * Выполняются итерации по исходному объекту описывающий требуемые фильтры и их свойства.
 * На каждой итерации происходит вызов функций, что заполняют фильтр
 * значением фильтра, условием, переводом, отображаемым результатом и массивом опций для селекта.
 * Заполненный объект с фильтрами передается в компонент Filters для последующей работы с ним.
 * @param data - исходный объект, описывающий требуемые фильтры.
 * @param search - строка с query параметрами из search URL.
 * @param t - функция транслитерации, передается из i18next.
 * @param state - Redux state приложения, для получения данных из стор.
 * @param fields - дополнительные поля
 * @param order - массив для отображения фильтров в нужном порядке
 * @param key - ключ, по которому в localStorage хранится динамический список использованных фильтров
 * @param user - ключ, по которому в localStorage хранятся кэшированные фильтры
 * @param shouldSetAccountFromParams - признак, что аккаунт не надо заполнять из строки search
 */
export const fillFilters = async ({
  data,
  search,
  t,
  state,
  fields = [],
  order,
  key,
  user,
  shouldSetAccountFromParams = true
}: IFillFilters): Promise<ILibFilters> => {
  const filters = cloneDeep(data)
  const params = queryString.parse(search)

  /** для каждого дополнительного поля добавляем фильтр в объект с фильтрами (если нужно) */
  fields.forEach(({ field_code }) => {
    const isExist = filters.hasOwnProperty(field_code)
    !isExist && addFieldToFilters(filters, field_code, fields)
  })

  Object.keys(params).forEach(fillConditionAndValue(filters, params, order, shouldSetAccountFromParams))

  for (const name in filters) {
    if (filters.hasOwnProperty(name)) {
      const filter = filters[name]
      const { isAdditional = false, path, options, isCached } = filter

      const needToTranslate = !isAdditional || (isAdditional && fields.every(field => field.field_code !== name))

      if (needToTranslate) {
        filter.translation = t(filter.translation)
      }

      if (path ?? options) {
        filter.options = isCached ? fillOptions(filter, t, state, user) : fillOptions(filter, t, state, key)
      }

      /** Заполняем renderValue только для тех фильтров, которые есть в параметрах */
      const isFilterInParams = Object.keys(params).some(param => {
        const [filterName] = param.split('.')
        return filterName === name
      })

      if (isFilterInParams) filter.renderValue = getRenderValue(filter, t)
    }
  }

  /** сохраняем список фильтров в localStorage */
  localStorage.setItem(key, JSON.stringify(order))

  return filters
}
/**
 * Данная функция через замыкание возвращает коллбек, что будет выполняться на каждой итерации
 * по ключам объекта с query параметрами.
 * На каждом вызове возвращенной функции, происходит заполнение фильтра, соответстующего параметру.
 * Так как фильтры имеют вид filterName.condition,
 * определение условия и имени фильтра происходит путем разделения строки через точку.
 * Если нет подходящего фильтра или значения, происходит выход из функции.
 * Если есть, то определяется тип фильтра, что берется из поля rules.
 * Для упрощения, заранее подготавливаются данные для массивов, строк или диапазона.
 * Далее, в соответствии с нужным типом фильтра, происходит заполнение его значением.
 * В конце выполняется установка условия.
 * @param filters - исходный массив фильтров.
 * @param params - параметры полученный из search URL
 * @param order - массив для отображения фильтров в нужном порядке
 * @param shouldSetAccountFromParams - признак, что аккаунт не надо заполнять из строки search
 */
const fillConditionAndValue = (
  filters: ILibFilters,
  params: ParsedQuery,
  order: string[],
  shouldSetAccountFromParams: boolean = true
) => (queryKey: string) => {
  const filterKeys: string[] = Object.keys(filters)
  const [filterName, condition] = queryKey.split('.')
  const value = params[queryKey]

  const hasFilter = filterKeys.includes(filterName)
  const targetFilter = filters[filterName]

  if ((filterName === 'account_oguid' || filterName === 'sub_account_oguid' || filterName === 'object_type') && !shouldSetAccountFromParams) return

  if (!hasFilter || value === null || value === undefined || !targetFilter) return

  /** если фильтра нет в массиве для отображения фильтров в нужном порядке - добавить его туда */
  if (!order.includes(filterName)) {
    if (filterName === 'sub_account_oguid') {
      const index = order.indexOf('account_oguid')
      order.splice(index + 1, 0, filterName)
    } else {
      order.push(filterName)
    }
  }

  const type = targetFilter.rules[condition]

  const stringValue = typeof value === 'string' ? value : value.join()
  const arrayValue = typeof value === 'string' ? value.split(',') : value
  const rangeValue = targetFilter.rangeValue ?? { from: '', to: '' }

  switch (type) {
    case FilterRules.TAGS:
      targetFilter.tags = arrayValue
      break
    case FilterRules.TEXT:
      targetFilter.value = stringValue
      break
    case FilterRules.TOGGLE:
      targetFilter.value = stringValue
      break
    case FilterRules.CURRENCY:
      targetFilter.rangeValue = condition === FilterCondition.BETWEEN
        ? getArrayRangeValue(arrayValue)
        : getRangeValue(stringValue, rangeValue, condition)
      break
    case FilterRules.DATE:
      targetFilter.rangeValue = condition === FilterCondition.BETWEEN
        ? getArrayRangeValue(arrayValue, true)
        : getRangeValue(stringValue, rangeValue, condition, true)
      break
    case FilterRules.DATETIME:
      targetFilter.rangeValue = condition === FilterCondition.BETWEEN
        ? getArrayRangeValue(arrayValue, false, true)
        : getRangeValue(stringValue, rangeValue, condition, false, true)
      break
    case FilterRules.SELECT:
      targetFilter.value = stringValue
      break
    case FilterRules.MULTISELECT:
      targetFilter.values = arrayValue
      break
  }

  targetFilter.condition = targetFilter.rangeValue?.to ? FilterCondition.BETWEEN : (condition as FilterCondition)
}

/**
 * Если фильтр типа Select, то необходимо подготовить для него массив option.
 * Инициализируем option для значения "Все".
 * Далее, если у фильтра указан path, то для данного фильтра исходный массив данных берется из хранилища Redux.
 * С помощью функции get из библиотеки lodash, передаем ей state и массив, в котором описан путь.
 * Если по указанному пути ничего не найдено, вернётся пустой массив.
 * Если же массив path не указан, но указан массив options.
 * Происходит преобразование и перезапись исходного массива options, добавляя переводы, к значению каждой опции.
 * @param filter - фильтр, в который надо добавить массив опций.
 * @param state - Redux state для заполнения опций, если указан path для данного фильтра.
 * @param t - функция транслитерации, передается из i18next.
 * @param key - ключ от фильтров в localStorage
 */
const fillOptions = (
  filter: ILibFilter,
  t: (value: string) => string,
  state?: IAppState,
  key?: string
): LibFilterSelectOptions | ILibFilterSelectOptions => {
  const { path, options, isUser, isUserEmail, isCached, name } = filter

  // for cached filters
  if (path && key && isCached) {
    const cachedFilterData = window.localStorage.getItem(key)

    if (cachedFilterData) {
      const dataFromCache: IFilterFromState[] = get(JSON.parse(cachedFilterData), path, [])
      return dataFromCache.map(getOptionFromState(isUser, isUserEmail))
    }
  }

  // for filters from state
  if (path && state && !isCached) {
    const dataFromState = get(state, path, [])

    /** для фильтров по доп. полю типа dictionary или для подаккаунтов */
    if (path.includes('object_account_dictionaries')) {
      return dataFromState.map(getOptionFromDictionaryValue)
    } else if (path.includes('sub_accounts')) {
      return dataFromState.map((item: ISubAccount) => getOptionFromSubaccount(item))
    } else {
      return dataFromState.map(getOptionsFromState(isUser, isUserEmail))
    }
  }

  if (!options) {
    throw new Error(`В фильтре ${name} нет данных для опций`)
  }

  return Array.isArray(options)
    ? options.map(getOptionsAndGroups(t))
    : Object.keys(options).reduce((acc, key) => (
      {
        ...acc,
        [key]: options[key].map(getOptionsAndGroups(t))
      }
    ), {})
}

export const getOptionFromDictionaryValue = (value: string): ILibOption => ({
  value: value,
  label: value
})

export const getOptionFromSubaccount = (value: ISubAccount): ILibOption => ({
  label: value.name,
  value: value.oguid
})

/**
 * Определение типа опций (сгруппированные/несгруппированные) для фильтра типа Select, данные которого надо получить из хранилища Redux
 * и вызов функции getOptionFromState.
 * Флаги isUser и isUserEmail нужны для работы  getOptionFromState.
 * @param isUserEmail - флаг для генерации value по oguid
 * @param isUser - флаг для генерации лейбла в виде Фамилия И.О. или имени.
 */
export const getOptionsFromState = (isUser?: boolean, isUserEmail = false) => (
  filterFromState: IFilterFromState | IFilterGroupedFromState
): ILibOption | IGroupType => {
  if ('options' in filterFromState) {
    return {
      label: filterFromState.label,
      options: filterFromState.options.map(getOptionFromState(isUser, isUserEmail))
    }
  }

  return getOptionFromState(isUser, isUserEmail)(filterFromState)
}

/**
 * Создание option для фильтра типа Select, данные которого надо получить из хранилища Redux (только несгруппированные опции).
 * Если мы заполняем данными пользователя, и в объекте фильтра указано, что он работает с пользователям,
 * через флаг isUser, происходит генерация лейбла с помощью утилитарной функции, генерирующей Фамилию И.О.
 * и устанавливает значением email.
 * В другом случае лейблом устанавливается поле name,
 * значением value устанавливается oguid.
 * @param isUserEmail - флаг для генерации value по oguid
 * @param isUser - флаг для генерации лейбла в виде Фамилия И.О. или имени.
 */
export const getOptionFromState = (isUser?: boolean, isUserEmail = false) => (
  filterFromState: IFilterFromState
): ILibOption => {
  const { name, oguid, email } = filterFromState
  const label = isUser ? `${getSurnameWithInitials(filterFromState)} ${email ? `(${email})` : ''}` : name
  const value = isUserEmail && email ? email : oguid

  return { label, value }
}

/**
 * Определение типа опций (сгруппированные/несгруппированные) для фильтра типа Select, на основе стандартных данных без хранилища Redux
 * и вызов функции getOption.
 * @param t - функция транслитерации, передается из i18next.
 */
const getOptionsAndGroups = (t: (value: string) => string) => (
  option: ILibOption | IGroupType
): ILibOption | IGroupType => {
  if ('options' in option) {
    const label: string = option.label

    return {
      label: t(`${label}`),
      options: option.options.map(getOption(t))
    }
  }

  return getOption(t)(option)
}

/**
 * Генерация опции для фильтра типа Селект, на основе стандартных данных без хранилища Redux только для несгруппированных опций.
 * Выполняется мэппинг для добавления транслитерации из переводов.
 * Путь для перевода указан в поле label исходного value.
 * @param t - функция транслитерации, передается из i18next.
 */
const getOption = (t: (value: string) => string) => ({ label, value }: ILibOption): ILibOption => ({
  label: t(`${label}`),
  value
})

/**
 * Генерация значения для отображения в списке результатов фильтров.
 * В соответствии с условием, типом и значением происходит заполнение фильтра данными для отображения результата.
 * @param filter - целевой фильтр.
 * @param t - функция транслитерации, передается из i18next.
 */
const getRenderValue = (filter: ILibFilter, t: (value: string) => string): string | undefined => {
  const { rangeValue, value, tags, condition, rules, options, values } = filter

  const emptyValue = t(Filters.isNull)
  const type = rules[condition]

  /**
   * Если значение фильтра равно значению условия «Все»
   */
  if (value === FilterCondition.ALL) return

  /**
   * Если значение фильтра равно значению условия «Пусто»
   */
  if (value === FilterCondition.IS_NULL) return emptyValue

  /**
   * Если значение фильтра равно "Да"
   */
  if (value === 'true') return t('common:yes')

  /**
   * Перевод для условия
   */
  const conditionTranslation = t(Filters.result[condition])

  /**
   * Если тип фильтра Select или Multiselect, а также имеется заполненный массив options и есть выбранное/ые значение/я.
   * Функция формирует единый массив из сгруппированных и несгруппированных опций, далее ищет в нём совпадения по выбранной/ым опции/ям.
   * Если совпадение найдено, подставляем его значение, если нет, подставляем три точки.
   * Подстановка трех точек нужна в связи с тем, что у нас часть данных может быть загруженна из бека,
   * а значений переводов еще нет.
   */
  if ((type === FilterRules.SELECT || type === FilterRules.MULTISELECT) && options && (value ?? values)) {
    const organizedOptions: ILibOption[] = []

    const selectOptions = Array.isArray(options) ? options : options[condition]

    selectOptions.forEach((option: ILibOption | IGroupType) => {
      if ('options' in option) {
        organizedOptions.push(...option.options)
      }
      if ('value' in option) {
        organizedOptions.push(option)
      }
    })

    if (values) {
      const targetOptions = organizedOptions
        // Из всех опций выбираем те, что содержатся в values
        .filter(({ value }: ILibOption) => values.includes(value))
        // Восстанавливаем порядок, в каком они были в values
        .sort((a, b) => values.indexOf(a.value) - values.indexOf(b.value))

      return `${conditionTranslation} ${targetOptions.map(({ label }) => label).join(',')}`
    }

    if (value) {
      const targetOption = organizedOptions.find((option: ILibOption) => value === option.value)

      return targetOption ? `${conditionTranslation} ${targetOption.label}` : '...'
    }
  }

  /**
   * Текстовое значение и условие. Как пример "= 300"
   */
  if (value) return `${conditionTranslation} ${t(value)}`

  /**
   * Если фильтр типа Tags, то берем все значения и разделяем их запятой и пробелом.
   */
  if (tags) return `${conditionTranslation} ${tags.join(', ')}`

  /**
   * Если фильтр по диапазону, используем функцию renderRangeValue
   */
  if (rangeValue) {
    return renderRangeValue(rangeValue, t, { type, condition })
  }

  return undefined
}

/**
 * Генерация результата фильтра с диапазоном значений.
 * Если нет значения from, возвращаем undefined, значения в фильтре нет.
 * Если фильтр по дате, надо преобразовать полученные значения в отформатированную строку для дат,
 * вызова функцию getFormattedDate.
 * Если фильтр по типу Currency, то такие преобразования не нужны.
 * В случае, если фильтр имеет условие Between и значение в поле to, то возвращаем результат
 * вида "между 18.12.2020 и 31.12.2020", аналогично и для типа Currency
 * @param from - Значение "от" фильтра с диапазоном.
 * @param to - Значение "до" фильтра с диапазоном.
 * @param t - функция транслитерации, передается из i18next.
 * @param type - Тип фильтра, для определения формата значения. Для дат будет отформатированное значение,
 * для диапазона численности - строка.
 * @param condition - Условие, для подстановки верного перевода.
 */
const renderRangeValue = (
  { from, to }: ILibRangeValue,
  t: (value: string) => string,
  { type, condition }: IRangeFilterOptions
): string | undefined => {
  if (!from) return

  const isDate = type === FilterRules.DATE
  const isTime = type === FilterRules.DATETIME

  const formattedFrom = getFormatField(from, isDate, isTime)

  if ((condition === FilterCondition.BETWEEN) && to) {
    const formattedTo = getFormatField(to, isDate, isTime)
    const betweenTranslation = t(Filters.between).toLocaleLowerCase()

    // Конечная строка вида "между {значение} и {значение}"
    return `${betweenTranslation} ${formattedFrom} ${t(Filters.and)} ${formattedTo}`
  }

  const conditionTranslate = t(Filters.result[condition]).toLocaleLowerCase()

  /**
  * Если фильтр "Ранее чем", показываем значение и подпись "дней"
  */
  if (condition === FilterCondition.LESS) {
    return `${conditionTranslate} ${formattedFrom} ${t('filters:days')}`
  }

  return `${conditionTranslate} ${formattedFrom}`
}

const getFormatField = (value: number | string, isDate: boolean, isTime: boolean): number | string => {
  if (isDate) return getFormattedDate(value)
  if (isTime) return getFormattedDateAndTime(value)
  return value
}

/**
 * Функция принятия фильтров.
 * При сабмите фильтров, выполняется редьюс и генерация нового объекта с полученными данными.
 * Данная функция получает измененый объект с фильтрами, обрабатывает и возвращает новый объект подходящий,
 * для последующей передачи queryString для генерации секции Search URL текущей страницы.
 * Фильтр не попадает в результирующий объект если:
 * 1. Значение равно false;
 * 2. Значение равно пустой строке;
 * 3. Значение равно isNotNull;
 * 4. Условие равно isNotNull.
 * В другом случае генерируется объект где ключом является строка вида filterName.condition, значение подставляется
 * из rangeValue для диапазона, для tags строка из объединенных элементов массива,
 * в другом случае значение из поля value.
 * @param filters - Набор фильтров с выбранными условиями и значениями.
 * @param key - ключ, по которому в localStorage хранится список использованных фильтров
 * @return Объект параметров. Где key - названиеФильтра.условие, значение - строка.
 */
export const getParamsFromFilters = (filters: ILibFilters, key: string): IParams => {
  const filtersFromStorage = getFiltersOrderFromLocalStorage(key)

  return Object.values(filters).reduce((acc, {
    condition,
    rangeValue,
    tags,
    rules,
    value,
    values,
    name
  }) => {
    if (!filtersFromStorage.includes(name)) return acc

    const isAllValue = condition === FilterCondition.ALL || value === FilterCondition.ALL
    const type = rules[condition]
    const isDate = type === FilterRules.DATE
    const isTime = type === FilterRules.DATETIME

    if (isAllValue) return acc

    if ((value === 'false' || value === '') && !rangeValue && !tags) return acc

    if (condition === FilterCondition.BETWEEN && rangeValue) {
      const {
        from: rangeFrom,
        to: rangeTo
      } = rangeValue

      const from = rangeFrom && (isDate || isTime)
        ? getDateForServerFormat(new Date(rangeFrom), isDate)
        : rangeFrom
      const to = rangeTo && (isDate || isTime)
        ? getDateForServerFormat(new Date(rangeTo), isDate)
        : rangeTo

      if (from && to) {
        return +from > +to
          ? { ...acc, [`${name}.between`]: `${to},${from}` }
          : { ...acc, [`${name}.between`]: `${from},${to}` }
      }

      if (from) {
        return isDate
          ? { ...acc, [`${name}.after`]: from }
          : { ...acc, [`${name}.ge`]: from }
      }

      if (to) {
        return isDate
          ? { ...acc, [`${name}.before`]: to }
          : { ...acc, [`${name}.le`]: to }
      }
    }

    let tempValue

    if (tags) {
      if (!tags.length) return acc

      tempValue = tags.join(',')
    }

    if (rangeValue) {
      tempValue = isDate || isTime
        ? getDateForServerFormat(new Date(rangeValue.from), isDate)
        : rangeValue.from
    }

    if (type === FilterRules.MULTISELECT && values?.length) {
      tempValue = values.join(',')
    }

    return { ...acc, [`${name}.${condition}`]: tempValue && tempValue !== '' ? tempValue : value }
  }, {})
}

/**
 * Генерация переводов необходимых для работы фильтров.
 * @param t - функция транслитерации, передается из i18next.
 */
export const getFiltersTranslations = (t: (value: string) => string): ILibTranslations => ({
  common: {
    ge: t(Filters.ge),
    gt: t(Filters.gt),
    in: t(Filters.in),
    endWith: t(Filters.endWith),
    contains: t(Filters.contains),
    equal: t(Filters.equal),
    between: t(Filters.between),
    isnotnull: t(Filters.isNotNull),
    tday: t(Filters.tday),
    teq: t(Filters.teq),
    less: t(Filters.result.less),
    day: t(Filters.day),
    month: t(Filters.month),
    week: t(Filters.week),
    after: t(Filters.result.after),
    before: t(Filters.result.before),
    daysText: t(Filters.daysText),
    allOptionText: t(Filters.allOptionText),
    all: t(Filters.all),
    beginWith: t(Filters.beginWith),
    isnull: t(Filters.isNull),
    le: t(Filters.le),
    lt: t(Filters.lt),
    notEqual: t(Filters.notEqual),
    resetText: t(Filters.reset),
    saveFilter: t(Filters.save),
    suggestContractorText: {
      empty: t(Filters.empty),
      hint: t(Filters.choose_variant),
      inn: t(Filters.tin),
      kpp: '',
      ogrn: '',
      placeholder: t(Filters.type_name)
    },
    suggestHandbookText: {
      empty: t(Filters.handbook_text.empty),
      hint: t(Filters.handbook_text.hint),
      placeholder: t(Filters.handbook_text.placeholder)
    },
    suggestRecipientText: {
      empty: t('recipient:empty'),
      groups: t('recipient:groups'),
      placeholder: t('recipient:placeholder'),
      users: t('recipient:users')
    },
    to: t(Filters.to),
    toggleText: {
      off: t(Filters.off),
      on: t(Filters.on)
    },
    timeText: t(Filters.time),
    updateFilter: t(Filters.update)
  },
  separator: {
    radix: t(Filters.radix),
    thousand: t(Filters.thousand)
  },
  sidebar: {
    addFilter: t(Filters.addFilter),
    addFilterPlaceholder: t(Filters.selectField),
    close: t(Filters.close),
    header: t(Filters.filters),
    reset: t(Filters.reset),
    showButton: t(Filters.show_filters),
    submit: t(Filters.submit)
  }
})

export const addFieldToFilters = (filters: ILibFilters, value: string, fields: ICustomField[]): void => {
  const selectedField = fields.find(field => field.field_code === value)

  if (!selectedField) return

  const { field_type, field_caption, field_code } = selectedField

  switch (field_type) {
    case FieldType.DATE:
      filters[value] = {
        isAdditional: true,
        name: value,
        condition: FilterCondition.ALL,
        translation: field_caption,
        options: {
          [FilterCondition.DAY]: dayOptions,
          [FilterCondition.MONTH]: monthOptions,
          [FilterCondition.WEEK]: weekOptions
        },
        rules: {
          [FilterCondition.ALL]: FilterRules.ALL,
          [FilterCondition.BEFORE]: FilterRules.DATE,
          [FilterCondition.EQ]: FilterRules.DATE,
          [FilterCondition.AFTER]: FilterRules.DATE,
          [FilterCondition.BETWEEN]: FilterRules.DATE,
          [FilterCondition.DAY]: FilterRules.SELECT,
          [FilterCondition.LESS]: FilterRules.CURRENCY,
          [FilterCondition.WEEK]: FilterRules.SELECT,
          [FilterCondition.MONTH]: FilterRules.SELECT,
        },
        maxNumberValue: DAYS_IN_YEAR
      }
      break
    case FieldType.DATETIME:
      filters[value] = {
        isAdditional: true,
        name: value,
        condition: FilterCondition.ALL,
        translation: field_caption,
        rules: {
          [FilterCondition.ALL]: FilterRules.ALL,
          [FilterCondition.BETWEEN]: FilterRules.DATETIME,
          [FilterCondition.EQ]: FilterRules.DATETIME,
          [FilterCondition.LT]: FilterRules.DATETIME,
          [FilterCondition.LE]: FilterRules.DATETIME,
          [FilterCondition.GT]: FilterRules.DATETIME,
          [FilterCondition.GE]: FilterRules.DATETIME
        }
      }
      break
    case FieldType.BOOLEAN:
      filters[value] = {
        isAdditional: true,
        name: value,
        condition: FilterCondition.EQ,
        translation: field_caption,
        rules: {
          [FilterCondition.EQ]: FilterRules.TOGGLE
        }
      }
      break
    case FieldType.NUMBER:
      filters[value] = {
        isAdditional: true,
        name: value,
        condition: FilterCondition.ALL,
        translation: field_caption,
        rules: {
          [FilterCondition.ALL]: FilterRules.ALL,
          [FilterCondition.EQ]: FilterRules.TEXT,
          [FilterCondition.GT]: FilterRules.TEXT,
          [FilterCondition.GE]: FilterRules.TEXT,
          [FilterCondition.LT]: FilterRules.TEXT,
          [FilterCondition.LE]: FilterRules.TEXT,
          [FilterCondition.NE]: FilterRules.TEXT
        },
        rulesTypes: {
          [FilterCondition.EQ]: TypesFilterRules.NUMBER,
          [FilterCondition.GT]: TypesFilterRules.NUMBER,
          [FilterCondition.GE]: TypesFilterRules.NUMBER,
          [FilterCondition.LT]: TypesFilterRules.NUMBER,
          [FilterCondition.LE]: TypesFilterRules.NUMBER,
          [FilterCondition.NE]: TypesFilterRules.NUMBER
        }
      }
      break
    case FieldType.STRING:
      filters[value] = {
        isAdditional: true,
        name: value,
        condition: FilterCondition.ALL,
        rules: {
          [FilterCondition.ALL]: FilterRules.ALL,
          [FilterCondition.EQ]: FilterRules.TEXT,
          [FilterCondition.END_WITH]: FilterRules.TAGS,
          [FilterCondition.BEGIN_WITH]: FilterRules.TAGS,
          [FilterCondition.CONTAINS]: FilterRules.TEXT
        },
        translation: field_caption
      }
      break
    case FieldType.DICTIONARY:
      filters[value] = {
        isAdditional: true,
        isCached: false,
        name: value,
        condition: FilterCondition.IN,
        path: ['accounts', 'object_account_dictionaries', field_code],
        rules: {
          [FilterCondition.IN]: FilterRules.MULTISELECT
        },
        translation: field_caption
      }
      break
    default:
      break
  }
}

export const applyExtendedDateFilterParams = (params: IParams | ISearchParams): IParams =>
  (
    Object.keys(params).reduce((acc, key) => {
      const [name, condition] = key.split('.')
      const value = params[key]

      if (condition === FilterCondition.AFTER) {
        return {
          ...acc,
          [`${name}.${FilterCondition.GT}`]: value
        }
      }

      if (condition === FilterCondition.BEFORE) {
        return {
          ...acc,
          [`${name}.${FilterCondition.LT}`]: value
        }
      }

      if (condition === FilterCondition.BETWEEN && typeof value === 'string') {
        const [from, to] = value.split(',')

        return {
          ...acc,
          [`${name}.${FilterCondition.GE}`]: from,
          [`${name}.${FilterCondition.LE}`]: to
        }
      }

      if (condition === FilterCondition.LESS) {
        const date = formatDay(DateTime.now().minus({ days: Number(value) }))

        return {
          ...acc,
          [`${name}.${FilterCondition.LT}`]: getDateForServerFormat(date, true)
        }
      }

      if (condition === FilterCondition.DAY) {
        let date

        switch (value) {
          case Day.TODAY:
            date = formatDay(DateTime.now())
            break

          case Day.TOMORROW:
            date = formatDay(DateTime.now().plus({ days: 1 }))
            break

          case Day.YESTERDAY:
            date = formatDay(DateTime.now().minus({ days: 1 }))
            break
        }

        if (date) {
          return {
            ...acc,
            [`${name}.${FilterCondition.EQ}`]: getDateForServerFormat(date, true)
          }
        }
      }

      if (condition === FilterCondition.WEEK || condition === FilterCondition.MONTH) {
        let from
        let to

        switch (value) {
          case Week.THISWEEK:
            [from, to] = formatWeek(DateTime.now())
            break

          case Week.NEXTWEEK:
            [from, to] = formatWeek(DateTime.now().plus({ weeks: 1 }))
            break

          case Week.PREVWEEK:
            [from, to] = formatWeek(DateTime.now().minus({ weeks: 1 }))
            break

          case Month.THISMONTH:
            [from, to] = formatMonth(DateTime.now())
            break

          case Month.NEXTMONTH:
            [from, to] = formatMonth(DateTime.now().plus({ months: 1 }))
            break

          case Month.PREVMONTH:
            [from, to] = formatMonth(DateTime.now().minus({ months: 1 }))
            break
        }

        if (from && to) {
          return {
            ...acc,
            [`${name}.${FilterCondition.GE}`]: getDateForServerFormat(from, true),
            [`${name}.${FilterCondition.LE}`]: getDateForServerFormat(to, true)
          }
        }
      }

      return {
        ...acc,
        [key]: value
      }
    }, {})
  )

export const saveFilterToLocalStorage = (key: string, filterName: string): void => {
  const filters = getFiltersOrderFromLocalStorage(key)

  if (filters.includes(filterName)) return

  if (filterName === 'sub_account_oguid') {
    const index = filters.indexOf('account_oguid')
    filters.splice(index + 1, 0, filterName)
    localStorage.setItem(key, JSON.stringify(filters))
  } else {
    localStorage.setItem(key, JSON.stringify([...filters, filterName]))
  }
}

export const getAdditionalOptions = ({ fields = [], key }: IGetAdditionalOptions): ILibOption[] => {
  const filtersFromStorage = getFiltersOrderFromLocalStorage(key)

  /** Исключаем те поля, которые уже есть в списке */
  return fields.filter(({ field_code }) => !filtersFromStorage.includes(field_code)).map(getOptionFromFieldValue())
}

export const getOptionFromFieldValue = () => (field: ICustomField) => ({
  value: field.field_code,
  label: field.field_caption
})
