import { Actions, IUserState, UserActionTypes } from './types'

const initialState: IUserState = {
  contacts: '',
  created_date: 0,
  email: '',
  isAuthenticated: false,
  login: '',
  name: '',
  oguid: '',
  patronymic: '',
  position: '',
  status: '',
  surname: '',
  settings: {
    locale: ''
  }
}

export function userReducer (state: IUserState = initialState, action: Actions): IUserState {
  switch (action.type) {
    case UserActionTypes.CHANGE_INFO: {
      return {
        ...state,
        ...action.payload
      }
    }
    case UserActionTypes.USER_AUTHENTICATED:
      return {
        ...state,
        ...action.payload
      }
    case UserActionTypes.SET_ORGANIZATION_USER: {
      const { orgs, ...rest } = action.payload
      const user = { ...rest }

      return { ...state, ...user }
    }
    case UserActionTypes.SET_USER_SETTINGS:
      return {
        ...state,
        settings: {
          locale: action.payload.locale
        }
      }
    default:
      return state
  }
}
