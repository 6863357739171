import { Action, ActionCreator } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'

import { RequestStatus } from '@const/consts'
import { IOrder, OrderNumberField } from '@store/modules/orders/types'
import { ITerminal, UserRole } from '@store/modules/users/types'
import { IApplicationState, ICreator, Nullable } from '@store/types/commonTypes'
import * as IRequestsActions from './typesActions'

export enum RequestsActionTypes {
  ADD_COMMENT = 'ADD_COMMENT',
  ADD_REQUEST_DRAFT = 'ADD_REQUEST_DRAFT',
  CHANGE_ACTIVE_ITEM = 'CHANGE_ACTIVE_ITEM',
  DELETE_COMMENTS = 'DELETE_COMMENTS',
  DELETE_REQUEST = 'DELETE_REQUEST',
  DELETE_REQUEST_DRAFT = 'DELETE_REQUEST_DRAFT',
  SET_COMMENTS = 'SET_COMMENTS',
  GET_REQUEST = 'GET_REQUEST',
  GET_REQUEST_DRAFT = 'GET_REQUEST_DRAFT',
  SET_REQUEST_TYPES = 'SET_REQUEST_TYPES',
  SET_REQUESTS = 'SET_REQUESTS',
  RESET_OGUID = 'RESET_OGUID',
  RESET_REQUEST = 'RESET_REQUEST',
  SEND_REQUEST = 'SEND_REQUEST',
  SET_OGUID = 'SET_OGUID',
  SET_REQUEST = 'SET_REQUEST',
  SET_REQUEST_TIMESTAMP = 'SET_REQUEST_TIMESTAMP',
  TOGGLE_ERROR_FIELD = 'TOGGLE_ERROR_FIELD',
  TOGGLE_TABLE = 'TOGGLE_TABLE',
  UPDATE_REQUEST_DRAFT = 'UPDATE_REQUEST_DRAFT',
  SET_REQUEST_HISTORY = 'SET_REQUEST_HISTORY'
}

export type Actions =
  | IRequestsActions.IActionAddComment
  | IRequestsActions.IActionAddRequestDraft
  | IRequestsActions.IActionChangeActiveItem
  | IRequestsActions.IActionDeleteComments
  | IRequestsActions.IActionDeleteRequest
  | IRequestsActions.IActionDeleteRequestDraft
  | IRequestsActions.IActionSetComments
  | IRequestsActions.IActionGetRequest
  | IRequestsActions.IActionGetRequestDraft
  | IRequestsActions.IActionSetRequestTypes
  | IRequestsActions.IActionSetRequests
  | IRequestsActions.IActionSetRequest
  | IRequestsActions.IActionResetOguid
  | IRequestsActions.IActionResetRequest
  | IRequestsActions.IActionSendRequest
  | IRequestsActions.IActionSetOguid
  | IRequestsActions.IActionSetRequestTimeStamp
  | IRequestsActions.IActionToggleErrorField
  | IRequestsActions.IActionToggleTable
  | IRequestsActions.IActionUpdateRequestDraft
  | IRequestsActions.IActionSetRequestHistory


type ActionKeys =
  | 'addComment'
  | 'addRequestDraft'
  | 'changeActiveItem'
  | 'deleteComments'
  | 'deleteRequest'
  | 'deleteRequestDraft'
  | 'setComments'
  | 'getRequest'
  | 'getRequestDraft'
  | 'sendRequest'
  | 'setRequestTypes'
  | 'setRequests'
  | 'setRequest'
  | 'resetRequest'
  | 'setRequestTimeStamp'
  | 'toggleErrorField'
  | 'toggleTable'
  | 'updateRequestDraft'
  | 'setRequestHistory'

export type RequestsActions = { [key in ActionKeys]: ActionCreator<Action<string>> }

export type RequestsThunkAction = ThunkAction<void, IRequestsState, null, Action<string>>

export type RequestsThunkDispatch = ThunkDispatch<IApplicationState, void, Action<string>>

interface IRequestOrder {
  id: number
  status: string
  date: string
  assigned_to: IUser
  oguid: string
}

export interface IRequestInfo {
  oguid: string
  name: string
  hasError?: boolean
}

export interface IRequest {
  initiator: ICreator
  id: number
  oguid: string
  request_status: RequestStatus | string
  sent_date: number
  sent_user: ICreator
  accepted_date: number
  accepted_user: ICreator
  declined_date: number
  declined_user: ICreator
  executed_date: number
  executed_user: ICreator
  created_by_client: boolean
  created_date: number
  created_user: ICreator & ICreatedUser
  contact_email: string
  contact_phone: string
  contact_name: string
  current_order: IRequestOrder
  pass_is_needed: boolean
  process_is_needed: boolean
  city: string
  address: string
  notes: string
  request_type: IRequestInfo
  account: IRequestInfo & { id: number }
  sub_account: IRequestInfo
  terminal: ITerminal
  statuses: IStatuses
  draft_objects: IObjectsDraft
  last_step: number
}

export interface IRequestType {
  oguid: string
  name: string
  request_category: string
}

export interface IErrorField {
  request_type: boolean
  terminal: boolean
  account: boolean
  contact_phone: boolean
  contact_email: boolean
  contact_name: boolean
  initiator: boolean
}

export interface IRequestsState {
  activeRequest: IRequest
  comments: IComment[]
  data: IRequest[]
  errorField: IErrorField
  loadingTimestamp: number
  oguid: string
  orders: Array<IOrder<OrderNumberField>>
  types: IRequestType[]
  history: IRequestHistory[]
  draftData: IDraftData
}

export interface ISendRequestData {
  account: string
  address: string
  city: string
  contact_email: string
  contact_name: string
  contact_phone: string
  initiator: string
  notes: string
  pass_is_needed: boolean
  process_is_needed: boolean
  request_type: string
  sub_account: string
  terminal: string
}

export interface IUpdateRequestData extends ISendRequestData {
  last_step: number
}

export interface IUser {
  oguid: string
  surname: string
  name: string
  patronymic: string
  position: string
}

export interface IComment {
  oguid: string
  message: string
  p_oguid: string
  created_date: number
  created_user: IUser
  filename: Nullable<string>
}

export interface INewRequestType {
  name: string
  request_category: string
}

export interface IMessage extends FormData {
  file?: number
  message?: string
}

interface ICreatedUser {
  contacts: string
  role: UserRole | string
}

interface IStatuses {
  declared: number
  confirm: number
  by_fact: number
}

export interface IStatus {
  request_status: RequestStatus
}

export enum RequestObjectStatus {
  CONFIRM = 'confirm',
  BY_FACT = 'by_fact',
  DECLARED = 'declared'
}

export interface IRequestHistory {
  action_type: RequestStatus
  action_date: number
  action_user: ICreator
}

export interface IParsedObject {
  oguid: string
  state: string
  is_allowed: boolean
  barcode: string
  object_type: string
  alt_barcode: string
  notes: string
  parent: string
  is_connected: boolean
  parent_type: string
  location: string
  seal1: string
  seal2: string
  error_description: string
}

export interface IUpdateObjectRequestDraft {
  alt_barcode: string
  notes: string
}

export interface IAmounts {
  box: number
  document: number
  folder: number
}

export interface IRequestObject {
  alt_barcode?: Nullable<string>
  barcode: Nullable<string>
  isEdited: boolean
  notes?: Nullable<string>
  object_type: Nullable<string>
  state: Nullable<string>
  isLoading: boolean
  is_allowed?: boolean
  id: string
  parent?: string
  isInFocus: boolean
  error_description?: Nullable<string>
}

export interface IDraftData {
  activeItem: string
  activeTable: string
}

export interface IObjectsDraft {
  amounts: IAmounts
  objects: IParsedObject[]
  errors: number
}

