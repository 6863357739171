import { lazy } from 'react'

import { IRoute } from '@common/Routing/types'
import { Path, RolesWithoutTSD } from '@const/consts'
import getLazyComponent from '@common/lazyLoading/lazyLoadingFunctions'

const Archive = lazy(() => import('./components/Archive'))

export const routes: IRoute[] = [
  {
    component: getLazyComponent(Archive),
    exact: true,
    name: 'Archive',
    path: Path.ARCHIVE,
    roles: RolesWithoutTSD
  }
]
