import { AxiosError, AxiosResponse } from 'axios'
import React, { Component, ReactNode } from 'react'
import { connect } from 'react-redux'

import { getLanguageName, Loader } from '@infologistics/frontend-libraries'

import { getDataForFilters } from '@common/cache/cacheFunctions'
import {
  allDataParams,
  CachedFilter,
  Instance, Languages,
  lookupLocalStorage,
  ObjectsFilterName,
  SuccessCode
} from '@const/consts'
import * as Sentry from '@sentry/browser'
import { getBadges, getUserFilters } from '@store/modules/navigation/actions'
import { getCartObjects } from '@store/modules/objects/actions'
import { changeOrganization } from '@store/modules/organizations/actions'
import { getOuterCompanies } from '@store/modules/outer_company/actions'
import { IOrganization } from '@store/modules/organizations/types'
import { setLoaded } from '@store/modules/startup/actions'
import { actions, getUser, getUserSettings, setUserSettingsAction } from '@store/modules/user/actions'
import { getUserAccounts } from '@store/modules/users/actions'
import { IApplicationState, IAxiosErrorData, IParams } from '@store/types/commonTypes'
import * as jwt from '@utils/jwt'
import {
  checkFiltersAge, getLanguageFromInstance,
  getUserFiltersKey,
  parseOrganizationAliasOrOguid,
  showErrorNotification
} from '@utils/utils'
import { ILocalStorageCart } from '@views/archive/components/Archive/types'
import {
  IDispatchToStartupProps as IPropsFromDispatch,
  IStateToStartupProps as IPropsFromState,
  StartupProps as IProps
} from '@views/startup/components/types'
import { IUserSettingsState } from '@store/modules/user/types';
import { getServicesCalcAlgorithms } from '@store/modules/metadata/actions'
import { getReportTypes } from '@store/modules/reports/actions'
import { withTranslation} from 'react-i18next';
import { setLanguage } from '@store/modules/utils/actions';

class Startup extends Component<IProps> {
  public componentDidMount (): void {
    this.authUser()

    checkFiltersAge()
  }

  public shouldComponentUpdate (nextProps: Readonly<IProps>): boolean {
    return (
      this.props.isLoaded !== nextProps.isLoaded ||
      this.props.activeOrganization.oguid !== nextProps.activeOrganization.oguid ||
      this.props.language !== nextProps.language
    )
  }

  public componentDidUpdate (prevProps: Readonly<IProps>): void {
    if (
      (!this.props.oguid && !this.props.isAuthenticated) ||
      (!this.props.isLoaded && this.props.isLoaded !== prevProps.isLoaded)
    ) {
      this.authUser()
    }

    if (
      prevProps.activeOrganization.oguid &&
      prevProps.activeOrganization.oguid !== this.props.activeOrganization.oguid &&
      this.props.isAuthenticated
    ) {
      this.props.setLoaded(true)
    }

    if (this.props.isAuthenticated && prevProps.language !== this.props.language) {
      setLoaded(true)
    }
  }

  public render (): ReactNode {
    const { children, isLoaded } = this.props

    return isLoaded ? children : <Loader loading />
  }

  private readonly authUser = (): void => {
    const tokens = jwt.get()

    if (tokens.accessToken || tokens.refToken) {
      this.props
        .getUser()
        .then(async (resp: AxiosResponse) => {
          if (resp.status === SuccessCode.GET) {
            this.setActiveOrg()
            this.props.setAuthenticated()

            await Promise.all([
              this.props.onGetUserAccounts(this.props.oguid),
              this.props.onGetBadges(),
              this.props.onGetFilters(),
              this.props.onGetServicesCalcAlgorithms(),
              this.props.setOuterCompanies(),
            ]).catch(showErrorNotification)

            this.getUserLanguage()
            this.getUserCart()
            this.getDataForFilters()
            await this.getReportData()
          }
          return resp
        })
        .catch(this.onGettingUserError)

      Sentry.configureScope((scope: any) => {
        scope.setUser({
          email: this.props.email,
          id: this.props.oguid
        })
      })
    } else {
      this.props.setLoaded(true)
    }
  }

  private readonly getUserLanguage = (): void => {
    this.props.onGetUserSettings()
      .then(({ data }) => {
        const { i18n, instance, onSetLanguage, onSetUserSettings, locale } = this.props
        const instanceValue = localStorage.getItem(lookupLocalStorage.INSTANCE) ?? instance ?? Instance.GLOBAL
        const languageFromInstance = getLanguageFromInstance(locale, instanceValue)
        let language = data.locale || localStorage.getItem(lookupLocalStorage.LANGUAGE) || getLanguageName(navigator.language)

        if (languageFromInstance === Languages.RU) {
          language = Languages.RU
        }

        if (languageFromInstance === Languages.EN) {
          language = Languages.EN
        }

        if (languageFromInstance === Languages.PT) {
          language = Languages.PT
        }

        onSetUserSettings({ locale: language })

        if (!localStorage.getItem(lookupLocalStorage.LANGUAGE))
          localStorage.setItem(lookupLocalStorage.LANGUAGE, language)

        if (i18n.language === language)
          return

        i18n
          .changeLanguage(language)
          .then(() => {
            onSetLanguage(language)
            localStorage.setItem(lookupLocalStorage.LANGUAGE, language)
          })
          .catch(showErrorNotification)
      })
  }

  async getReportData(): Promise<void> {
    await this.props.onGetReportTypes().catch(showErrorNotification)

    return this.props.setLoaded(true)
  }

  private readonly setActiveOrg = (): void => {
    const organizationFromLocalStorage = window.localStorage.getItem('user.activeOrg')

    if (organizationFromLocalStorage) {
      const organization = this.getActiveOrganization(organizationFromLocalStorage)

      if (organization) {
        return this.setOrganization(organization)
      }
    }

    const organizationFromUrl = parseOrganizationAliasOrOguid(window.location.pathname)
    if (organizationFromUrl) {
      const organization = this.getActiveOrganization(organizationFromUrl)

      if (organization) {
        window.localStorage.setItem('user.activeOrg', organization.oguid)
        return this.setOrganization(organization)
      }
    }

    const { orgsList } = this.props
    const firstOrganizationInList = orgsList[0]

    window.localStorage.setItem('user.activeOrg', firstOrganizationInList.oguid)

    this.setOrganization(firstOrganizationInList)
  }

  private readonly setOrganization = (org: IOrganization): void => this.props.changeOrganization(org)

  private readonly getUserCart = (): void => {
    const {
      oguid,
      onGetCartObjects,
      activeOrganization: { oguid: orgOguid }
    } = this.props
    const cartContent = window.localStorage.getItem(`cart_${oguid}`)

    if (cartContent) {
      const parsedCartContent: ILocalStorageCart = JSON.parse(cartContent)

      const isOrganizationCartWrittenInLocalStorage = parsedCartContent[orgOguid]

      if (isOrganizationCartWrittenInLocalStorage?.length) {
        const params = {
          [ObjectsFilterName.BARCODE_IN]: parsedCartContent[orgOguid].toString(),
          ...allDataParams
        }

        onGetCartObjects(params).catch(showErrorNotification)
      }
    }
  }

  private readonly getActiveOrganization = (org: string): IOrganization | undefined => {
    const { orgsList } = this.props
    return (
      orgsList.find((organization: IOrganization) => organization.alias === org) ??
      orgsList.find((organization: IOrganization) => organization.oguid === org)
    )
  }

  private readonly onGettingUserError = (error: AxiosError<IAxiosErrorData>): void => {
    showErrorNotification(error)
    jwt.clear()
    this.props.setAuthenticated(false)
    this.props.setLoaded(true)
  }

  private readonly getDataForFilters = (): void => {
    const { oguid: user, activeOrganization } = this.props

    return getDataForFilters(
      [
        CachedFilter.USERS,
        CachedFilter.ACCOUNTS,
        CachedFilter.TERMINALS,
        CachedFilter.ORDER_TYPES,
        CachedFilter.REQUEST_TYPES,
        CachedFilter.GROUPS
      ],
      getUserFiltersKey({ user, organization: activeOrganization.oguid }),
      true
    )
  }
}

const mapStateToProps = (state: IApplicationState): IPropsFromState => ({
  activeOrganization: state.organizations.activeOrganization,
  email: state.user.email,
  isAuthenticated: state.user.isAuthenticated,
  isLoaded: state.app.isLoaded,
  oguid: state.user.oguid,
  orgsList: state.organizations.organizationsList,
  locale: state.user.settings.locale,
  language: state.utils.language,
  instance: state.utils.instance
})

const mapDispatchToProps = (dispatch: any): IPropsFromDispatch => ({
  changeOrganization: (organizationData: IOrganization) => dispatch(changeOrganization(organizationData)),
  setOuterCompanies: () => dispatch(getOuterCompanies()),
  getUser: () => dispatch(getUser()),
  onGetUserSettings: () => dispatch(getUserSettings()),
  onGetUserAccounts: (userOguid: string) => dispatch(getUserAccounts(userOguid)),
  onGetBadges: () => dispatch(getBadges()),
  onGetCartObjects: (params: IParams) => dispatch(getCartObjects(params)),
  onGetFilters: () => dispatch(getUserFilters()),
  onGetReportTypes: () => dispatch(getReportTypes()),
  onGetServicesCalcAlgorithms: () => dispatch(getServicesCalcAlgorithms()),
  setAuthenticated: (isAuthenticated?: boolean) => dispatch(actions.setAuthenticated(isAuthenticated)),
  setLoaded: (isLoaded: boolean) => dispatch(setLoaded(isLoaded)),
  onSetLanguage: (language: string) => dispatch(setLanguage(language)),
  onSetUserSettings: (userSettings: IUserSettingsState) => dispatch(setUserSettingsAction(userSettings))
})

const StartupClass = connect(mapStateToProps, mapDispatchToProps)(Startup)

export default withTranslation()(StartupClass)
