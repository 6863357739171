import {
  INewObject,
} from '@store/modules/objects/types'
import { AxiosRequestConfig, AxiosResponse } from 'axios'

import AbstractHttpService from '../abstractHttpService'

import urls from '@const/urls'
import { IOrder, OrderNumberField } from '@store/modules/orders/types'
import {
  IComment,
  IMessage, INewRequestType, IParsedObject,
  IRequest,
  IRequestHistory,
  IRequestType,
  ISendRequestData, IStatus, IUpdateObjectRequestDraft, IUpdateRequestData
} from '@store/modules/requests/types'
import { IRequestPatchData } from './types'
import { IParams, ISearchParams, ISuccessfulRequest } from '@store/types/commonTypes'
import { ETag } from '@common/cache/types'

class RequestsService extends AbstractHttpService {
  private readonly url = urls.requests

  public async getRequests (
    params: IParams | ISearchParams
  ): Promise<AxiosResponse<Array<IOrder<OrderNumberField>>>> {
    const url = this.url.list
    const config: AxiosRequestConfig = { params }

    return await this._http.get(url, config)
  }

  public async getRequestDraft (params: IParams): Promise<AxiosResponse<IRequest>> {
    const url = this.url.draft.base
    const config: AxiosRequestConfig = { params }

    return await this._http.get(url, config)
  }

  public async getRequest (requestOguid: string): Promise<AxiosResponse<IRequest>> {
    const url = this.url.single.replace('{{ oguid }}', requestOguid)

    return await this._http.get(url)
  }

  public async addRequestDraft (data: ISendRequestData): Promise<AxiosResponse<ISuccessfulRequest>> {
    const url = this.url.draft.base

    return await this._http.post(url, data)
  }

  public async sendRequest (): Promise<AxiosResponse<ISuccessfulRequest>> {
    const url = this.url.draft.send

    return await this._http.post(url)
  }

  public async updateRequestDraft (data: IUpdateRequestData): Promise<AxiosResponse<ISuccessfulRequest>> {
    const url = this.url.draft.base

    return await this._http.put(url, data)
  }

  public async deleteRequestDraft (): Promise<AxiosResponse<ISuccessfulRequest>> {
    const url = this.url.draft.base

    return await this._http.delete(url)
  }

  public async deleteRequest (requestOguid: string): Promise<AxiosResponse<ISuccessfulRequest>> {
    const url = this.url.single.replace('{{ oguid }}', requestOguid)

    return await this._http.delete(url)
  }

  public async getRequestOrders (
    requestOguid: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<Array<IOrder<OrderNumberField>>>> {
    const url = this.url.orders.replace('{{ request_oguid }}', requestOguid)

    return await this._http.get(url, config)
  }

  public async getRequestTypes (params: IParams, headers?: ETag): Promise<AxiosResponse<IRequestType[]>> {
    const url = this.url.types.list

    return await this._http.get(url, { params, headers })
  }

  public async addRequestType (data: INewRequestType): Promise<AxiosResponse<ISuccessfulRequest>> {
    const url = this.url.types.list

    return await this._http.post(url, data)
  }

  public async editRequestType (requestTypeOguid: string, data: INewRequestType): Promise<AxiosResponse<ISuccessfulRequest>> {
    const url = this.url.types.single.replace('{{ oguid }}', requestTypeOguid)

    return await this._http.put(url, data)
  }

  public async deleteRequestType (requestTypeOguid: string): Promise<AxiosResponse<ISuccessfulRequest>> {
    const url = this.url.types.single.replace('{{ oguid }}', requestTypeOguid)

    return await this._http.delete(url)
  }

  public async getHistory (requestOguid: string): Promise<AxiosResponse<IRequestHistory[]>> {
    const url = this.url.history.replace('{{ request_oguid }}', requestOguid)

    return await this._http.get(url)
  }

  public async patchRequest (requestOguid: string, data: IRequestPatchData): Promise<AxiosResponse> {
    const url = this.url.single.replace('{{ oguid }}', requestOguid)

    return await this._http.patch(url, data)
  }

  public async getComments (requestOguid: string, params: IParams): Promise<AxiosResponse<IComment[]>> {
    const url = this.url.messages.list.replace('{{ request_oguid }}', requestOguid)
    const config: AxiosRequestConfig = { params }

    return await this._http.get(url, config)
  }

  public async addComment (requestOguid: string, data: IMessage): Promise<AxiosResponse<ISuccessfulRequest>> {
    const url = this.url.messages.list.replace('{{ request_oguid }}', requestOguid)

    return await this._http.post(url, data)
  }

  public async deleteComment (requestOguid: string, messageOguid: string): Promise<AxiosResponse<ISuccessfulRequest>> {
    const url = this.url.messages.single
      .replace('{{ request_oguid }}', requestOguid)
      .replace('{{ message_oguid }}', messageOguid)

    return await this._http.delete(url)
  }

  public async getCommentFile (requestOguid: string, commentOguid: string): Promise<AxiosResponse<Blob>> {
    const url = this.url.messages.file
      .replace('{{ request_oguid }}', requestOguid)
      .replace('{{ message_oguid }}', commentOguid)
    const config: AxiosRequestConfig = { responseType: 'blob' }

    return await this._http.get(url, config)
  }

  public async requestStatusUpdate (requestOguid: string, data: IStatus): Promise<AxiosResponse<ISuccessfulRequest>> {
    const url = this.url.status.replace('{{ oguid }}', requestOguid)

    return await this._http.put(url, data)
  }

  public async addObjectsToRequestDraft (data: INewObject[]): Promise<AxiosResponse<IParsedObject[]>> {
    const url = this.url.draft.draft_objects.list

    return await this._http.post(url, data)
  }

  public async deleteObjectsFromRequestDraft (data: string[]): Promise<AxiosResponse<ISuccessfulRequest>> {
    const url = this.url.draft.draft_objects.list

    return await this._http.delete(url, { data })
  }

  public async addObjectsChildToRequestDraft (data: INewObject[]): Promise<AxiosResponse<IParsedObject[]>> {
    const url = this.url.draft.draft_objects.child

    return await this._http.post(url, data)
  }

  public async updateObjectRequestDraft (objectOguid: string, data: IUpdateObjectRequestDraft): Promise<AxiosResponse<ISuccessfulRequest>> {
    const url = this.url.draft.draft_objects.single.replace('{{ object_oguid }}', objectOguid)

    return await this._http.put(url, data)
  }

  public async deleteObjectFromRequestDraft (objectOguid: string): Promise<AxiosResponse<ISuccessfulRequest>> {
    const url = this.url.draft.draft_objects.single.replace('{{ object_oguid }}', objectOguid)

    return await this._http.delete(url)
  }

  public async exportRequests (params: ISearchParams): Promise<AxiosResponse<Blob>> {
    const url = this.url.excel
    const config: AxiosRequestConfig = { responseType: 'blob', params }

    return await this._http.get(url, config)
  }
}

export default new RequestsService()
