import { Canceler } from 'axios'
import * as IUtilsActions from './typesActions'
import { Instance } from '@const/consts'

export enum UtilsActionTypes {
  RESET_ERROR404 = 'RESET_ERROR404',
  RESET_USER_EXIST = 'RESET_USER_EXIST',
  SET_ERROR404 = 'SET_ERROR404',
  SET_INSTANCE = 'SET_INSTANCE',
  SET_LANGUAGE = 'SET_LANGUAGE',
  SET_USER_EXIST = 'SET_USER_EXIST',
  TOGGLE_MENUS = 'TOGGLE_MENUS',
  TOGGLE_REFRESH_LOCK = 'TOGGLE_REFRESH_LOCK',
  TOGGLE_TABS_LOCK = 'TOGGLE_TABS_LOCK'
}

export type Actions =
  | IUtilsActions.IActionSetUserExist
  | IUtilsActions.IActionToggleMenus
  | IUtilsActions.IActionTabsLocking
  | IUtilsActions.IActionRefreshLocking
  | IUtilsActions.IActionResetUserExist
  | IUtilsActions.IActionSetError404
  | IUtilsActions.IActionResetError404
  | IUtilsActions.IActionSetInstance
  | IUtilsActions.IActionSetLanguage

export interface IUtilsState {
  areMenusShown: boolean
  instance: Instance
  isTabsLocked: boolean
  isRefreshLocked: boolean
  isError404: boolean
  isUserExist: boolean
  language: string
}

export interface ICanceler {
  canceler: Canceler
  id: string
}
