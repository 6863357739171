import { AxiosRequestConfig, AxiosResponse } from 'axios'

import AbstractHttpService from '../abstractHttpService'

import urls from '@const/urls'
import { IName, IParams, ISuccessfulRequest } from '@store/types/commonTypes'

import {
  IAccountService,
  ICustomField,
  INewAccountService,
  INewCustomField,
  IUpdateAccountService,
  IGetAccountsWithPagination,
  IAccountData, ISubAccount, IAddress
} from '@store/modules/accounts/types'
import { ETag } from '@common/cache/types'

class AccountsService extends AbstractHttpService {
  private readonly url = urls.accounts

  public async getAccounts (params: IParams, headers?: ETag): Promise<AxiosResponse<IAccountData[]>> {
    const url = this.url.list

    return await this._http.get(url, { params, headers })
  }

  public async getAccountsWithPagination (params: IGetAccountsWithPagination): Promise<AxiosResponse<IAccountData[]>> {
    const { filterName } = params
    const url = `${this.url.list}${filterName ? `?name.like=${filterName}` : ''}`

    return await this._http.get(url, { params })
  }

  public async getAccount (accountOguid: string): Promise<AxiosResponse<IAccountData>> {
    const url = this.url.single.replace('{{ account_oguid }}', accountOguid)

    return await this._http.get(url)
  }

  public async addAccount (data: IAccountData): Promise<AxiosResponse<ISuccessfulRequest>> {
    const url = this.url.list

    return await this._http.post(url, data)
  }

  public async updateAccount (accountOguid: string, data: IAccountData): Promise<AxiosResponse> {
    const url = this.url.single.replace('{{ account_oguid }}', accountOguid)

    return await this._http.put(url, data)
  }

  public async removeAccount (accountOguid: string): Promise<AxiosResponse> {
    const url = this.url.single.replace('{{ account_oguid }}', accountOguid)

    return await this._http.delete(url)
  }

  public async addUserValidToAccount (accountOguid: string, data: string[]): Promise<AxiosResponse> {
    const url = this.url.users.list
      .replace('{{ account_oguid }}', accountOguid)

    return await this._http.post(url, data)
  }

  public async removeUserFromAccount (accountOguid: string, userOguid: string): Promise<AxiosResponse> {
    const url = this.url.users.single
      .replace('{{ account_oguid }}', accountOguid)
      .replace('{{ user_oguid }}', userOguid)

    return await this._http.delete(url)
  }

  public async addAddressAccount (accountOguid: string, data: IAddress): Promise<AxiosResponse<ISuccessfulRequest>> {
    const url = this.url.addresses.list.replace('{{ account_oguid }}', accountOguid)

    return await this._http.post(url, data)
  }

  public async updateAddressAccount (accountOguid: string, addressOguid: string, data: IAddress): Promise<AxiosResponse> {
    const url = this.url.addresses.single
      .replace('{{ account_oguid }}', accountOguid)
      .replace('{{ address_oguid }}', addressOguid)

    return await this._http.put(url, data)
  }

  public async removeAddressAccount (accountOguid: string, addressOguid: string): Promise<AxiosResponse> {
    const url = this.url.addresses.single
      .replace('{{ account_oguid }}', accountOguid)
      .replace('{{ address_oguid }}', addressOguid)

    return await this._http.delete(url)
  }

  public async getSubAccounts (accountOguid: string, params: IParams): Promise<AxiosResponse<ISubAccount[]>> {
    const url = this.url.sub_accounts.list.replace('{{ account_oguid }}', accountOguid)

    return await this._http.get(url, { params })
  }

  public async getSubAccount (accountOguid: string, subAccountOguid: string): Promise<AxiosResponse<ISubAccount>> {
    const url = this.url.sub_accounts.single
      .replace('{{ account_oguid }}', accountOguid)
      .replace('{{ sub_account_oguid }}', subAccountOguid)

    return await this._http.get(url)
  }

  public async addSubAccount (accountOguid: string, data: ISubAccount): Promise<AxiosResponse<ISuccessfulRequest>> {
    const url = this.url.sub_accounts.list.replace('{{ account_oguid }}', accountOguid)

    return await this._http.post(url, data)
  }

  public async updateSubAccount (accountOguid: string, subAccountOguid: string, data: IName): Promise<AxiosResponse> {
    const url = this.url.sub_accounts.single
      .replace('{{ account_oguid }}', accountOguid)
      .replace('{{ sub_account_oguid }}', subAccountOguid)

    return await this._http.put(url, data)
  }

  public async removeSubAccount (accountOguid: string, subAccountOguid: string): Promise<AxiosResponse> {
    const url = this.url.sub_accounts.single
      .replace('{{ account_oguid }}', accountOguid)
      .replace('{{ sub_account_oguid }}', subAccountOguid)

    return await this._http.delete(url)
  }

  public async getAccountServices (
    accountOguid: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<IAccountService[]>> {
    const url = this.url.services.list.replace('{{ account_oguid }}', accountOguid)

    return await this._http.get(url, config)
  }

  public async addAccountService (
    accountOguid: string,
    data: INewAccountService
  ): Promise<AxiosResponse<ISuccessfulRequest>> {
    const url = this.url.services.list.replace('{{ account_oguid }}', accountOguid)

    return await this._http.post(url, data)
  }

  public async editAccountService (
    accountOguid: string,
    serviceOguid: string,
    data: IUpdateAccountService
  ): Promise<AxiosResponse<ISuccessfulRequest>> {
    const url = this.url.services.single
      .replace('{{ account_oguid }}', accountOguid)
      .replace('{{ service_oguid }}', serviceOguid)

    return await this._http.put(url, data)
  }

  public async deleteAccountService (
    accountOguid: string,
    serviceOguid: string
  ): Promise<AxiosResponse<ISuccessfulRequest>> {
    const url = this.url.services.single
      .replace('{{ account_oguid }}', accountOguid)
      .replace('{{ service_oguid }}', serviceOguid)

    return await this._http.delete(url)
  }

  public async getAccountCustomFields (
    accountOguid: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<ICustomField[]>> {
    const url = this.url.custom_fields.list.replace('{{ account_oguid }}', accountOguid)

    return await this._http.get(url, config)
  }

  public async addAccountCustomField (
    accountOguid: string,
    data: INewCustomField
  ): Promise<AxiosResponse<ISuccessfulRequest>> {
    const url = this.url.custom_fields.list.replace('{{ account_oguid }}', accountOguid)

    return await this._http.post(url, data)
  }

  public async editAccountCustomField (
    accountOguid: string,
    customFieldOguid: string,
    data: INewCustomField
  ): Promise<AxiosResponse<ISuccessfulRequest>> {
    const url = this.url.custom_fields.single
      .replace('{{ account_oguid }}', accountOguid)
      .replace('{{ custom_field_oguid }}', customFieldOguid)

    return await this._http.put(url, data)
  }

  public async deleteAccountCustomField (
    accountOguid: string,
    customFieldOguid: string
  ): Promise<AxiosResponse<ISuccessfulRequest>> {
    const url = this.url.custom_fields.single
      .replace('{{ account_oguid }}', accountOguid)
      .replace('{{ custom_field_oguid }}', customFieldOguid)

    return await this._http.delete(url)
  }

  public async getCustomFieldDictionaries (
    accountOguid: string,
    customFieldCode: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<string[]>> {
    const url = this.url.dictionaries.list
      .replace('{{ account_oguid }}', accountOguid)
      .replace('{{ custom_field_code }}', customFieldCode)

    return await this._http.get(url, config)
  }

  public async addCustomFieldDictionaries (
    accountOguid: string,
    customFieldCode: string,
    data: string[]
  ): Promise<AxiosResponse<string[]>> {
    const url = this.url.dictionaries.list
      .replace('{{ account_oguid }}', accountOguid)
      .replace('{{ custom_field_code }}', customFieldCode)

    return await this._http.post(url, data)
  }

  public async deleteCustomFieldDictionaries (accountOguid: string, customFieldCode: string): Promise<AxiosResponse> {
    const url = this.url.dictionaries.list
      .replace('{{ account_oguid }}', accountOguid)
      .replace('{{ custom_field_code }}', customFieldCode)

    return await this._http.delete(url)
  }

  public async deleteCustomFieldDictValue (
    accountOguid: string,
    customFieldCode: string,
    customFieldDictValue: string
  ): Promise<AxiosResponse> {
    const url = this.url.dictionaries.single
        .replace('{{ account_oguid }}', accountOguid)
        .replace('{{ custom_field_code }}', customFieldCode)
        .replace('{{ custom_field_dict_value }}', customFieldDictValue)

    return await this._http.delete(url)
  }
}

export default new AccountsService()
