import { Actions, IModalState, ModalActionTypes } from './types'

const initialState: IModalState = {
  id: ''
}

export function modalReducer (state: IModalState = initialState, action: Actions): IModalState {
  switch (action.type) {
    case ModalActionTypes.SHOW_MODAL: {
      return { id: action.payload }
    }
    case ModalActionTypes.HIDE_MODAL: {
      return initialState
    }
    default:
      return state
  }
}
