import { IObject } from '@store/modules/objects/types'
import { ICustomField } from '@store/modules/accounts/types'

export interface IArchiveObjectHistoryState {
  isLoading: boolean
}

export interface IIconProps {
  hint: string
  externalClass?: string
  classes?: string
  type: string
}

interface IObjectModalProps {
  onClear: () => void
  onRefreshPage: () => void
  inArchive?: boolean
  objectOguid: string
  setIsHideModal: (isHideModal: boolean) => void
}

export interface IStateToObjectModalProps {
  role: string
  object: IObject
  accountCustomFields: ICustomField[]
}

export type AllObjectModalProps = IObjectModalProps & IStateToObjectModalProps

export enum ObjectTabName {
  INFORMATION = 'information',
  CHANGE = 'change',
  HISTORY = 'history'
}
