import React, { FC, KeyboardEvent, MouseEvent, ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMount } from 'react-use'
import { useSelector } from 'react-redux'

import CustomNavLink from '@common/navigation/components/CustomLinks/CustomNavLink'
import { MouseEventType } from '@const/consts'
import { LinkContent } from '@infologistics/frontend-libraries'
import { createUrl, getBadgeValue } from '@utils/utils'
import { AllServiceParams, IBadges } from '@store/modules/navigation/types'
import { IApplicationState } from '@store/types/commonTypes'
import { INavItem } from '@const/navSidebar'
import { useHistory, useRouteMatch } from 'react-router'

interface INavMenuItemProps {
  item: INavItem
}

const NavMenuItem: FC<INavMenuItemProps> = (props: INavMenuItemProps) => {
  const { item } = props
  const { badge, icon, items, title, route } = item
  const badges: IBadges = useSelector((state: IApplicationState) => state.navigation.badges)
  const { t } = useTranslation()
  const match = useRouteMatch<AllServiceParams>()
  const history = useHistory<AllServiceParams>()

  const [newRoute, setNewRoute] = useState('')

  useMount(() => {
    setRoute()
  })

  const setRoute = (): void => {
    const firstNestedMenuRoute = items?.[0]?.route
    setNewRoute(firstNestedMenuRoute ?? route)
  }

  const renderLinkContent = (): ReactElement => {
    const badgeProps = badge && {
      theme: badge.theme ?? 'default',
      value: getBadgeValue(badges, badge)
    }
    const badgeData = badgeProps?.value !== '0' ? badgeProps : undefined

    return <LinkContent title={t(title)} iconBefore={icon} iconBeforeProps={{ classes: 'mr-2' }} badgeProps={badgeData} />
  }

  const handleOpenMenuList = (e: MouseEvent | KeyboardEvent): void => {
    const menuItemHasChildren = !!item.items
    const url = createUrl(match, newRoute)

    if (menuItemHasChildren || e.type === MouseEventType.AUXCLICK) {
      e.preventDefault()
      history.push(url)
    }
  }

  const linkProps = {
    onAuxClick: handleOpenMenuList,
    onClick: handleOpenMenuList,
    title: t(title),
    to: newRoute
  }

  return <CustomNavLink {...linkProps}>{renderLinkContent()}</CustomNavLink>
}

export default NavMenuItem
