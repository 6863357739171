import { Action, ActionCreator } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'

import { EmptyString, IApplicationState, Nullable } from '@store/types/commonTypes'

import { Role, UserStatusesEnum } from '@const/consts'
import * as IUserActions from './typesActions'
import { IOrganization } from '@store/modules/organizations/types'

export enum UserActionTypes {
  CHANGE_INFO = 'CHANGE_INFO',
  SET_ORGANIZATIONS = 'SET_ORGANIZATIONS',
  SET_ORGANIZATION_USER = 'SET_ORGANIZATION_USER',
  USER_AUTHENTICATED = 'USER_AUTHENTICATED',
  SET_USER_SETTINGS = 'SET_USER_SETTINGS'
}

export type Actions =
  | IUserActions.IActionChangeInfo
  | IUserActions.IActionUserAuthenticated
  | IUserActions.IActionSetOrganizationUser
  | IUserActions.IActionSetOrganizations
  | IUserActions.IActionSetUserSettings

type ActionKeys = 'changeInfo' | 'setAuthenticated' | 'setOrganizations' | 'setOrganizationUser' | 'setUserSettings'

export type UserActions = { [key in ActionKeys]: ActionCreator<Action<string>> }

export type UserThunkAction = ThunkAction<void, IUserState, void, Action<string>>

export type UserThunkDispatch = ThunkDispatch<IApplicationState, void, Action<string>>

export interface IUserState {
  contacts: string
  created_date: number
  email: string
  isAuthenticated: boolean
  login: string
  name: string
  oguid: string
  patronymic: string
  position: string
  status: string
  surname: string
  settings: IUserSettingsState
}

export interface IUserSettingsState {
  locale: string
}

export interface INewUser {
  contacts: string
  email: string
  login?: string
  role: EmptyString<Role>
  name: string
  password?: Nullable<string>
  patronymic: string
  position: string
  send_invite?: boolean
  status: UserStatusesEnum
  surname: string
}

export interface IUserWithOrganizations {
  oguid: string
  org: string
  login: string
  email: string
  surname: string
  name: string
  patronymic: string
  username: string
  contacts: string
  orgs: IOrganization[]
}
