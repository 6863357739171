import axios, { AxiosResponse } from 'axios'
import jwtDecode from 'jwt-decode'
import { CookieSetOptions } from 'universal-cookie'

import cookies from '@utils/cookies'

import { AUTH_URL } from '@const/consts'

export const get = (): any => {
  let expireAccess = 0
  let expireRef = 0
  const accessToken = cookies.get('access')
  const refToken = cookies.get('refresh')

  if (accessToken) {
    const decodedAcc: any = jwtDecode(accessToken)
    expireAccess = decodedAcc.expire
  }

  if (refToken) {
    const decodedRef: any = jwtDecode(refToken)
    expireRef = decodedRef.expire
  }

  return {
    accessToken,
    expireAccess,
    expireRef,
    refToken
  }
}

export const clear = (): void => {
  cookies.remove('access', { path: '/' })
  cookies.remove('refresh', { path: '/' })
}

export const set = (access: string, refresh: string, isSessionCookie = false): void => {
  const options = {
    path: '/'
  }

  const decodedAcc: any = jwtDecode(access)
  const decodedRef: any = jwtDecode(refresh)
  const expireAccess = decodedAcc.expire
  const expireRef = decodedRef.expire
  const accessOptions: CookieSetOptions = Object.assign({}, options)
  const refreshOptions: CookieSetOptions = Object.assign({}, options)

  if (isSessionCookie) {
    accessOptions.expires = new Date(expireAccess)
    refreshOptions.expires = new Date(expireRef)
  }

  cookies.set('access', access, accessOptions)
  cookies.set('refresh', refresh, refreshOptions)
}

export const refreshToken = (): Promise<AxiosResponse> => {
  return axios({
    data: {
      refresh: get().refToken
    },
    headers: {
      'Content-type': 'application/json'
    },
    method: 'POST',
    url: `${AUTH_URL}token-refresh/`
  })
    .then((resp: any) => {
      if (resp.status === 200) {
        set(resp.data.access, resp.data.refresh)
      } else {
        clear()
        document.location.reload()

        return Promise.reject()
      }

      return resp
    })
    .catch((e: any) => {
      clear()
      document.location.reload()
      return Promise.reject(e)
    })
}
