import { AxiosResponse } from 'axios'
import AbstractHttpService from '../abstractHttpService'

import urls from '@const/urls'
import { INewFilter } from '@store/modules/navigation/types'

class FiltersService extends AbstractHttpService {
  private readonly url = urls.filters

  public async getUserFilters (): Promise<AxiosResponse> {
    const url = this.url.list

    return await this._http.get(url)
  }

  public async addUserFilter (filterData: INewFilter): Promise<AxiosResponse> {
    const url = this.url.list

    return await this._http.post(url, filterData)
  }

  public async editUserFilter (filterOguid: string, filterData: INewFilter): Promise<AxiosResponse> {
    const url = this.url.single.replace('{{ oguid }}', filterOguid)

    return await this._http.put(url, filterData)
  }

  public async deleteUserFilter (filterOguid: string): Promise<AxiosResponse> {
    const url = this.url.single.replace('{{ oguid }}', filterOguid)

    return await this._http.delete(url)
  }
}

export default new FiltersService()
