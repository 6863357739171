import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { useHistory, useLocation } from 'react-router'

import { Role, RouteName } from '@const/consts'
import { createUrl, getRouteFromMenuItems } from '@utils/utils'

import { EmptyString, IApplicationState } from '@store/types/commonTypes'
import { AllServiceParams } from '@store/modules/navigation/types'

const RedirectToStartPage: FC = () => {
  const authorized: boolean = useSelector((state: IApplicationState) => state.user.isAuthenticated)
  const userRole: EmptyString<Role> = useSelector((state: IApplicationState) => state.organizations.activeOrganization.user_role)
  const alias: string = useSelector((state: IApplicationState) => state.organizations.activeOrganization.alias)
  const oguid: string = useSelector((state: IApplicationState) => state.organizations.activeOrganization.oguid)
  const history = useHistory<AllServiceParams>()
  const { pathname, search } = useLocation<AllServiceParams>()
  const currentLocation = `${pathname}${search}`

  if (!authorized) {
    return (
      <Redirect
        to={{
          pathname: RouteName.SIGN_IN,
          state: { from: currentLocation }
        }}
      />
    )
  }

  const section = getRouteFromMenuItems(userRole)

  history.push(createUrl(alias || oguid, section))

  return null
}

export default RedirectToStartPage
