export default {
  accounts: {
    addresses: {
      list: '/accounts/{{ account_oguid }}/addresses',
      single: '/accounts/{{ account_oguid }}/addresses/{{ address_oguid }}'
    },
    services: {
      list: '/accounts/{{ account_oguid }}/account_services',
      single: '/accounts/{{ account_oguid }}/account_services/{{ service_oguid }}'
    },
    custom_fields: {
      list: '/accounts/{{ account_oguid }}/fields',
      single: '/accounts/{{ account_oguid }}/fields/{{ custom_field_oguid }}'
    },
    dictionaries: {
      list: '/accounts/{{ account_oguid }}/fields/{{ custom_field_code }}/dictionaries/values',
      single: '/accounts/{{ account_oguid }}/fields/{{ custom_field_code }}/dictionaries/values/{{ custom_field_dict_value }}'
    },
    list: '/accounts',
    single: '/accounts/{{ account_oguid }}',
    sub_accounts: {
      list: '/accounts/{{ account_oguid }}/sub_accounts',
      single: '/accounts/{{ account_oguid }}/sub_accounts/{{ sub_account_oguid }}'
    },
    users: {
      list: '/accounts/{{ account_oguid }}/users',
      single: '/accounts/{{ account_oguid }}/users/{{ user_oguid }}'
    }
  },
  auth: {
    signin: '/signin',
    signup: '/signup',
    password_reset: '/password-reset'
  },
  barcodes: {
    list: '/barcodes',
    single: '/barcodes/{{ oguid }}'
  },
  filters: {
    list: '/userFilters',
    single: '/userFilters/{{ oguid }}'
  },
  objects: {
    file: {
      list: '/objects/file',
      single: '/objects/{{ oguid }}/file',
      template: '/objects/file/template'
    },
    history: '/objects/{{ oguid }}/history',
    list: '/objects',
    single: '/objects/{{ oguid }}'
  },
  operations: {
    list: '/operations',
    single: '/operations/{{ operation_oguid }}'
  },
  organizations: {
    list: 'orgs/',
    single: 'orgs/{{ orgOguid }}'
  },
  orders: {
    available_services: '/orders/{{ oguid }}/available_services',
    history: '/orders/{{ order_oguid }}/history',
    list: '/orders',
    notifications: '/orders/{{ order_oguid }}/notifications',
    objects: '/orders/{{ oguid }}/objects',
    operations: {
        dashboard: '/orders/{{ oguid }}/operations_dashboard',
        details: '/orders/{{ oguid }}/operations_dashboard_details'
      },
    planned_operations: {
      list: '/order_types/{{ order_type_oguid }}/planned_operations_maker',
      maker: '/planned_operations_maker/{{ maker_oguid }}',
      single: '/order_types/{{ order_type_oguid }}/planned_operations_maker/{{ maker_oguid }}'
    },
    services: {
      list: '/orders/{{ oguid }}/services',
      single: '/orders/{{ oguid }}/services/{{ service_oguid }}'
    },
    single: '/orders/{{ oguid }}',
    state: '/orders/{{ order_oguid }}/state',
    types: {
      list: '/order/types',
      single: '/order/types/{{ order_type_oguid }}'
    },
    excel: '/orders_excel',
  },
  contractors: {
    list: '/outer_companies',
    single: '/outer_companies/{{ outer_company_oguid }}',
  },
  reports: {
    list: {
      report_types: '/report/report_types',
      reports: '/report/{{ report_type_oguid }}/reports'
    },
    single: {
      file: '/report/{{ report_oguid }}/file',
      new_report: '/report',
      delete: '/report/{{ report_oguid }}'
    }
  },
  requests: {
    available_services: '/request_draft/available_services',
    excel: '/requests_excel',
    draft: {
      base: '/request_draft',
      draft_objects: {
        list: '/request_draft/draft_objects',
        single: '/request_draft/draft_objects/{{ object_oguid }}',
        child: '/request_draft/draft_objects_child',
      },
      send: '/request_draft/send',
      services: '/request_draft/services'
    },
    history: '/requests/{{ request_oguid }}/history',
    list: '/requests',
    messages: {
      file: '/requests/{{ request_oguid }}/messages/{{ message_oguid }}/file',
      list: '/requests/{{ request_oguid }}/messages',
      single: '/requests/{{ request_oguid }}/messages/{{ message_oguid }}'
    },
    objects: '/requests/{{ oguid }}/objects',
    orders: '/requests/{{ request_oguid }}/orders',
    services: {
      list: '/requests/{{ oguid }}/services',
      single: '/request_draft/services/{{ service_oguid }}'
    },
    single: '/requests/{{ oguid }}',
    status: '/requests/{{ oguid }}/status',
    types: {
      list: '/request/types',
      single: '/request/types/{{ oguid }}'
    }
  },
  services: {
    list: '/services',
    single: '/services/{{ oguid }}',
    calculate: '/services/calculate',
    calc_algorithms: '/service_calc_algorithms'
  },
  terminals: {
    list: '/terminals',
    single: '/terminals/{{ terminal_oguid }}',
    locations: '/terminals/{{ terminal_oguid }}/locations',
    file: '/terminals/{{ terminal_oguid }}/locations/file'
  },
  user: {
    user: 'user/',
    settings: '/settings'
  },
  users: {
    accounts: {
      list: '/users/{{ user_oguid }}/accounts',
      single: '/users/{{ user_oguid }}/accounts/{{ account_oguid }}'
    },
    list: '/users',
    recipients: '/recipients',
    single: '/users/{{ user_oguid }}',
    statistics: '/users/{{ user_oguid }}/statistics',
    subaccounts: {
      list: '/users/{{ user_oguid }}/sub_accounts',
      single: '/users/{{ user_oguid }}/sub_accounts/{{ sub_account_oguid }}'
    },
    terminals: {
      list: '/users/{{ user_oguid }}/terminals',
      single: '/users/{{ user_oguid }}/terminals/{{ terminal_oguid }}'
    }
  },
  groups: {
    list: {
      details: '/groups',
      members: '/groups/{{ group_oguid }}/members'
    },
    single: {
      group: '/groups/{{ group_oguid }}',
      member_delete: '/groups/{{ group_oguid }}/members/{{ user_oguid }}'
    }
  }
}
