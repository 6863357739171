import { Action, ActionCreator } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'

import { IUser } from '@store/modules/users/types'
import { IApplicationState, ICreator, Nullable } from '@store/types/commonTypes'
import * as ITerminalsActions from './typesActions'

export enum TerminalsActionTypes {
  ADD_TERMINAL = 'ADD_TERMINAL',
  DELETE_TERMINAL = 'DELETE_TERMINAL',
  EDIT_TERMINAL = 'EDIT_TERMINAL',
  SET_LOCATIONS = 'SET_LOCATIONS',
  SET_TERMINAL = 'SET_TERMINAL',
  SET_TERMINALS = 'SET_TERMINALS'
}

export type Actions =
  | ITerminalsActions.IActionAddTerminal
  | ITerminalsActions.IActionDeleteTerminal
  | ITerminalsActions.IActionEditTerminal
  | ITerminalsActions.IActionSetLocations
  | ITerminalsActions.IActionSetTerminal
  | ITerminalsActions.IActionSetTerminals

type ActionKeys = 'addTerminal' | 'deleteTerminal' | 'editTerminal' | 'setLocation' | 'setTerminal' | 'setTerminals'

export type TerminalsActions = { [key in ActionKeys]: ActionCreator<Action<string>> }

export type TerminalsThunkAction = ThunkAction<void, ITerminalsState, null, Action<string>>

export type TerminalsThunkDispatch = ThunkDispatch<IApplicationState, void, Action<string>>

export interface ITerminalData {
  name: string
  oguid: string
  created_date?: string
  created_user?: ICreator
}

export interface ITerminalsState {
  data: ITerminalData[]
  locations: ILocation[]
}

interface ILocationAccount {
  oguid: string
  id: string
  name: string
}

export interface ILocation {
  location_key: string
  zone: string
  row: string
  shelf: string
  col: string
  notes: string
  x_coord: number
  y_coord: number
  capacity: number
  is_temporary: boolean
  used: number
  reserved: number
  object_type: Nullable<string>
  terminal: ITerminalData
  account: Nullable<ILocationAccount>
  created_date: string
  created_user: IUser
}
