import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import OrganizationLink from '@common/navigation/components/CustomLinks/OrganizationLink'
import { Role, RouteName } from '@const/consts'
import { Logo as LogoLibrary } from '@infologistics/frontend-libraries'
import { resetError404 } from '@store/modules/utils/actions'
import { getRouteFromMenuItems } from '@utils/utils'
import { EmptyString, IApplicationState } from '@store/types/commonTypes'

const Logo: FC = () => {
  const dispatch = useDispatch<any>()
  const authorized: boolean = useSelector((state: IApplicationState) => state.user.isAuthenticated)
  const userRole: EmptyString<Role> = useSelector((state: IApplicationState) => state.organizations.activeOrganization.user_role)
  const path = authorized && userRole ? getRouteFromMenuItems(userRole) : RouteName.DEFAULT

  return (
    <div>
      <OrganizationLink onClick={() => dispatch(resetError404())} to={path}>
        <LogoLibrary src='/assets/img/logo.svg' alt='RMKeeper' />
      </OrganizationLink>
    </div>
  )
}

export default Logo
