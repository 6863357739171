import { IObjectsDraft, IRequestsState } from '@store/modules/requests/types'
import { ActiveItems, TableNames } from '@const/consts'

export const initial_draft_objects: IObjectsDraft = {
  amounts: {
    box: 0,
    folder: 0,
    document: 0
  },
  objects: [],
  errors: 0
}

export const RequestInitialState: IRequestsState = {
  activeRequest: {
    accepted_date: 0,
    accepted_user: {
      name: '',
      oguid: '',
      patronymic: '',
      position: '',
      surname: ''
    },
    account: {
      id: 0,
      name: '',
      oguid: ''
    },
    address: '',
    city: '',
    contact_email: '',
    contact_name: '',
    contact_phone: '',
    created_by_client: false,
    created_date: 0,
    created_user: {
      contacts: '',
      name: '',
      oguid: '',
      patronymic: '',
      position: '',
      role: '',
      surname: ''
    },
    current_order: {
      assigned_to: {
        name: '',
        oguid: '',
        patronymic: '',
        position: '',
        surname: ''
      },
      date: '',
      id: 0,
      oguid: '',
      status: ''
    },
    declined_date: 0,
    declined_user: {
      name: '',
      oguid: '',
      patronymic: '',
      position: '',
      surname: ''
    },
    executed_date: 0,
    executed_user: {
      name: '',
      oguid: '',
      patronymic: '',
      position: '',
      surname: ''
    },
    id: 0,
    initiator: {
      name: '',
      oguid: '',
      patronymic: '',
      position: '',
      surname: ''
    },
    last_step: 1,
    notes: '',
    oguid: '',
    pass_is_needed: false,
    process_is_needed: false,
    request_status: '',
    request_type: {
      name: '',
      oguid: ''
    },
    sent_date: 0,
    sent_user: {
      name: '',
      oguid: '',
      patronymic: '',
      position: '',
      surname: ''
    },
    statuses: {
      by_fact: 0,
      confirm: 0,
      declared: 0
    },
    sub_account: {
      name: '',
      oguid: ''
    },
    terminal: {
      name: '',
      oguid: ''
    },
    draft_objects: initial_draft_objects
  },
  comments: [],
  data: [],
  errorField: {
    account: false,
    contact_email: false,
    contact_name: false,
    contact_phone: false,
    initiator: false,
    request_type: false,
    terminal: false
  },
  loadingTimestamp: 0,
  oguid: '',
  orders: [],
  types: [],
  history: [],
  draftData: {
    activeItem: ActiveItems.LIST,
    activeTable: TableNames.ALL,
  }
}
