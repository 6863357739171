import { lazy } from 'react'

import { IRoute } from '@common/Routing/types'
import { Path, RolesWithoutClient, RouteName } from '@const/consts'
import getLazyComponent from '@common/lazyLoading/lazyLoadingFunctions'

const Orders = lazy(() => import('./components/Orders'))
const Order = lazy(() => import('./components/Order/Order'))

export const routes: IRoute[] = [
  {
    component: getLazyComponent(Orders),
    exact: true,
    name: 'Orders',
    path: RouteName.ORDERS,
    roles: RolesWithoutClient
  },
  {
    component: getLazyComponent(Order),
    exact: true,
    name: 'Order',
    path: Path.ORDERS_EDIT,
    roles: RolesWithoutClient
  }
]
