import { PER_PAGE_ITEMS_DEFAULT } from '@const/consts';

import { ILibPagination } from "@infologistics/frontend-libraries";
import { IPagination } from "@infologistics/frontend-libraries/dist/components/Pagination/types";

export const handleIsShowPagination = <T>(items: T[], pagination: IPagination): boolean =>
  items.length >= 10 || (pagination.pageIndex !== 1 && pagination.nextPage === null)

export const getPagination = (headers: any): ILibPagination => ({
  itemsPerPage: +headers['x-per-page'],
  nextPage: headers['x-next-page'] ? +headers['x-next-page'] : null,
  pageIndex: headers['x-page'] !== '-1' ? +headers['x-page'] : 1,
  prevPage: headers['x-prev-page'] ? +headers['x-prev-page'] : null,
  total: headers['x-total'] ? +headers['x-total'] : null
})

export const getPerPageNumber = (perPageKey?: string): string | number => {
  const itemPerPage = localStorage.getItem(perPageKey ?? window.location.pathname)

  return itemPerPage ?? PER_PAGE_ITEMS_DEFAULT
}
