import { IActionSetLoaded, IStartUpState, StartupActionTypes } from './types'

const initialState: IStartUpState = {
  isLoaded: false
}

export default function startupReducer (state: IStartUpState = initialState, action: IActionSetLoaded): IStartUpState {
  switch (action.type) {
    case StartupActionTypes.SET_LOADED:
      return {
        ...state,
        isLoaded: action.payload
      }

    default:
      return state
  }
}
