import { lazy } from 'react'

import { IRoute } from '@common/Routing/types'
import { AllRoles, RouteName } from '@const/consts'
import getLazyComponent from '@common/lazyLoading/lazyLoadingFunctions'

const Reports = lazy(() => import('./components/Reports'))

export const routes: IRoute[] = [
  {
    component: getLazyComponent(Reports),
    exact: true,
    name: 'Reports',
    path: RouteName.REPORTS,
    roles: AllRoles
  }
]
