import { initialPagination, ObjectType } from '@const/consts'
import { IObjectsState } from '@store/modules/objects/types'

export const editedObject = {
  account: {
    name: '',
    oguid: ''
  },
  alt_barcode: '',
  barcode: '',
  base_terminal: {
    name: '',
    oguid: ''
  },
  filename: '',
  first_state_in_date: 0,
  is_connected: true,
  location: '',
  notes: '',
  object_transport: {
    barcode: '',
    oguid: ''
  },
  object_type: ObjectType.BOX,
  oguid: '',
  parent: {
    barcode: '',
    oguid: ''
  },
  path: '',
  quantity: 0,
  seal1: null,
  seal2: null,
  state: '',
  state_date: 0,
  stationary_location: '',
  sub_account: {
    name: '',
    oguid: ''
  },
  terminal: {
    name: '',
    oguid: ''
  },
  terminate_date: 0,
  fields: {}
}

export const initialState: IObjectsState = {
  data: [],
  editedObject,
  quantity: {
    boxes: 0,
    documents: 0,
    folders: 0,
    supplies: 0,
    object_transports: 0
  },
  objectHistory: [],
  paginationHistory: initialPagination,
  statuses: {
    by_fact: 0,
    confirm: 0,
    declared: 0
  },
  updatedObjects: {
    not_found: 0,
    updated: 0
  }
}
