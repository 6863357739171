import { AxiosError, AxiosResponse } from 'axios'
import { Action, ActionCreator } from 'redux'

import filtersService from '@services/filters'
import usersService from '@services/users'

import { SuccessCode } from '@const/consts'
import { INavItem } from '@const/navSidebar'
import store from '@store/configureStore'
import { showErrorNotification } from '@utils/utils'
import {
  IActionDeleteFilter,
  IActionSetEditedFilter,
  IActionSetFilters,
  IActionSetMenuItems,
  IActionSetNewFilter,
  IActionSetOrdersActiveFilter,
  IActionSetRequestsActiveFilter,
  IBadges,
  INavigationActionCloseShortcut,
  INavigationActionOpenShortcut,
  INavigationActionSetBadges,
  INewFilter,
  IUserFilter,
  NavigationActions,
  NavigationActionTypes,
  NavigationThunkAction,
  NavigationThunkDispatch
} from './types'
import { IAxiosErrorData } from '@store/types/commonTypes'

let isLoadingBadges = false

export const closeShortcut: ActionCreator<Action> = (): INavigationActionCloseShortcut => ({
  type: NavigationActionTypes.CLOSE_SHORTCUT
})

export const openShortcut: ActionCreator<Action> = (): INavigationActionOpenShortcut => ({
  type: NavigationActionTypes.OPEN_SHORTCUT
})

const setBadgesAction: ActionCreator<Action> = (badges: IBadges): INavigationActionSetBadges => ({
  payload: badges,
  type: NavigationActionTypes.SET_BADGES
})

const setMenuItemsAction = (menuItems: INavItem[]): IActionSetMenuItems => ({
  payload: menuItems,
  type: NavigationActionTypes.SET_MENU_ITEMS
})

const setFiltersAction = (filters: IUserFilter[]): IActionSetFilters => ({
  payload: filters,
  type: NavigationActionTypes.SET_FILTERS
})

const setEditedFilterAction = (filter: IUserFilter): IActionSetEditedFilter => ({
  payload: filter,
  type: NavigationActionTypes.SET_EDITED_FILTER
})

const setNewFilterAction = (filter: IUserFilter): IActionSetNewFilter => ({
  payload: filter,
  type: NavigationActionTypes.SET_NEW_FILTER
})

const deleteFilterAction = (filterOguid: string): IActionDeleteFilter => ({
  payload: filterOguid,
  type: NavigationActionTypes.DELETE_FILTER
})

const setRequestsActiveFilterAction = (filterOguid: string): IActionSetRequestsActiveFilter => ({
  payload: filterOguid,
  type: NavigationActionTypes.SET_REQUESTS_ACTIVE_FILTER
})

const setOrdersActiveFilterAction = (filterOguid: string): IActionSetOrdersActiveFilter => ({
  payload: filterOguid,
  type: NavigationActionTypes.SET_ORDERS_ACTIVE_FILTER
})

export const actions: NavigationActions = {
  deleteFilter: deleteFilterAction,
  setBadges: setBadgesAction,
  setEditedFilter: setEditedFilterAction,
  setFilters: setFiltersAction,
  setMenuItems: setMenuItemsAction,
  setNewFilter: setNewFilterAction,
  setOrdersActiveFilter: setOrdersActiveFilterAction,
  setRequestsActiveFilter: setRequestsActiveFilterAction
}

export const getBadges: ActionCreator<NavigationThunkAction> = () =>
  (dispatch: NavigationThunkDispatch) => {
    const userOguid = store.getState().user.oguid

    if (isLoadingBadges) {
      return Promise.resolve()
    }

    isLoadingBadges = true

    return usersService.getBadges(userOguid)
      .then((resp: AxiosResponse) => {
        if (resp.status === SuccessCode.GET) {
          dispatch(setBadgesAction(resp.data))
        }

        isLoadingBadges = false
      })
      .catch((err: AxiosError<IAxiosErrorData>) => {
        isLoadingBadges = false
        showErrorNotification(err)
      })
  }

export const getUserFilters: ActionCreator<NavigationThunkAction> = () =>
  (dispatch: NavigationThunkDispatch): Promise<AxiosResponse> =>
    filtersService.getUserFilters()
    .then((resp: AxiosResponse) => {
      if (SuccessCode.GET === resp.status) {
        dispatch(actions.setFilters(resp.data))
      }

      return resp
    })
    .catch((error: AxiosError) => Promise.reject(error))

export const addUserFilter: ActionCreator<NavigationThunkAction> = (filterData: INewFilter) =>
  (dispatch: NavigationThunkDispatch): Promise<AxiosResponse> =>
    filtersService.addUserFilter(filterData)
      .then((resp: AxiosResponse) => {
        if (SuccessCode.POST.includes(resp.status)) {
          dispatch(actions.setNewFilter(resp.data))
        }

        return resp
      })
      .catch((error: AxiosError) => Promise.reject(error))

export const editUserFilter: ActionCreator<NavigationThunkAction> = (filterOguid: string, filterData: INewFilter) =>
  (dispatch: NavigationThunkDispatch): Promise<AxiosResponse> =>
    filtersService.editUserFilter(filterOguid, filterData)
      .then((resp: AxiosResponse<IUserFilter>) => {
        if (SuccessCode.PUT.includes(resp.status)) {
          dispatch(actions.setEditedFilter(resp.data))
        }

        return resp
      })
      .catch((error: AxiosError) => Promise.reject(error))

export const deleteUserFilter: ActionCreator<NavigationThunkAction> = (filterOguid: string) =>
  (dispatch: NavigationThunkDispatch): Promise<AxiosResponse> =>
    filtersService.deleteUserFilter(filterOguid)
      .then((resp: AxiosResponse<IUserFilter>) => {
        if (SuccessCode.DELETE.includes(resp.status)) {
          dispatch(actions.deleteFilter(filterOguid))
        }

        return resp
      })
      .catch((error: AxiosError) => Promise.reject(error))
