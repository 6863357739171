import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { Action, ActionCreator } from 'redux'

import { SuccessCode } from '@const/consts'
import { getPagination } from '@utils/pagination'

import {
  INewOuterCompany,
  IOuterCompany,
  OuterCompanyActions,
  OuterCompanyActionTypes,
  OuterCompanyThunkAction,
  OuterCompanyThunkDispatch
} from './types'
import * as IOuterCompanyActions from './typesActions'
import { ISuccessfulRequest } from '@store/types/commonTypes'

import { setPagination } from '@store/modules/pagination/actions'
import OuterCompany from '@app/services/outer_company'

// actions

const addOuterCompanyAction: ActionCreator<Action> = (
  data: IOuterCompany
): IOuterCompanyActions.IActionAddOuterCompany => ({
  payload: data,
  type: OuterCompanyActionTypes.ADD_OUTER_COMPANY
})

const setOuterCompaniesAction: ActionCreator<Action> = (
  data: IOuterCompany[]
): IOuterCompanyActions.IActionSetOuterCompanies => ({
  payload: data,
  type: OuterCompanyActionTypes.SET_OUTER_COMPANIES
})

const changeOuterCompanyAction: ActionCreator<Action> = (
  data: IOuterCompany
): IOuterCompanyActions.IActionChangeOuterCompany => ({
  payload: data,
  type: OuterCompanyActionTypes.CHANGE_OUTER_COMPANY
})

const deleteOuterCompanyAction: ActionCreator<Action> = (
  outerCompanyOguid: string
): IOuterCompanyActions.IActionDeleteOuterCompany => ({
  payload: outerCompanyOguid,
  type: OuterCompanyActionTypes.DELETE_OUTER_COMPANY
})

const setActiveContractorAction: ActionCreator<Action> = (
  data: IOuterCompany
): IOuterCompanyActions.IActionSetActiveContractor => ({
  payload: data,
  type: OuterCompanyActionTypes.SET_ACTIVE_CONTRACTOR
})

export const actions: OuterCompanyActions = {
  setOuterCompanies: setOuterCompaniesAction,
  addOuterCompany: addOuterCompanyAction,
  changeOuterCompany: changeOuterCompanyAction,
  deleteOuterCompany: deleteOuterCompanyAction,
  setActiveContractor: setActiveContractorAction
}

// thunk

export const addOuterCompany: ActionCreator<OuterCompanyThunkAction> = (data: INewOuterCompany, config: AxiosRequestConfig) =>
  (dispatch: OuterCompanyThunkDispatch): Promise<AxiosResponse<ISuccessfulRequest>> =>
    OuterCompany.addOuterCompany(data, config)
      .then((resp: AxiosResponse<ISuccessfulRequest>) => {
        if (SuccessCode.POST.includes(resp.status)) {
          const payload = { ...resp.data, name: data.name }
          dispatch(actions.addOuterCompany(payload))

        }

        return resp
      })
      .catch((error: AxiosError) => Promise.reject(error))

export const getOuterCompany: ActionCreator<OuterCompanyThunkAction> = (outerCompanyOguid: string) =>
  (dispatch: OuterCompanyThunkDispatch): Promise<AxiosResponse> =>
    OuterCompany.getOuterCompany(outerCompanyOguid)
      .then((resp: AxiosResponse) => {
        if (resp.status === SuccessCode.GET) dispatch(actions.setActiveContractor(resp.data))
        return resp
      })
      .catch((error: AxiosError) => Promise.reject(error))

export const getOuterCompanies: ActionCreator<OuterCompanyThunkAction> = () =>
  (dispatch: OuterCompanyThunkDispatch): Promise<AxiosResponse> =>
    OuterCompany.getOuterCompanies()
      .then((resp: AxiosResponse) => {
        if (resp.status === SuccessCode.GET) dispatch(actions.setOuterCompanies(resp.data))
        return resp
      })
      .catch((error: AxiosError) => Promise.reject(error))

export const getOuterCompaniesWithPagination: ActionCreator<OuterCompanyThunkAction> = (params: any) =>
  (dispatch: OuterCompanyThunkDispatch): Promise<AxiosResponse> =>
    OuterCompany.getOuterCompaniesWithPagination(params)
      .then((resp: AxiosResponse) => {
        if (resp.status === SuccessCode.GET) {
          dispatch(actions.setOuterCompanies(resp.data))
          dispatch(setPagination(getPagination(resp.headers)))
        }
        return resp
      })
      .catch((error: AxiosError) => Promise.reject(error))

export const updateOuterCompany: ActionCreator<OuterCompanyThunkAction> = (
  data: IOuterCompany,
  config: AxiosRequestConfig
) => (dispatch: OuterCompanyThunkDispatch): Promise<AxiosResponse> =>
  OuterCompany.updateOuterCompany(data, config)
      .then((resp: AxiosResponse) => {
        if (SuccessCode.PUT.includes(resp.status)) {
          dispatch(actions.changeOuterCompany(data))
        }
        return resp
      })
      .catch((error: AxiosError) => Promise.reject(error))

export const deleteOuterCompany: ActionCreator<OuterCompanyThunkAction> = (outerCompanyOguid: string) => (
  dispatch: OuterCompanyThunkDispatch
): Promise<AxiosResponse<ISuccessfulRequest>> =>
  OuterCompany.deleteOuterCompany(outerCompanyOguid)
    .then((resp: AxiosResponse<ISuccessfulRequest>) => {
      if (SuccessCode.DELETE.includes(resp.status)) dispatch(actions.deleteOuterCompany(outerCompanyOguid))
      return resp
    })
    .catch((error: AxiosError) => Promise.reject(error))
