import cn from 'classnames'
import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { changeOrganization as changeActiveOrganization } from '@store/modules/organizations/actions'
import { IApplicationState } from '@app/store/types/commonTypes'
import { setLoaded } from '@store/modules/startup/actions'
import { createUrl, getRouteFromMenuItems } from '@utils/utils'

import { AllServiceParams } from '@store/modules/navigation/types'
import { IOrganization } from '@store/modules/organizations/types'

const styles = require('./Organization.module.css')

interface IOwnProps {
  organization: IOrganization
}

const Organization: FC<IOwnProps> = (props: IOwnProps) => {
  const { organization } = props
  const { alias, user_role, name, oguid } = organization
  const dispatch = useDispatch<any>()
  const history = useHistory<AllServiceParams>()
  const activeOrganization = useSelector((state: IApplicationState) => state.organizations.activeOrganization.oguid)

  const onOrganizationClick = (): void => {
    if (activeOrganization === oguid) return

    dispatch(changeActiveOrganization(organization))
    window.localStorage.setItem('user.activeOrg', oguid)

    const organizationIdentifier = alias || oguid
    const firstMenuItemPath = getRouteFromMenuItems(user_role)

    history.replace(createUrl(organizationIdentifier, firstMenuItemPath))
    dispatch(setLoaded(false))
  }

  return (
    <li className={cn(styles.row, 'm-0 pointer px-3 py-1')} onClick={onOrganizationClick}>
      <p className='m-0'>{name}</p>
    </li>
  )
}

export default Organization
