import { INewUser, IUserWithOrganizations } from '@store/modules/user/types'
import { AxiosResponse } from 'axios'

import urls from '@const/urls'
import AbstractHttpService from '../abstractHttpService'

class UserService extends AbstractHttpService {
  private readonly url = urls.user

  public async getUser (): Promise<AxiosResponse<IUserWithOrganizations>> {
    const url = this.url.user

    return await this._http.get(url, {}, false)
  }

  public async updateProfile (data: INewUser): Promise<AxiosResponse> {
    const url = this.url.user

    return await this._http.put(url, data, {}, false)
  }

  public async getUserSettings (): Promise<AxiosResponse<object>> {
    const url = this.url.settings

    return await this._http.get(url, {}, false)
  }

  public async updateUserSettings (userSettings: object): Promise<AxiosResponse> {
    const url = this.url.settings

    return await this._http.put(url, userSettings, {}, false)
  }
}

export default new UserService()
