import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { useMount } from 'react-use'

import { Aside, ILibNavItem, LibNavItemLevel, Navigation as LibraryNavigation } from '@infologistics/frontend-libraries'

import NavMenuItem from '@common/navigation/components/NavMenuItem/index'
import { NAV_ANIMATION_DELAY, Role } from '@const/consts'
import { INavItem, menu } from '@const/navSidebar'
import { actions } from '@store/modules/navigation/actions'
import { resetError404 } from '@store/modules/utils/actions'
import { EmptyString, IApplicationState } from '@store/types/commonTypes'
import { filterAvailableMenuItems } from '@utils/utils'
import { cloneDeep } from 'lodash'
import { AllServiceParams } from '@store/modules/navigation/types'

const Navigation: FC = () => {
  const { t } = useTranslation(['archive', 'requests', 'orders'])
  const dispatch = useDispatch<any>()
  const location = useLocation<AllServiceParams>()
  const { pathname } = location

  const authorized: boolean = useSelector((state: IApplicationState) => state.user.isAuthenticated)
  const userRole: EmptyString<Role> = useSelector((state: IApplicationState) => state.organizations.activeOrganization.user_role)
  const menuItems: INavItem[] = useSelector((state: IApplicationState) => state.navigation.menuItems)
  const isError404: boolean = useSelector((state: IApplicationState) => state.utils.isError404)
  const hasReports: boolean = useSelector((state: IApplicationState) => state.reports.types.length > 0)

  useMount(() => {
    getNavItems()
  })

  useEffect(() => {
    if (authorized) {
      if (isError404) dispatch(resetError404())
    }
  }, [pathname])

  const getExtendedMenuItems = (menuItems: INavItem[], level = LibNavItemLevel.TOP): ILibNavItem[] => {
    const activeMenuItems = menuItems.filter(({ route }) => pathname.includes(route))
    const activeMenuItemUuid = activeMenuItems.length ? activeMenuItems[0].uuid : ''

    return menuItems.map((item): ILibNavItem => getExtendedMenuItem(item, level, activeMenuItemUuid))
  }

  const getExtendedMenuItem = (
    menuItem: INavItem,
    menuLevel: string,
    activeMenuItemUuid: string
  ): ILibNavItem => {
    const { items, title, uuid } = menuItem

    const isActive = activeMenuItemUuid === uuid
    const isTopLevel = menuLevel === LibNavItemLevel.TOP

    const subMenuItemsForDropdown = items && getExtendedMenuItems(items, LibNavItemLevel.LOW)

    return {
      ...menuItem,
      customNavLink: <NavMenuItem key={uuid} item={menuItem} />,
      isActive,
      isOpen: !isTopLevel || isActive,
      items: subMenuItemsForDropdown,
      level: menuLevel,
      title: t(title)
    }
  }

  const getNavItems = (): void => {
    const menuItems = filterAvailableMenuItems(cloneDeep(menu), userRole, hasReports)

    dispatch(actions.setMenuItems(menuItems))
  }

  // Расчет высоты навигации для полного скроллинга: (100% - (высота header + высота переключателя режима))
  const navHeight = 'calc(100% - 48px)'

  return (
    <Aside>
      <LibraryNavigation
        items={getExtendedMenuItems(menuItems)}
        height={navHeight}
        delay={NAV_ANIMATION_DELAY}
      />
    </Aside>
  )
}

export default Navigation
