import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'

import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'

import './i18n'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    integrations: [new Integrations.BrowserTracing()],
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENV,
    tracesSampleRate: 1.0,
    ignoreErrors: [
      'Request failed with status code 401',
      'Request failed with status code 403',
      'Request failed with status code 404'
    ]
  })
}

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
