import React, { FC, ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, RouteComponentProps } from 'react-router-dom'
import { useLocation } from 'react-router'

import { RouteName } from '@const/consts'
import { AllServiceParams } from '@store/modules/navigation/types'
import { changeOrganization } from '@store/modules/organizations/actions'
import { IOrganization } from '@store/modules/organizations/types'
import Page404 from '@views/misc/components/Page404'

import { IRouteMainParams as IProps } from '@common/Routing/types'
import { IApplicationState } from '@store/types/commonTypes'

export const PrivateRoute: FC<IProps> = (props: IProps) => {
  const { component: ComposedComponent, ...rest } = props
  const dispatch = useDispatch<any>()
  const location = useLocation<AllServiceParams>()
  const { pathname } = location
  const paramOrg = pathname.split('/')[1]

  const { alias, oguid } = useSelector((state: IApplicationState) => state.organizations.activeOrganization)
  const authorized: boolean = useSelector((state: IApplicationState) => state.user.isAuthenticated)
  const organizations: IOrganization[] = useSelector((state: IApplicationState) => state.organizations.organizationsList)

  const getAvailableOrganization = (
    org: string,
    organizations: IOrganization[]
  ): IOrganization | undefined => {
    return (
      organizations.find((organization: IOrganization) => organization.alias === org) ??
      organizations.find((organization: IOrganization) => organization.oguid === org)
    )
  }
  const availableOrganization = getAvailableOrganization(paramOrg, organizations)

  const handleRender = (renderProps: RouteComponentProps<AllServiceParams>): ReactElement => {
    const {
      location: { pathname, search },
    } = renderProps
    const currentLocation = `${pathname}${search}`

    if (!authorized) {
      return (
        <Redirect
          to={{
            pathname: RouteName.SIGN_IN,
            state: { from: currentLocation }
          }}
        />
      )
    }

    if (!availableOrganization) return <Route component={Page404} />

    return <ComposedComponent {...renderProps} />
  }

  useEffect(() => {
    if (availableOrganization && paramOrg !== oguid && paramOrg !== alias) {
      dispatch(changeOrganization(availableOrganization))
      window.localStorage.setItem('user.activeOrg', availableOrganization.oguid)
    }
  }, [location])

  return <Route {...rest} render={handleRender} />
}

export default PrivateRoute
