import { TranslationsDeclension } from '@const/consts'
import { ObjectTabName } from '@views/archive/components/Archive/Modals/ObjectModal/types'

const transFunc = (arg: string): string => arg

transFunc('notification:confirm_content')
transFunc('notification:confirm_title')
transFunc('notification:error_content')
transFunc('notification:error_title')
transFunc('notification:success_content')
transFunc('notification:success_title')
transFunc('orders:all_tasks')
transFunc('requests:all_requests')

export const ObjectTypes = {
  box: transFunc('common:box'),
  document: transFunc('common:document'),
  folder: transFunc('common:folder'),
  object_transport: transFunc('common:object_transport'),
  supply: transFunc('common:supply')
}

export const StateTypes = {
  in: transFunc('filters:state.in'),
  out: transFunc('filters:state.out'),
  destroyed: transFunc('filters:state.destroyed'),
  closed: transFunc('filters:state.closed'),
}

export const extendedObjectTypes = {
  all: transFunc('common:all'),
  ...ObjectTypes
}

export const Operations = {
  DESTROY: transFunc('common:destroy'),
  PermanentOUT: transFunc('common:permanent_out'),
  attach: transFunc('common:attach'),
  attach_to_tr: transFunc('operations:attach_to_tr'),
  detach: transFunc('common:detach'),
  out: transFunc('operations:out'),
  pick_up: transFunc('common:pick_up'),
  put: transFunc('common:put'),
  PUT_SEAL: transFunc('common:put_seal'),
  REMOVE_SEAL: transFunc('common:remove_seal')
}

export const OperationsFields = {
  operation_type: transFunc('operations:operation_type'),
  terminal: transFunc('common:terminal'),
  destination: transFunc('common:location'),
  objects_operation: transFunc('operations:move_objects')
}

export const PageText404 = {
  one: transFunc('404:one'),
  three: transFunc('404:three'),
  two: transFunc('404:two')
}

export const ObjectHistoryColumns = {
  executor_and_date: transFunc('object:history.executor_and_date'),
  old_and_new_values: transFunc('object:history.old_and_new_values'),
  operation: transFunc('common:operation'),
  request_and_task: transFunc('object:history.request_and_task')
}

export const ObjectHistoryTypes = {
  order: transFunc('common:order'),
  request: transFunc('common:request'),
  without_order: transFunc('common:without_order'),
  without_request: transFunc('common:without_request')
}

export const ObjectStates = {
  closed: transFunc('object:state.closed'),
  destroyed: transFunc('object:state.destroyed'),
  in: transFunc('object:state.in'),
  out: transFunc('object:state.out'),
  pending: transFunc('object:state.pending'),
  question: transFunc('object:state.question')
}

export const ObjectStatuses = {
  by_fact: transFunc('object:status.by_fact'),
  confirm: transFunc('object:status.confirm'),
  declared: transFunc('object:status.declared')
}

export const ObjectsMessages = {
  add_any_object: transFunc('object:add_any_object'),
  add_objects_with_notes: transFunc('object:add_objects_with_notes'),
  add_documents: transFunc('object:add_documents'),
  add_folders: transFunc('object:add_folders'),
  add_objects: transFunc('object:add_objects'),
  add_objects_tags: transFunc('object:add_by_barcode'),
  object_not_found: transFunc('object:object_not_found'),
  object_type_is_invalid: transFunc('object:object_type_is_invalid'),
  request_documents_from_objects: transFunc('object:request_documents_from_objects'),
  request_folders_from_objects: transFunc('object:request_folders_from_objects')
}

export const Menu = {
  accounts: transFunc('common:accounts'),
  administration: transFunc('common:administration'),
  archive: transFunc('common:archive'),
  barcodes: transFunc('common:barcodes'),
  groups: transFunc('common:groups'),
  operations: transFunc('common:operations'),
  order_types: transFunc('common:order_types'),
  orders: transFunc('common:orders'),
  props: transFunc('common:props'),
  reports: transFunc('common:reports'),
  request_types: transFunc('common:request_types'),
  requests: transFunc('common:requests'),
  terminals: transFunc('common:terminals'),
  users: transFunc('common:users'),
  services: transFunc('common:services'),
  contractors: transFunc('common:contractors')
}

export const Accounts = {
  address: transFunc('common:address'),
  city: transFunc('common:city'),
  enter_address: transFunc('accounts:enter_address'),
  enter_city: transFunc('accounts:enter_city'),
  pass_needed: transFunc('common:pass_needed'),
  add_account: transFunc('accounts:add_account'),
  edit_account: transFunc('accounts:edit_account'),
  route: transFunc('accounts:route.title')
}

export const AccountRoutes = {
  title: transFunc('accounts:route.title'),
  city: transFunc('accounts:route.city'),
  intercity: transFunc('accounts:route.intercity'),
  other: transFunc('accounts:route.other')
}


export const Archive = {
  all: transFunc('common:all'),
  boxes: transFunc('archive:boxes'),
  documents: transFunc('archive:documents'),
  folders: transFunc('archive:folders')
}

export const ArchiveHeader = {
  all: transFunc('archive:all_objects'),
  box: transFunc('archive:boxes'),
  document: transFunc('archive:documents'),
  folder: transFunc('archive:folders')
}

export const ArchiveTabs = {
  [ObjectTabName.CHANGE]: transFunc('common:change'),
  [ObjectTabName.HISTORY]: transFunc('common:history'),
  [ObjectTabName.INFORMATION]: transFunc('object:information')
}

export const ArchiveModalFields = {
  alt_barcode: transFunc('common:alt_barcode'),
  barcode: transFunc('common:barcode'),
  parent: transFunc('common:parent_barcode'),
  quantity: transFunc('common:quantity')
}

export const BarcodesErrorText = {
  objectType_value: transFunc('common:warning.required'),
  enter_length: transFunc('barcodes:enter_length'),
  enter_prefix: transFunc('barcodes:enter_prefix'),
  enter_correct_length_value: transFunc('barcodes:enter_correct_length_value'),
  enter_value_from: transFunc('barcodes:enter_value_from'),
  enter_value_to: transFunc('barcodes:enter_value_to')
}

export const BarcodeRangeFields = {
  object_type: transFunc('common:objects_type'),
  prefix: transFunc('common:prefix'),
  length: transFunc('common:length'),
  value_from: transFunc('barcodes:value_from'),
  value_to: transFunc('barcodes:value_to'),
  involved: transFunc('barcodes:involved'),
  terminal: transFunc('common:terminal'),
  account: transFunc('common:account'),
  sub_account: transFunc('common:sub_account')
}

export const ContractorFields = {
  name: transFunc('contractors:heading.name'),
  is_outer: transFunc('contractors:heading.outer')
}

export const ExportModal = {
  title: transFunc('export_modal:title'),
  success_export: transFunc('export_modal:success_export'),
  nameList: transFunc('common:objects'),
  fields: {
    account: transFunc('export_modal:fields.account'),
    alt_barcode: transFunc('export_modal:fields.alt_barcode'),
    barcode: transFunc('export_modal:fields.barcode'),
    filename: transFunc('export_modal:fields.filename'),
    first_state_in_date: transFunc('export_modal:fields.first_state_in_date'),
    is_connected: transFunc('export_modal:fields.is_connected'),
    location: transFunc('export_modal:fields.location'),
    notes: transFunc('export_modal:fields.notes'),
    object_transport: transFunc('export_modal:fields.object_transport'),
    object_type: transFunc('export_modal:fields.object_type'),
    oguid: transFunc('export_modal:fields.oguid'),
    parent: transFunc('export_modal:fields.parent'),
    path: transFunc('export_modal:fields.path'),
    quantity: transFunc('export_modal:fields.quantity'),
    request_object_status: transFunc('export_modal:fields.request_object_status'),
    order_object_state: transFunc('export_modal:fields.order_object_state'),
    state: transFunc('export_modal:fields.state'),
    state_date: transFunc('export_modal:fields.state_date'),
    stationary_location: transFunc('export_modal:fields.stationary_location'),
    sub_account: transFunc('export_modal:fields.sub_account'),
    terminal: transFunc('export_modal:fields.terminal')
  }
}

export const DraftObjectsExportModal = {
  title: transFunc('export_modal:draft_objects_export'),
  success_export: transFunc('export_modal:success_export'),
  nameList: transFunc('common:objects'),
  fields: {
    alt_barcode: transFunc('export_modal:fields.alt_barcode'),
    barcode: transFunc('export_modal:fields.barcode'),
    error_description: transFunc('export_modal:fields.error_description'),
    is_connected: transFunc('export_modal:fields.is_connected'),
    location: transFunc('export_modal:fields.location'),
    notes: transFunc('export_modal:fields.notes'),
    object_type: transFunc('export_modal:fields.object_type'),
    oguid: transFunc('export_modal:fields.oguid'),
    parent: transFunc('export_modal:fields.parent'),
    parent_type: transFunc('export_modal:fields.parent_type'),
    state: transFunc('export_modal:fields.state')
  }
}

export const OperationsExportModal = {
  operations_export: transFunc('export_modal:operations_export'),
  success_export: transFunc('export_modal:success_export'),
  nameList: transFunc('common:operations'),
  order_operations_fields: {
    barcode: transFunc('export_modal:order_operations_fields.barcode'),
    check_location: transFunc('export_modal:order_operations_fields.check_location'),
    check_parent_barcode_level1: transFunc('export_modal:order_operations_fields.check_parent_barcode_level1'),
    check_parent_barcode_level2: transFunc('export_modal:order_operations_fields.check_parent_barcode_level2'),
    check_status: transFunc('export_modal:order_operations_fields.check_status'),
    new_destination: transFunc('export_modal:order_operations_fields.new_destination'),
    object_quantity: transFunc('export_modal:order_operations_fields.object_quantity'),
    objects: transFunc('export_modal:order_operations_fields.objects'),
    old_destination: transFunc('export_modal:order_operations_fields.old_destination'),
    operation_date: transFunc('export_modal:order_operations_fields.operation_date'),
    operation_id: transFunc('export_modal:order_operations_fields.operation_id'),
    operation_oguid: transFunc('export_modal:order_operations_fields.operation_oguid'),
    operation_type: transFunc('export_modal:order_operations_fields.operation_type'),
    operation_user: transFunc('export_modal:order_operations_fields.operation_user'),
    order: transFunc('export_modal:order_operations_fields.order'),
    planned_destination: transFunc('export_modal:order_operations_fields.planned_destination'),
    planned_operations_maker_oguid: transFunc('export_modal:order_operations_fields.planned_operations_maker_oguid'),
    request: transFunc('export_modal:order_operations_fields.request'),
    terminal: transFunc('export_modal:order_operations_fields.terminal')
  }
}

export const ServicesExportModal = {
  title: transFunc('export_modal:services_export'),
  success_export: transFunc('export_modal:success_export'),
  nameList: transFunc('services:services_list'),
  services_fields: {
    oguid: transFunc('export_modal:services_fields.oguid'),
    service_group: transFunc('export_modal:services_fields.service_group'),
    service_hint: transFunc('export_modal:services_fields.service_hint'),
    is_default: transFunc('export_modal:services_fields.is_default'),
    is_tsd_available: transFunc('export_modal:services_fields.is_tsd_available'),
    is_custom: transFunc('export_modal:services_fields.is_custom'),
    request_available: transFunc('export_modal:services_fields.request_available'),
    service_code: transFunc('export_modal:services_fields.service_code'),
    service_name: transFunc('export_modal:services_fields.service_name'),
    unit: transFunc('export_modal:services_fields.unit'),
    request_category_available: transFunc('export_modal:services_fields.request_category_available'),
    sort_order: transFunc('export_modal:services_fields.sort_order'),
    quantity_type: transFunc('export_modal:services_fields.quantity_type'),
    service_calc_algorithm: transFunc('export_modal:services_fields.service_calc_algorithm')
  }
}

export const Orders = {
  account: transFunc('common:account'),
  add_operation: transFunc('operations:add_operation'),
  all: transFunc('common:all'),
  assigned: transFunc('orders:assigned'),
  attach: transFunc('common:attach'),
  attach_to_tr: transFunc('common:attach_to_tr'),
  cancelled: transFunc('orders:cancelled'),
  completed: transFunc('orders:completed'),
  execution: transFunc('orders:execution'),
  history: transFunc('orders:history_tab'),
  information: transFunc('orders:information'),
  new: transFunc('orders:new'),
  objects: transFunc('common:objects'),
  operations: transFunc('common:operations'),
  order: transFunc('common:order'),
  plan: transFunc('orders:implementation_plan'),
  put: transFunc('common:put'),
  sub_account: transFunc('common:subaccount'),
  unassigned: transFunc('orders:unassigned'),
  services: transFunc('common:services'),
  notifications: transFunc('orders:order_notifications'),
}

export const OrderFields = {
  order_type: transFunc('orders:order_type'),
  terminal: transFunc('orders:terminal'),
  order_comment: transFunc('orders:order_comment'),
  is_copy_objects: transFunc('orders:is_copy_objects'),
  outer_company: transFunc('orders:outer_company'),
  outer_order_number: transFunc('orders:outer_order_number'),
  outer_order_date: transFunc('orders:outer_order_date'),
  outer_planned_date: transFunc('orders:outer_planned_date'),
  outer_sum_with_nds: transFunc('orders:outer_sum_with_nds'),
  is_postponed_execution: transFunc('orders:is_postponed_execution')
}

export const OrderNotificationFields = {
  account_name: transFunc('common:account'),
  address: transFunc('common:address'),
  contact_name: transFunc('requests:contact_name'),
  contact_phone: transFunc('common:phone'),
  contact_email: transFunc('common:email'),
  car_plate: transFunc('orders:car_plate'),
  driver_executor: transFunc('orders:driver_executor'),
  driver_another: transFunc('orders:driver_another'),
  forwarder_without: transFunc('orders:forwarder_without'),
  forwarder_rmk: transFunc('orders:forwarder_rmk'),
  forwarder_another: transFunc('orders:forwarder_another'),
  quantity_info: transFunc('object:quantity_info'),
  planned_date: transFunc('orders:planned_date_time'),
  track_number: transFunc('orders:outer_order_number'),
  is_copy_to_email: transFunc('orders:copy_to')
}

export const OrderStatuses = {
  assigned_to: transFunc('orders:status.assigned_to'),
  completed: transFunc('orders:status.completed'),
  declined: transFunc('orders:status.declined'),
  new: transFunc('common:new'),
  started: transFunc('orders:status.started')
}

export const OrdersStatuses = {
  all: transFunc('common:all'),
  assigned_to: transFunc('orders:assigned'),
  completed: transFunc('orders:completed'),
  declined: transFunc('orders:cancelled'),
  new: transFunc('orders:new'),
  started: transFunc('orders:execution')
}

export const OrderHistoryStatuses = {
  assigned_to: transFunc('orders:history.assigned_to'),
  completed: transFunc('orders:history.completed'),
  declined: transFunc('orders:history.declined'),
  new: transFunc('orders:history.created'),
  started: transFunc('orders:history.started')
}

export const OrderNotificationStatuses = {
  read: transFunc('orders:notification.status.read'),
  unread: transFunc('orders:notification.status.unread')
}

export const OrderButtons = {
  assign: transFunc('common:buttons.assign'),
  complete: transFunc('common:buttons.complete'),
  reassign: transFunc('common:buttons.reassign'),
  reopen: transFunc('common:buttons.reopen'),
  send_order_to_work: transFunc('common:buttons.send_order_to_work'),
  cancel_task: transFunc('common:buttons.cancel_task'),
  edit: transFunc('common:buttons.edit'),
  delete: transFunc('common:buttons.delete')
}

export const Requests = {
  all: transFunc('common:all'),
  completed: transFunc('requests:completed'),
  drafts: transFunc('requests:drafts'),
  execution: transFunc('requests:execution'),
  history: transFunc('common:history'),
  information: transFunc('common:info'),
  no_execution: transFunc('requests:no_execution'),
  no_order: transFunc('requests:no_order'),
  objects: transFunc('common:objects'),
  orders: transFunc('common:orders'),
  pending: transFunc('requests:pending'),
  rejected: transFunc('requests:rejected'),
  request: transFunc('common:request'),
  unprocessed: transFunc('requests:unprocessed'),
  services: transFunc('services:additional_services')
}

export const RequestButtons = {
  accept_request: transFunc('common:buttons.accept_request'),
  change: transFunc('common:buttons.change'),
  complete: transFunc('common:buttons.complete'),
  decline: transFunc('common:buttons.decline'),
  send: transFunc('common:buttons.send'),
  delete: transFunc('common:buttons.delete'),
  close: transFunc('common:buttons.close'),
  edit: transFunc('common:buttons.edit'),
  reopen: transFunc('common:buttons.reopen'),
}

export const RequestCategory = {
  name: transFunc('request_type:name'),
  request_category: transFunc('request_type:request_category')
}

export const RequestDate = {
  completed: 'executed_date',
  declined: 'declined_date',
  draft: 'created_date',
  in_progress: 'accepted_date',
  new: 'sent_date'
}

export const RequestStatuses = {
  completed: transFunc('requests:status.completed'),
  declined: transFunc('requests:status.declined'),
  draft: transFunc('requests:status.draft'),
  in_progress: transFunc('requests:status.in_progress'),
  new: transFunc('requests:status.pending')
}

export const RequestStatusesNotification = {
  completed: transFunc('requests:notification.completed_content'),
  in_progress: transFunc('requests:notification.in_progress_content'),
  new: transFunc('requests:notification.new_content')
}

export const RequestStatusesNotificationEmpty = {
  completed: transFunc('requests:notification.completed_empty_content'),
  in_progress: transFunc('requests:notification.in_progress_empty_content'),
  new: transFunc('requests:notification.new_empty_content')
}

export const RequestHistoryStatuses = {
  accepted: transFunc('requests:status.accepted'),
  completed: transFunc('requests:status.completed_by'),
  created: transFunc('common:created'),
  rejected: transFunc('requests:status.rejected'),
  sent: transFunc('requests:status.sent')
}

export const RequestFields = {
  account: transFunc('common:account'),
  address: transFunc('common:address'),
  city: transFunc('common:city'),
  contact_email: transFunc('common:email'),
  contact_name: transFunc('requests:contact_name'),
  contact_phone: transFunc('common:phone'),
  created_user: transFunc('requests:created_user'),
  initiator: transFunc('requests:initiator'),
  notes: transFunc('requests:notes'),
  pass_is_needed: transFunc('common:pass_needed'),
  pass_needed: transFunc('common:pass_needed'),
  process_is_needed: transFunc('requests:process_is_needed'),
  request_type: transFunc('common:request_type'),
  sub_account: transFunc('common:subaccount'),
  terminal: transFunc('common:terminal')
}

export const RequestModalReason = {
  add_reason: transFunc('requests:add_reason'),
  decline_anyway: transFunc('requests:decline_anyway'),
  decline: transFunc('common:buttons.decline'),
  cancel: transFunc('common:buttons.cancel'),
  unfinished_tasks: transFunc('requests:unfinished_tasks'),
  request_message_declined: transFunc('requests:request_message_declined')
}

export const UserMessages = {
  account: transFunc('user:enter_account'),
  code: transFunc('user:enter_code'),
  company: transFunc('user:enter_company'),
  contacts: transFunc('user:enter_contacts'),
  email: transFunc('user:enter_email'),
  login: transFunc('user:enter_login'),
  mode: transFunc('user:enter_mode'),
  name: transFunc('user:enter_name'),
  org_name: transFunc('user:enter_company'),
  password: transFunc('user:enter_password'),
  phone: transFunc('user:enter_phone'),
  position: transFunc('user:enter_position'),
  role: transFunc('user:enter_role'),
  surname: transFunc('user:enter_surname')
}

export const UserFields = {
  contacts: transFunc('user:contacts'),
  email: transFunc('common:email'),
  login: transFunc('user:login'),
  name: transFunc('common:name'),
  password: transFunc('common:password'),
  patronymic: transFunc('common:patronymic'),
  position: transFunc('user:position'),
  surname: transFunc('common:surname')
}

export const UserStatuses = {
  active: transFunc('user:status.active'),
  blocked: transFunc('user:status.blocked'),
  deactivated: transFunc('user:status.deactivated')
}

export const UserRoles = {
  ROLE_CLIENT: transFunc('user:role.client'),
  ROLE_PROVIDER_ADMIN: transFunc('user:role.admin'),
  ROLE_PROVIDER_DISPATCHER: transFunc('user:role.dispatcher'),
  ROLE_PROVIDER_EMPLOYEE: transFunc('user:role.employee'),
  ROLE_PROVIDER_OPERATOR_TSD: transFunc('user:role.operator'),
  ROLE_MASTER_CLIENT: transFunc('user:role.master_client')
}

export const Warning = {
  max: {
    5: transFunc('common:warning.max.5'),
    20: transFunc('common:warning.max.20'),
    25: transFunc('common:warning.max.25'),
    30: transFunc('common:warning.max.30'),
    32: transFunc('common:warning.max.32'),
    50: transFunc('common:warning.max.50'),
    200: transFunc('common:warning.max.200'),
    500: transFunc('common:warning.max.500'),
    700: transFunc('common:warning.max.700'),
  },
  min: {
    3: transFunc('common:warning.min.3'),
    6: transFunc('common:warning.min.6'),
    10: transFunc('common:warning.min.10')
  },
  max_integer: transFunc('common:warning.max_integer'),
  regexp: transFunc('common:warning.regexp'),
  required: transFunc('common:warning.required'),
  valid: transFunc('common:warning.valid'),
  date_error: transFunc('common:warning.date_error'),
  user: {
    initial: transFunc('user:initial_error'),
    login: transFunc('user:login_error'),
    email: transFunc('user:email_error'),
    contacts: transFunc('user:phone_error')
  }
}

export const FiltersName = {
  account: transFunc('common:account'),
  account_id: transFunc('common:account_id'),
  address: transFunc('common:address'),
  alt_barcode: transFunc('common:alt_barcode'),
  assigned_to: transFunc('filters:assigned_to'),
  assigned_to_group: transFunc('filters:assigned_to_group'),
  barcode: transFunc('common:barcode'),
  date: transFunc('common:date'),
  first_state_in_date: transFunc('filters:first_state_in_date'),
  description: transFunc('common:description'),
  id: transFunc('filters:id'),
  created_by_client: transFunc('filters:created_by_client'),
  creator: transFunc('filters:creator'),
  initiator: transFunc('filters:initiator'),
  location: transFunc('filters:location'),
  location_temp_key: transFunc('filters:location_temp_key'),
  no_order: transFunc('requests:no_order'),
  object_type: transFunc('filters:object_type'),
  object_type_alt: transFunc('filters:object_type_alt'),
  operation_id: transFunc('filters:operation_id'),
  operation_type: transFunc('operations:operation_type'),
  order_id: transFunc('filters:request_id'),
  order_status: transFunc('orders:order_status'),
  order_type: transFunc('filters:order_type'),
  outer: transFunc('orders:outer'),
  outer_order_number: transFunc('orders:outer_order_number'),
  outer_planned_date: transFunc('orders:outer_planned_date'),
  parent_barcode: transFunc('common:parent_barcode'),
  prefix: transFunc('common:prefix'),
  request: transFunc('common:request'),
  request_status: transFunc('requests:request_status'),
  request_type: transFunc('filters:request_type'),
  role: transFunc('common:role'),
  show_pending: transFunc('filters:show_pending'),
  status: transFunc('common:status'),
  state: transFunc('filters:state.title'),
  subaccount: transFunc('common:subaccount'),
  terminal: transFunc('common:terminal'),
  user: transFunc('common:user'),
  value: transFunc('filters:value'),
  without_active_orders: transFunc('filters:without_active_orders'),
  zone: transFunc('terminals:zone'),
  city: transFunc('common:city'),
}

export const OrganizationMessages = {
  name: transFunc('organization:enter_name')
}

export const Filters = {
  teq: transFunc('filters:teq'),
  day: transFunc('filters:day.title'),
  month: transFunc('filters:month.title'),
  tday: transFunc('filters:day.title'),
  week: transFunc('filters:week.title'),
  daysText: transFunc('filters:daysText'),
  allOptionText: transFunc('filters:allOptionText'),
  addFilter: transFunc('filters:addFilter'),
  assigned_to: transFunc('filters:assigned_to'),
  assigned_to_group: transFunc('filters:assigned_to_group'),
  beginWith: transFunc('filters:beginWith'),
  between: transFunc('filters:between'),
  close: transFunc('filters:close'),
  contains: transFunc('filters:contains'),
  empty: transFunc('filters:empty'),
  endWith: transFunc('filters:endWith'),
  equal: transFunc('filters:equal'),
  ge: transFunc('filters:ge'),
  gt: transFunc('filters:gt'),
  le: transFunc('filters:le'),
  lt: transFunc('filters:lt'),
  notEqual: transFunc('filters:notEqual'),
  save: transFunc('filters:save'),
  choose_variant: transFunc('filters:choose_variant'),
  type_name: transFunc('filters:type_name'),
  update: transFunc('filters:update'),
  tin: transFunc('filters:tin'),
  and: transFunc('filters:and'),
  filters: transFunc('filters:filters'),
  id: transFunc('filters:id'),
  in: transFunc('filters:in'),
  initiator: transFunc('filters:initiator'),
  isNotNull: transFunc('filters:isNotNull'),
  isNull: transFunc('filters:isNull'),
  location_temp_key: transFunc('filters:location_temp_key'),
  no_filters: transFunc('filters:no_filters'),
  object_type: transFunc('filters:object_type'),
  order_type: transFunc('filters:order_type'),
  request_type: transFunc('filters:request_type'),
  reset: transFunc('filters:reset'),
  result: {
    '%like': transFunc('filters:result.%like'),
    eq: transFunc('filters:result.eq'),
    gt: transFunc('filters:result.gt'),
    in: transFunc('filters:result.in'),
    le: transFunc('filters:result.le'),
    like: transFunc('filters:result.like'),
    'like%': transFunc('filters:result.like%'),
    lt: transFunc('filters:result.lt'),
    ge: transFunc('filters:result.ge'),
    less: transFunc('filters:result.less'),
    after: transFunc('filters:result.after'),
    before: transFunc('filters:result.before'),
    day: transFunc('filters:result.day'),
    month: transFunc('filters:result.month'),
    week: transFunc('filters:result.week')
  },
  handbook_text: {
    empty: transFunc('handbooks:suggest.empty'),
    hint: transFunc('handbooks:suggest.hint'),
    placeholder: transFunc('handbooks:suggest.placeholder')
  },
  show_filters: transFunc('filters:show_filters'),
  show_pending: transFunc('filters:show_pending'),
  submit: transFunc('filters:submit'),
  to: transFunc('filters:to'),
  on: transFunc('common:yes'),
  off: transFunc('common:no'),
  all: transFunc('common:all'),
  radix: transFunc('currency:radix_separator'),
  selectField: transFunc('filters:selectField'),
  thousand: transFunc('currency:thousand_separator'),
  time: transFunc('common:time')
}

export const ObjectModalFields = {
  account: transFunc('common:account'),
  alt_barcode: transFunc('common:alt_barcode'),
  barcode: transFunc('common:barcode'),
  file: transFunc('common:file'),
  filename: transFunc('common:file'),
  first_state_in_date: transFunc('archive:modals.storage_start_date'),
  location: transFunc('archive:modals.current_location'),
  seal_1: transFunc('archive:modals.seal_1'),
  seal_2: transFunc('archive:modals.seal_2'),
  notes: transFunc('common:description'),
  object_transport: transFunc('archive:modals.transport_barcode'),
  parent: transFunc('archive:modals.parent_barcode'),
  quantity: transFunc('common:quantity'),
  stationary_location: transFunc('common:location'),
  sub_account: transFunc('common:sub_account'),
  terminal: transFunc('common:terminal')
}

export const ObjectInfoNotFilledFields = {
  account: transFunc('archive:not_indicated'),
  additional_field_1: transFunc('archive:not_filled'),
  additional_field_2: transFunc('archive:not_filled'),
  additional_field_3: transFunc('archive:not_filled'),
  additional_field_4: transFunc('archive:not_filled'),
  alt_barcode: transFunc('archive:not_indicated'),
  location: transFunc('archive:not_indicated'),
  notes: transFunc('archive:not_indicated'),
  object_transport: transFunc('archive:not_indicated'),
  parent: transFunc('archive:not_indicated'),
  stationary_location: transFunc('archive:not_indicated'),
  sub_account: transFunc('common:no_subaccount'),
  terminal: transFunc('archive:not_indicated')
}

export const ObjectInfoNotFilledFieldsDeclension = {
  account: TranslationsDeclension.MASCULINE,
  alt_barcode: TranslationsDeclension.MASCULINE,
  location: TranslationsDeclension.FEMININE,
  notes: TranslationsDeclension.NEUTER,
  object_transport: TranslationsDeclension.MASCULINE,
  parent: TranslationsDeclension.MASCULINE,
  stationary_location: TranslationsDeclension.FEMININE,
  terminal: TranslationsDeclension.MASCULINE
}

export const TaskTypeTab = {
  settings: transFunc('order_type:settings'),
  services: transFunc('common:services'),
  plan_of_operations: transFunc('order_type:plan_of_operations')
}

export const TaskTypeSettingsField = {
  allowed_object_type: transFunc('order_type:fields.allowed_object_type'),
  code: transFunc('order_type:fields.code'),
  is_autoclose_request: transFunc('order_type:fields.is_autoclose_request'),
  is_objects_required: transFunc('order_type:fields.is_objects_required'),
  is_outer: transFunc('order_type:fields.is_outer'),
  is_payable: transFunc('order_type:fields.is_payable'),
  name: transFunc('order_type:fields.name')
}

export const Language = {
  en: transFunc('language:en'),
  ru: transFunc('language:ru'),
  pt: transFunc('language:pt')
}

export const LanguageShort = {
  en: transFunc('language:short.en'),
  ru: transFunc('language:short.ru'),
  pt: transFunc('language:short.pt')
}

export const ServiceField = {
  service_oguid: transFunc('services:service_name'),
  service_code: transFunc('services:heading.code'),
  service_calc_algorithm: transFunc('services:heading.calc_algorithm'),
  quantity: transFunc('common:quantity'),
  unit_price: transFunc('services:unit_price_short'),
  date_from: transFunc('services:effective_date'),
  date_to: transFunc('services:expiration_date'),
  min_sum: transFunc('services:heading.minimal_price'),
  service_name_invoice: transFunc('services:service_name_invoice'),
  override_name: transFunc('services:override_name'),
  contract_name: transFunc('services:contract_name'),
  contract_number: transFunc('services:contract_number'),
  contract_date: transFunc('services:contract_date'),
}

export const GeneralServicesField = {
  service_code: transFunc('services:heading.code'),
  service_group: transFunc('services:service_group'),
  service_name: transFunc('services:service_name'),
  service_hint: transFunc('common:description'),
  unit: transFunc('services:unit'),
  is_default: transFunc('services:add_in_account'),
  request_available: transFunc('services:request_available'),
  request_category_available: transFunc('services:request_category'),
  is_tsd_available: transFunc('services:input_from_TSD'),
  service_calc_algorithm: transFunc('services:heading.calc_algorithm'),
  sort_order: transFunc('services:sorting_order'),
  quantity_type: transFunc('services:indicator_type')
}

export const RequestAvailable = {
  none: transFunc('services:none'),
  objects: transFunc('services:objects'),
  additional_services: transFunc('services:additional_services')
}

export const QuantityType = {
  int: transFunc('services:int'),
  money: transFunc('services:money'),
  number: transFunc('services:number')
}

export const OrderTypeService = {
  service_code: transFunc('services:heading.code'),
  service_group: transFunc('services:heading.group'),
  service_name: transFunc('services:service')
}

export const RequestService = {
  service_oguid: transFunc('services:service_name'),
  quantity: transFunc('common:quantity'),
  service_hint: transFunc('common:description'),
  service_group: transFunc('services:service_group'),
  service_code: transFunc('services:heading.code'),
  unit: transFunc('services:unit')
}

export const ServicesTablesHeading = {
  code: transFunc('services:heading.code'),
  code_v2: transFunc('services:heading.code_v2'),
  group: transFunc('services:heading.group'),
  name: transFunc('services:heading.name'),
  sorting: transFunc('services:heading.sorting'),
  unit: transFunc('services:heading.unit'),
  in_request: transFunc('services:heading.in_request'),
  in_account: transFunc('services:heading.in_account'),
  on_TSD: transFunc('services:heading.on_TSD'),
  calc_algorithm: transFunc('services:heading.calc_algorithm'),
  quantity: transFunc('common:quantity'),
  valid_since: transFunc('services:heading.valid_since'),
  valid_until: transFunc('services:heading.valid_until'),
  price: transFunc('services:heading.price'),
  minimal_price: transFunc('services:heading.minimal_price'),
  contract_name: transFunc('services:heading.contract'),
  contract_number: transFunc('services:heading.number'),
  contract_date: transFunc('services:heading.date'),
}

export const GroupsTableHeading = {
  name: transFunc('groups:heading.name'),
  users: transFunc('groups:heading.users'),
  terminals: transFunc('groups:heading.terminals')
}

export const ObjectsAllowed = {
  only_in_plan: transFunc('order_type:only_in_plan'),
  any: transFunc('order_type:any')
}

export const MakerAlgorithm = {
  many_objects: transFunc('order_type:many_objects'),
  move_to_storage: transFunc('order_type:move_to_storage'),
  move_from_storage: transFunc('order_type:move_from_storage')
}

export const PlannedOrderField = {
  sort_index: transFunc('order_type:sorting'),
  description: transFunc('common:description'),
  destination_description: transFunc('order_type:hint'),
  destination: transFunc('order_type:location'),
  maker_algorithm: transFunc('order_type:algorithm'),
  objects_allowed: transFunc('order_type:work_with_objects')
}

export const RecalculateFields = {
  account_oguid: transFunc('common:account'),
  date_from: transFunc('services:effective_date'),
  date_to: transFunc('services:expiration_date')
}

export const GroupField = {
  name: transFunc('groups:group_name'),
  terminals: transFunc('groups:heading.terminals'),
  all_terminals_access: transFunc('groups:all_terminals_access')
}

export const MembersTableHeading = {
  full_name: transFunc('groups:heading.full_name'),
  email: transFunc('groups:heading.email'),
  position: transFunc('groups:heading.position')
}

export const AccountTablesHeading = {
  object_type: transFunc('accounts:additional_fields_header.object_type'),
  field_caption: transFunc('accounts:additional_fields_header.field_caption'),
  field_code: transFunc('accounts:additional_fields_header.field_code'),
  field_type: transFunc('accounts:additional_fields_header.field_type')
}

export const AdditionFieldsModalField = {
  field_code: transFunc('accounts:additional_fields_header.field_code'),
  field_caption: transFunc('accounts:additional_fields_header.field_caption'),
  object_type: transFunc('accounts:object_type'),
  field_type: transFunc('accounts:field_type')
}

export const AdditionalFieldType = {
  number: transFunc('accounts:field_types.number'),
  string: transFunc('accounts:field_types.string'),
  date: transFunc('accounts:field_types.date'),
  datetime: transFunc('accounts:field_types.datetime'),
  boolean: transFunc('accounts:field_types.boolean'),
  dictionary: transFunc('accounts:field_types.dictionary')
}

export const BooleanOptions = {
  true: transFunc('common:yes'),
  false: transFunc('common:no')
}

export const DayOptions = {
  today: transFunc('filters:day.today'),
  tomorrow: transFunc('filters:day.tomorrow'),
  yesterday: transFunc('filters:day.yesterday')
}

export const MonthOptions = {
  nextmonth: transFunc('filters:month.next'),
  prevmonth: transFunc('filters:month.prev'),
  thismonth: transFunc('filters:month.this')
}

export const WeekOptions = {
  nextweek: transFunc('filters:week.next'),
  prevweek: transFunc('filters:week.prev'),
  thisweek: transFunc('filters:week.this')
}

export const ObjectTemplates = {
  standart: {
    name: transFunc('archive:modals.download_template'),
    notice: transFunc('archive:modals.notice')
  },
  account: {
    name: transFunc('archive:modals.download_template_account'),
    notice: transFunc('archive:modals.notice_account')
  },
  object_type: {
    name: transFunc('archive:modals.download_template_object_type'),
    notice: transFunc('archive:modals.notice_object_type')
  },
}
