import { Actions as ActionsOuterCompany } from '@store/modules/outer_company/types'
import { Actions, IOuterCompanyState, OuterCompanyActionTypes } from './types'

const initialState: IOuterCompanyState = {
  outerCompanyList: [],
  activeContractor: {
    oguid: '',
    name: '',
    is_outer: false
  }
}

export function outerCompanyReducer (
  state: IOuterCompanyState = initialState,
  action: Actions | ActionsOuterCompany
): IOuterCompanyState {
  switch (action.type) {
    case OuterCompanyActionTypes.SET_OUTER_COMPANIES:
      return { ...state, outerCompanyList: [...action.payload] }

    case OuterCompanyActionTypes.SET_ACTIVE_CONTRACTOR:
      return { ...state, activeContractor: action.payload }

    case OuterCompanyActionTypes.ADD_OUTER_COMPANY:
      return { ...state, outerCompanyList: [action.payload, ...state.outerCompanyList] }

    case OuterCompanyActionTypes.CHANGE_OUTER_COMPANY: {
      return {
        ...state,
        outerCompanyList: state.outerCompanyList.map(outerCompany => {
          if (outerCompany.oguid !== action.payload.oguid) return outerCompany
          return action.payload
        })
      }
    }

    case OuterCompanyActionTypes.DELETE_OUTER_COMPANY: {
      return {
        ...state,
        outerCompanyList: state.outerCompanyList.filter(outerCompany => outerCompany.oguid !== action.payload)
      }
    }

    default:
      return state
  }
}
