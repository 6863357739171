import { Action, ActionCreator } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'

import { IApplicationState } from '@store/types/commonTypes'

import { IReport, IReportsState, IReportType } from './types'

export enum ReportsActionTypes {
  SET_REPORT_TYPES = 'SET_REPORT_TYPES',
  SET_REPORTS = 'SET_REPORTS'
}

export interface IActionSetReports extends Action<string> {
  payload: IReport[]
  type: ReportsActionTypes.SET_REPORTS
}

export interface IActionSetReportTypes extends Action<string> {
  payload: IReportType[]
  type: ReportsActionTypes.SET_REPORT_TYPES
}

export type Actions = IActionSetReports | IActionSetReportTypes

export enum ActionKeys {
  SET_REPORTS = 'setReports',
  SET_REPORT_TYPES = 'setReportTypes'
}

export type ReportsActions = { [key in ActionKeys]: ActionCreator<Action<string>> }

export type ReportsThunkAction = ThunkAction<void, IReportsState, null, Action<string>>

export type ReportsThunkDispatch = ThunkDispatch<IApplicationState, void, Action<string>>
