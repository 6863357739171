import { Action, ActionCreator } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AxiosResponse } from 'axios'

import { IApplicationState, Nullable } from '@store/types/commonTypes'

export interface INewGroupTerminal {
  oguid: string
}

export interface IGroupTerminal extends INewGroupTerminal {
  name: string
}

export interface IGroup {
  oguid: string
  name: string
  users_count: number
  terminals: Nullable<IGroupTerminal[]>
}

export interface INewGroup {
  name: string
  terminals: Nullable<INewGroupTerminal[]>
}

export interface IGroupMember {
  oguid: string
  surname: string
  name: string
  patronymic: string
  position: string
  email: string
}

export enum GroupsActionTypes {
  SET_GROUPS = 'SET_GROUPS',
  SET_GROUP = 'SET_GROUP',
  SET_MEMBERS = 'SET_MEMBERS'
}

export interface IActionSetGroups extends Action<string> {
  type: GroupsActionTypes.SET_GROUPS
  payload: IGroup[]
}

export interface IActionSetGroup extends Action<string> {
  type: GroupsActionTypes.SET_GROUP
  payload: IGroup
}

export interface IActionSetMembers extends Action<string> {
  type: GroupsActionTypes.SET_MEMBERS
  payload: IGroupMember[]
}

export interface IGroupsState {
  data: IGroup[]
  activeGroup: IGroup
  members: IGroupMember[]
}

export type Actions = IActionSetGroups | IActionSetGroup | IActionSetMembers

type ActionKeys = 'setGroups' | 'setGroup' | 'setMembers'

export type GroupsActions = { [key in ActionKeys]: ActionCreator<Action<string>> }

export type GroupsThunkDispatch = ThunkDispatch<IGroupsState, void, Action<string>>

export type GroupsThunkAction = ThunkAction<
  Promise<AxiosResponse> | Promise<void>,
  IApplicationState,
  void,
  Action<string>
>
