import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import { Header as LibraryHeader, getSurnameWithInitials } from '@infologistics/frontend-libraries'

import Buttons from './components/Buttons'
import Logo from './components/Logo'
import Organizations from './components/Organizations'
import { IApplicationState } from '@store/types/commonTypes'
import { IUserState } from '@store/modules/user/types'

const Header: FC = () => {
  const user: IUserState  = useSelector((state: IApplicationState) => state.user)
  const classInfo = 'd-flex align-items-center ml-auto mr-3 text-muted-600 mb-0 fw-400'

  return (
    <LibraryHeader>
      <Logo />

      <Organizations />

      <div className={classInfo}>
        <span className='mr-4'>{getSurnameWithInitials(user)}</span>
        <Buttons />
      </div>
    </LibraryHeader>
  )
}

export default Header
