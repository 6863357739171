import { Action, ActionCreator } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'

import { IApplicationState, ILink, Nullable } from '@store/types/commonTypes'
import * as IBarcodesActions from './typesActions'

export enum BarcodesActionTypes {
  SET_BARCODES = 'SET_BARCODES',
  SET_BARCODE_RANGE = 'SET_BARCODE_RANGE',
  RESET_BARCODE_RANGE = 'RESET_BARCODE_RANGE',
  UPDATE_BARCODE_RANGE = 'UPDATE_BARCODE_RANGE'
}

export type Actions =
  IBarcodesActions.IActionSetBarcodes |
  IBarcodesActions.IActionSetBarcodeRange |
  IBarcodesActions.IActionResetBarcodeRange |
  IBarcodesActions.IActionUpdateBarcodeRange

type ActionKeys = 'setBarcodes' | 'updateBarcodeRange' | 'setBarcodeRange' | 'resetBarcodeRange'

export type BarcodesActions = { [key in ActionKeys]: ActionCreator<Action<string>> }

export type BarcodesThunkAction = ThunkAction<void, IBarcodesState, null, Action<string>>

export type BarcodesThunkDispatch = ThunkDispatch<IApplicationState, void, Action<string>>

export interface IBarcodesState {
  data: IBarcodeRange[]
  barcodeRange: IBarcodeRange
}

export interface IBarcodeRange {
  id: number
  oguid: string
  object_type: string
  length: number
  prefix: string
  involved: number
  value_from: number
  value_to: number
  account: Nullable<ILink>
  sub_account: Nullable<ILink>
  terminal: Nullable<ILink>
  created_date: number
  updated_date: number
  created_user: {
    oguid: string
    surname: string
    name: string
    patronymic: string
    position: string
  }
  updated_user: {
    oguid: string
    surname: string
    name: string
    patronymic: string
    position: string
  }
}

export interface INewBarcode {
  id: number
  oguid: string
  object_type: string
  length: number
  prefix: string
  involved: number
  value_from: string
  value_to: string
  account: Nullable<ILink>
  sub_account: Nullable<ILink>
  terminal: Nullable<ILink>
}
