import { Action, ActionCreator } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'

import { Operation, OrderStatus } from '@const/consts'
import { EmptyString, IApplicationState, ICreator, Nullable } from '@store/types/commonTypes'
import * as IOrdersActions from './typesActions'

export enum OrdersActionTypes {
  DELETE_ORDER = 'DELETE_ORDER',
  RESET_ACTIVE_ORDER = 'RESET_ACTIVE_ORDER',
  RESET_ORDERS = 'RESET_ORDERS',
  SET_ACTIVE_OPERATION = 'SET_ACTIVE_OPERATION',
  SET_ACTIVE_ORDER = 'SET_ACTIVE_ORDER',
  SET_ACTIVE_TYPE = 'SET_ACTIVE_TYPE',
  RESET_ACTIVE_TYPE = 'RESET_ACTIVE_TYPE',
  SET_DASHBOARD = 'SET_DASHBOARD',
  SET_OPERATIONS = 'SET_OPERATIONS',
  SET_ORDER = 'SET_ORDER',
  SET_ORDER_TYPES = 'SET_ORDER_TYPES',
  SET_ORDERS = 'SET_ORDERS',
  UPDATE_ORDER_STATE = 'UPDATE_ORDER_STATE',
  SET_PLANNED_OPERATIONS = 'SET_PLANNED_OPERATIONS',
  SET_ACTIVE_PLANNED_OPERATION = 'SET_ACTIVE_PLANNED_OPERATION',
  SET_ORDER_HISTORY = 'SET_ORDER_HISTORY',
  SET_ORDER_NOTIFICATIONS = 'SET_ORDER_NOTIFICATIONS',
}

export type Actions =
  | IOrdersActions.IActionDeleteOrder
  | IOrdersActions.IActionResetOrders
  | IOrdersActions.IActionSetOrder
  | IOrdersActions.IActionSetOrderTypes
  | IOrdersActions.IActionSetOrders
  | IOrdersActions.IActionSetActiveOrder
  | IOrdersActions.IActionResetActiveOrder
  | IOrdersActions.IActionUpdateOrderState
  | IOrdersActions.IActionSetDashboard
  | IOrdersActions.IActionSetOperations
  | IOrdersActions.IActionSetActiveOperation
  | IOrdersActions.IActionSetActiveType
  | IOrdersActions.IActionResetActiveType
  | IOrdersActions.IActionSetPlannedOperations
  | IOrdersActions.IActionSetActivePlannedOperation
  | IOrdersActions.IActionSetOrderHistory
  | IOrdersActions.IActionSetOrderNotifications

type ActionKeys =
  | 'deleteOrder'
  | 'setOrder'
  | 'setOrderTypes'
  | 'setOrders'
  | 'setActiveOrder'
  | 'resetActiveOrder'
  | 'resetOrders'
  | 'updateOrderState'
  | 'setDashboard'
  | 'setOperations'
  | 'setActiveOperation'
  | 'setActiveType'
  | 'resetActiveType'
  | 'setPlannedOperations'
  | 'setActivePlannedOperation'
  | 'setOrderHistory'
  | 'setOrderNotifications'

export type OrdersActions = { [key in ActionKeys]: ActionCreator<Action<string>> }

export type OrdersThunkAction = ThunkAction<void, IApplicationState, null, Action<string>>

export type OrdersThunkDispatch = ThunkDispatch<IApplicationState, void, Action<string>>

export interface IOrder<sumType> {
  id: number
  account: IFieldType
  sub_account: IFieldType
  address: string
  oguid: string
  city: string
  contact_name: string
  contact_phone: string
  contact_email: string
  org_oguid: string
  request: IRequestType
  order_type: IFieldType
  terminal: IFieldType
  created_date: number
  created_user: ICreator
  assigned_date: Nullable<number>
  assigned_to: Nullable<ICreator>
  assigned_to_group: Nullable<IGroup>
  start_user: Nullable<ICreator>
  start_date: Nullable<number>
  accomplish_user: Nullable<ICreator>
  accomplish_date: Nullable<number>
  decline_user: Nullable<ICreator>
  decline_date: Nullable<number>
  order_additional_volume: number
  outer_company: IFieldType
  outer_order_number: string
  outer_order_date: OrderNumberField
  outer_planned_date: OrderNumberField
  outer_sum_with_nds: sumType
  order_comment: string
  pass_is_needed: boolean
  status: string
  is_postponed_execution: boolean
  is_copy_objects: boolean
}

export interface IRequestType {
  oguid: string
  name: string
  id: number
  request_date: number
  request_category: string
  request_type: string
}

export interface IFieldType {
  oguid: string
  name: string
}

export interface IGroup {
  oguid: string
  name: string
}

export interface IOrdersState {
  activeOperation: Nullable<IOperationsDashboard>
  activeOrder: IOrder<OrderNumberField>
  data: Array<IOrder<OrderNumberField>>
  operations: IOperation[]
  operationsDashboard: IOperationsDashboard[]
  types: ITaskType[]
  activeType: ITaskType
  plannedOperations: IPlannedOperation[]
  activePlannedOperation: IActivePlannedOperation
  history: IHistory[]
  orderNotifications: IOrderNotification[]
}

export interface IStatusData {
  assigned_to: Nullable<string>
  assigned_to_group: Nullable<string>
  status: string
  state_comment?: Nullable<string>
}

export type OrderNumberField = Nullable<number>

export interface IOrderState {
  accomplish_date: Nullable<number>
  accomplish_user: Nullable<ICreator>
  assigned_date: Nullable<number>
  assigned_to: Nullable<ICreator>
  created_date: number
  created_user: ICreator
  decline_user: Nullable<ICreator>
  start_date: Nullable<number>
  start_user: Nullable<ICreator>
  state_comment: Nullable<string>
  status: OrderStatus
}

export interface IOperationsDashboard {
  operation_id: Nullable<number>
  operation_oguid: Nullable<string>
  planned_operations_maker_oguid: Nullable<string>
  sort_index: Nullable<number>
  description: Nullable<string>
  maker_algorithm: Nullable<MakerAlgorithm>
  objects_allowed: Nullable<string>
  operation_type: Nullable<OperationType>
  check_status?: Nullable<Status>
  objects?: string[]
  objects_count?: Nullable<number>
}

export enum Status {
  ERROR = 'error',
  DECLARED = 'declared',
  CONFIRM = 'confirm',
  BY_FACT = 'by_fact'
}

export enum OperationType {
  OUT = 'out',
  PICK_UP = 'pick_up',
  PUT = 'put',
  ATTACH = 'attach',
  DETACH = 'detach',
  ATTACH_TO_TR = 'attach_to_tr',
  PERMANENTOUT = 'PermanentOUT',
  DESTROY = 'DESTROY'
}

export interface IOperation {
  check_location: Nullable<string>
  check_parent_barcode_level1: Nullable<string>
  check_parent_barcode_level2: Nullable<string>
  planned_destination?: Nullable<string>
  new_destination: Nullable<string>
  old_destination: Nullable<string>
  barcode: string
  operation_id: Nullable<number>
  operation_oguid: Nullable<string>
  operation_date: Nullable<number>
  operation_user: Nullable<ICreator>
  planned_operations_maker_oguid: Nullable<string>
  check_status: Nullable<Status>
  operation_type: Nullable<OperationType>
}

export interface ITaskType {
  oguid: string
  code: string
  name: string
  is_outer: boolean
  is_autoclose_request: boolean
  allowed_object_type: Nullable<string>
  is_payable: boolean
  allowed_operations_type: Nullable<Operation[]>
  is_objects_required: boolean
  services: Nullable<IOrderServiceType[]>
}

export interface INewTaskType {
  code: string
  name: string
  is_outer: boolean
  is_autoclose_request?: Nullable<boolean>
  allowed_object_type?: Nullable<string>
  is_payable: boolean
  allowed_operations_type: Nullable<Operation[]>
  is_objects_required: boolean
  services: Nullable<IEditedOrderTypeService[]>
}

export interface IEditedOrderTypeService {
  service_code: string
}

export type IEditedTaskType = INewTaskType & { oguid: string }

export interface IOrderServiceType {
  service_code: string
  service_name: string
}

export enum MakerAlgorithm {
  MANY_OBJECTS = 'many_objects',
  MOVE_TO_STORAGE = 'move_to_storage',
  MOVE_FROM_STORAGE = 'move_from_storage'
}

export enum ObjectsAllowed {
  ANY = 'any',
  ONLY_IN_PLAN = 'only_in_plan'
}

export interface INewPlannedOperation {
  sort_index: number
  description: string
  destination_description: string
  destination: Nullable<string>
  maker_algorithm: MakerAlgorithm
  objects_allowed: ObjectsAllowed
}

export interface IPlannedOperation extends INewPlannedOperation {
  oguid: string
}

export interface IActivePlannedOperation {
  oguid: string
  sort_index: EmptyString<number>
  description: string
  destination_description: string
  destination: Nullable<string>
  maker_algorithm: EmptyString<MakerAlgorithm>
  objects_allowed: EmptyString<ObjectsAllowed>
}

export interface IHistory {
  action_type: OrderStatus
  action_date: number
  action_user: ICreator
  assigned_to_user: Nullable<ICreator>
  assigned_to_group: Nullable<IGroup>
}

export interface INewOrderNotification {
  request_oguid: string
  request_name: string
  request_number: string
  request_date: number
  account_name: string
  address: string
  contact_name: string
  contact_phone: string
  contact_email: string
  copy_to_email: Nullable<string>
  car_plate: string
  driver_contact: string
  forwarder_contact: Nullable<string>
  quantity_info: Nullable<string>
  planned_date: number
  planned_from_hour: string
  planned_to_hour: string
  track_number: Nullable<string>
}

export interface IOrderNotification extends INewOrderNotification {
  oguid: string
  created_date: number
  is_read: boolean
}
