import { AxiosError, AxiosResponse } from 'axios'
import { Action, ActionCreator } from 'redux'

import { ResetError, Role, SuccessCode, UserStatusesEnum } from '@const/consts'
import store from '@store/configureStore'
import { setPagination } from '@store/modules/pagination/actions'
import { getPagination } from '@utils/pagination'
import {
  ActionKey,
  EditableUser,
  IEditedUser,
  INewUser,
  IRecipient,
  IRecipientsParams,
  IUser,
  IUserData,
  UsersActions,
  UsersActionTypes,
  UsersThunkAction,
  UsersThunkDispatch
} from './types'
import * as IUsersActions from './typesActions'
import usersService from '@services/users'
import { IParams, ISuccessfulRequest } from '@store/types/commonTypes'

const getEditableUser = (): EditableUser => store.getState().users.editableUser

// actions

const getUserAction: ActionCreator<Action> = (
  data: IUserData<UserStatusesEnum, Role>
): IUsersActions.IActionGetUser => ({
  payload: data,
  type: UsersActionTypes.GET_USER
})

const getUserAccountsAction: ActionCreator<Action> = (
  data: IUserData<UserStatusesEnum, Role>
): IUsersActions.IActionGetUserAccounts => ({
  payload: data,
  type: UsersActionTypes.GET_USER_ACCOUNTS
})

const getUsersAction: ActionCreator<Action> = (data: IUser[]): IUsersActions.IActionGetUsers => ({
  payload: data,
  type: UsersActionTypes.GET_USERS
})

const getAllUsersAction: ActionCreator<Action> = (data: IUser[]): IUsersActions.IActionGetAllUsers => ({
  payload: data,
  type: UsersActionTypes.GET_ALL_USERS
})

const setRecipientsAction: ActionCreator<Action> = (recipients: IRecipient[]): IUsersActions.IActionSetRecipients => ({
  payload: recipients,
  type: UsersActionTypes.SET_RECIPIENTS
})

const getUpdateUserAction: ActionCreator<Action> = (
  data: IUserData<UserStatusesEnum, Role>
): IUsersActions.IActionUpdateUser => ({
  payload: data,
  type: UsersActionTypes.UPDATE_USER
})

export const actions: UsersActions = {
  [ActionKey.GET_ALL_USERS]: getAllUsersAction,
  [ActionKey.GET_USER]: getUserAction,
  [ActionKey.GET_USER_ACCOUNTS]: getUserAccountsAction,
  [ActionKey.GET_USERS]: getUsersAction,
  [ActionKey.SET_RECIPIENTS]: setRecipientsAction,
  [ActionKey.UPDATE_USER]: getUpdateUserAction
}

// thunks

export const getUser: ActionCreator<UsersThunkAction> = (userOguid: string) =>
  (dispatch: UsersThunkDispatch): Promise<AxiosResponse> =>
    usersService.getUser(userOguid)
      .then((resp: AxiosResponse) => {
        if (resp.status === SuccessCode.GET) {
          dispatch(actions[ActionKey.GET_USER](resp.data))
        }

        return resp
      })
      .catch((error: AxiosError) => Promise.reject(error))

export const getUsers: ActionCreator<UsersThunkAction> = (params: IParams) =>
  (dispatch: UsersThunkDispatch) => {
    if (store.getState().organizations.activeOrganization.user_role === Role.TSD) {
      return Promise.resolve()
    }

    return usersService.getAllUsers(params)
      .then((resp: AxiosResponse) => {
        if (resp.status === SuccessCode.GET) {
          dispatch(actions[ActionKey.GET_USERS](resp.data))
          dispatch(setPagination(getPagination(resp.headers)))
        }

        return resp
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }

export const getAllUsers: ActionCreator<UsersThunkAction> = (params: IParams = {}) =>
  (dispatch: UsersThunkDispatch): Promise<AxiosResponse> =>
    usersService.getAllUsers(params)
      .then((resp: AxiosResponse) => {
        if (resp.status === SuccessCode.GET) {
          dispatch(actions[ActionKey.GET_ALL_USERS](resp.data))
        }

        return resp
      })
      .catch((error: AxiosError) => Promise.reject(error))

export const updateUser: ActionCreator<UsersThunkAction> = (userOguid: string, data: IEditedUser) =>
  (dispatch: UsersThunkDispatch): Promise<AxiosResponse<ISuccessfulRequest>> =>
    usersService.updateUser(userOguid, data)
      .then((resp: AxiosResponse<ISuccessfulRequest>) => {
        const editableUser = { ...getEditableUser() }
        editableUser.status = data.status
        if (SuccessCode.PUT.includes(resp.status)) {
          dispatch(actions[ActionKey.UPDATE_USER](editableUser))
        }

        return resp
      })
      .catch((error: AxiosError) => Promise.reject(error))

export const getRecipients: ActionCreator<UsersThunkAction> = (params: IRecipientsParams) => (
  dispatch: UsersThunkDispatch
): Promise<AxiosResponse<IRecipient[]>> =>
  usersService.getRecipients(params)
    .then((resp: AxiosResponse<IRecipient[]>) => {
      if (resp.status === SuccessCode.GET) {
        dispatch(actions[ActionKey.SET_RECIPIENTS](resp.data))
      }

      return resp
    })
    .catch((error: AxiosError) => Promise.reject(error))

export const getUserAccounts: ActionCreator<UsersThunkAction> = (userOguid: string) =>
  (dispatch: UsersThunkDispatch): Promise<AxiosResponse> =>
    usersService.getUserAccounts(userOguid, ResetError)
      .then((resp: AxiosResponse) => {
        if (resp.status === SuccessCode.GET) {
          dispatch(actions[ActionKey.GET_USER_ACCOUNTS](resp.data))
        }

        return resp
      })
      .catch((error: AxiosError) => Promise.reject(error))

  // Следующие экшены не работают со стором, но используются при работе хука useLoading
export const addUser: ActionCreator<UsersThunkAction> = (data: INewUser) =>
  (): Promise<AxiosResponse<ISuccessfulRequest>> =>
    usersService.addUser(data)
      .catch((error: AxiosError) => Promise.reject(error))

export const deleteUser: ActionCreator<UsersThunkAction> = (userOguid: string) =>
  (): Promise<AxiosResponse> =>
    usersService.deleteUser(userOguid)
