import { Actions, ITerminalData, ITerminalsState, TerminalsActionTypes } from '@store/modules/terminals/types'

const initialState: ITerminalsState = {
  data: [],
  locations: []
}

export function terminalReducer (state: ITerminalsState = initialState, action: Actions): ITerminalsState {
  const { data } = state
  switch (action.type) {
    case TerminalsActionTypes.SET_TERMINALS: {
      return { ...state, data: [...action.payload] }
    }
    case TerminalsActionTypes.SET_TERMINAL: {
      return { ...state, data: [action.payload] }
    }
    case TerminalsActionTypes.SET_LOCATIONS: {
      return { ...state, locations: [...action.payload] }
    }
    case TerminalsActionTypes.DELETE_TERMINAL: {
      const filteredTerminals = data.filter((item: ITerminalData) => item.oguid !== action.payload.oguid)
      return { ...state, data: filteredTerminals }
    }
    case TerminalsActionTypes.EDIT_TERMINAL: {
      const terminals = data.map((item: ITerminalData) => {
        if (item.oguid !== action.payload.oguid) {
          return item
        }

        return {
          ...item,
          ...action.payload
        }
      })

      return { ...state, data: [...terminals] }
    }

    default:
      return state
  }
}
