import { lazy } from 'react'
import { IRoute } from '@common/Routing/types'
import { Path, Role, RolesAdminAndMasterClient, RouteName, Section } from '@const/consts'
import getLazyComponent from '@common/lazyLoading/lazyLoadingFunctions'

const Organization = lazy(() => import('./components/Organization'))
const Users = lazy(() => import('./components/Users/Users'))
const User = lazy(() => import('./components/User'))
const Terminals = lazy(() => import('./components/Terminals'))
const Terminal = lazy(() => import('./components/Terminal'))
const Accounts = lazy(() => import('./components/Accounts'))
const Account = lazy(() => import('./components/Account'))
const OrderTypes = lazy(() => import('./components/OrderTypes'))
const TaskType = lazy(() => import('./components/OrderTypes/components/TaskType'))
const RequestTypes = lazy(() => import('./components/RequestTypes'))
const Services = lazy(() => import('./components/Services/components/Services'))
const Contractors = lazy(() => import('./components/Contractors'))
const Groups = lazy(() => import('./components/Groups/components/Groups'))
const Group = lazy(() => import('./components/Groups/components/Group'))

// TODO переделать роуты так, чтобы добавление элементов было на страницах элементов, а не их списков
export const routes: IRoute[] = [
  {
    component: getLazyComponent(Organization),
    exact: true,
    name: 'OrganizationEdit',
    path: Section.PROPS,
    roles: [Role.ADMIN]
  },
  {
    component: getLazyComponent(Organization),
    exact: true,
    name: 'OrganizationAdd',
    path: RouteName.ADD_ORG,
    roles: [Role.ADMIN]
  },
  {
    component: getLazyComponent(Users),
    exact: true,
    name: 'Users',
    path: Section.USERS,
    roles: RolesAdminAndMasterClient
  },
  {
    component: getLazyComponent(User),
    exact: true,
    name: 'UserAdd',
    path: Section.USERS_ADD,
    roles: RolesAdminAndMasterClient
  },
  {
    component: getLazyComponent(User),
    exact: true,
    name: 'UserEdit',
    path: Path.USERS_EDIT,
    roles: RolesAdminAndMasterClient
  },
  {
    component: getLazyComponent(Terminals),
    exact: true,
    name: 'Terminals',
    path: Section.TERMINALS,
    roles: [Role.ADMIN]
  },
  {
    component: getLazyComponent(Terminal),
    exact: true,
    name: 'TerminalEdit',
    path: Path.TERMINALS_EDIT,
    roles: [Role.ADMIN]
  },
  {
    component: getLazyComponent(Accounts),
    exact: true,
    name: 'Accounts',
    path: Section.ACCOUNTS,
    roles: RolesAdminAndMasterClient
  },
  {
    component: getLazyComponent(Account),
    exact: true,
    name: 'AccountAdd',
    path: Section.ACCOUNTS_ADD,
    roles: [Role.ADMIN]
  },
  {
    component: getLazyComponent(Account),
    exact: true,
    name: 'AccountEdit',
    path: Path.ACCOUNTS_EDIT,
    roles: RolesAdminAndMasterClient
  },
  {
    component: getLazyComponent(OrderTypes),
    exact: true,
    name: 'TaskTypes',
    path: Section.TASK_TYPES,
    roles: [Role.ADMIN]
  },
  {
    component: getLazyComponent(TaskType),
    exact: true,
    name: 'TaskTypeAdd',
    path: Section.TASK_TYPES_ADD,
    roles: [Role.ADMIN]
  },
  {
    component: getLazyComponent(TaskType),
    exact: true,
    name: 'TaskTypeEdit',
    path: Path.TASK_TYPES_EDIT,
    roles: [Role.ADMIN]
  },
  {
    component: getLazyComponent(RequestTypes),
    exact: true,
    name: 'RequestTypes',
    path: Section.REQUEST_TYPES,
    roles: [Role.ADMIN]
  },
  {
    component: getLazyComponent(Services),
    exact: true,
    name: 'Services',
    path: Section.SERVICES,
    roles: [Role.ADMIN]
  },
  {
    component: getLazyComponent(Contractors),
    exact: true,
    name: 'Contractors',
    path: Section.CONTRACTORS,
    roles: [Role.ADMIN]
  },
  {
    component: getLazyComponent(Groups),
    exact: true,
    name: 'Groups',
    path: Section.GROUPS,
    roles: [Role.ADMIN]
  },
  {
    component: getLazyComponent(Group),
    exact: true,
    name: 'GroupAdd',
    path: Section.GROUPS_ADD,
    roles: [Role.ADMIN]
  },
  {
    component: getLazyComponent(Group),
    exact: true,
    name: 'GroupEdit',
    path: Path.GROUPS_EDIT,
    roles: [Role.ADMIN]
  }
]
