import React, { ComponentType, FC, LazyExoticComponent, Suspense } from 'react'
import { Loader } from '@infologistics/frontend-libraries'

/**
 * Функция-обвёртка для того что бы передавать в Route компонент с дополнительными props
 * @param ComponentLazy - React.lazy
 */
const getLazyComponent = (ComponentLazy: LazyExoticComponent<ComponentType<any>>): FC => {
  const LazyComponentWithSuspense: FC = props => (
    <Suspense fallback={<Loader loading />}>
      <ComponentLazy {...props} />
    </Suspense>
  )

  return LazyComponentWithSuspense
}

export default getLazyComponent
