import { IObject } from '@store/modules/objects/types'
import { Actions, CartActionTypes } from './types'

const initialState: IObject[] = []

export function cartReducer (state: IObject[] = initialState, action: Actions): IObject[] {
  switch (action.type) {
    case CartActionTypes.REMOVE_CART_ITEM: {
      const newCart = state.filter((item: IObject) => action.payload !== item.barcode)
      return [...newCart]
    }
    case CartActionTypes.ADD_CART_ITEM: {
      return [...state, ...action.payload]
    }
    case CartActionTypes.UPDATE_CART_ITEM: {
      return [...action.payload]
    }
    case CartActionTypes.CLEAN_CART: {
      return []
    }
    default:
      return state
  }
}
