import React, { ReactElement } from 'react'
import { Route, Switch } from 'react-router-dom'

import { getRoutesList } from '@utils/utils'
import Page404 from '@views/misc/components/Page404'

import { IProps } from './types'

export const RenderRoutes = ({ routes }: IProps): ReactElement => (
  <Switch>
    {getRoutesList(routes)}
    <Route component={Page404} />
  </Switch>
)
