import { AxiosError, AxiosResponse } from 'axios'
import { Action, ActionCreator } from 'redux'

import { Role, SuccessCode } from '@const/consts'
import organizationsService from '@services/organizations'

import { cleanCart } from '@store/modules/cart/actions'
import {
  INewOrganization,
  IOrganization,
  OrganizationsActions,
  OrganizationsActionTypes,
  OrganizationsThunkAction,
  OrganizationsThunkDispatch
} from './types'
import * as IOrganizationsActions from './typesActions'
import { ISuccessfulRequest } from '@store/types/commonTypes'

// actions

const addOrganizationAction: ActionCreator<Action> = (
  data: IOrganization
): IOrganizationsActions.IActionAddOrganization => ({
  payload: data,
  type: OrganizationsActionTypes.ADD_ORGANIZATION
})

const changeOrganizationAction: ActionCreator<Action> = (
  data: IOrganization
): IOrganizationsActions.IActionChangeOrganization => ({
  payload: data,
  type: OrganizationsActionTypes.CHANGE_ORGANIZATION
})

const updateOrganizationAction: ActionCreator<Action> = (
  data: IOrganization
): IOrganizationsActions.IActionUpdateOrganization => ({
  payload: data,
  type: OrganizationsActionTypes.UPDATE_ORGANIZATION
})

export const actions: OrganizationsActions = {
  addOrganization: addOrganizationAction,
  changeOrganization: changeOrganizationAction,
  updateOrganization: updateOrganizationAction
}

// thunk

export const addOrganization: ActionCreator<OrganizationsThunkAction> = (data: INewOrganization) =>
  (dispatch: OrganizationsThunkDispatch): Promise<AxiosResponse<ISuccessfulRequest>> =>
    organizationsService.addOrganization(data)
      .then((resp: AxiosResponse<ISuccessfulRequest>) => {
        if (SuccessCode.POST.includes(resp.status)) {
          const payload = { ...resp.data, name: data.name, user_role: Role.ADMIN }
          dispatch(actions.addOrganization(payload))
        }

        return resp
      })
      .catch((error: AxiosError) => Promise.reject(error))

export const updateOrganization: ActionCreator<OrganizationsThunkAction> = (
  orgOguid: string,
  data: INewOrganization
) => (dispatch: OrganizationsThunkDispatch): Promise<AxiosResponse<ISuccessfulRequest>> =>
  organizationsService.updateOrganization(orgOguid, data)
    .then((resp: AxiosResponse<ISuccessfulRequest>) => {
      if (SuccessCode.PUT.includes(resp.status)) {
        const payload = { ...resp.data, name: data.name }
        dispatch(actions.updateOrganization(payload))
      }

      return resp
    })
    .catch((error: AxiosError) => Promise.reject(error))

export const changeOrganization: ActionCreator<OrganizationsThunkAction> = (organizationData: IOrganization) => {
  return (dispatch: OrganizationsThunkDispatch) => {
    dispatch(actions.changeOrganization(organizationData))
    dispatch(cleanCart())
  }
}
