import * as IOuterCompanyActions from '@app/store/modules/outer_company/typesActions'
import { Action, ActionCreator } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'

import { IApplicationState } from '@store/types/commonTypes'

export enum OuterCompanyActionTypes {
  SET_OUTER_COMPANIES = 'SET_OUTER_COMPANIES',
  ADD_OUTER_COMPANY = 'ADD_OUTER_COMPANY',
  CHANGE_OUTER_COMPANY = 'CHANGE_OUTER_COMPANY',
  DELETE_OUTER_COMPANY = 'DELETE_OUTER_COMPANY',
  SET_ACTIVE_CONTRACTOR = 'SET_ACTIVE_CONTRACTOR'
}

export type Actions =
  | IOuterCompanyActions.IActionAddOuterCompany
  | IOuterCompanyActions.IActionChangeOuterCompany
  | IOuterCompanyActions.IActionDeleteOuterCompany
  | IOuterCompanyActions.IActionSetOuterCompanies
  | IOuterCompanyActions.IActionSetActiveContractor

type ActionKeys = 'addOuterCompany' | 'changeOuterCompany' | 'deleteOuterCompany' | 'setOuterCompanies' | 'setActiveContractor'

export type OuterCompanyActions = { [key in ActionKeys]: ActionCreator<Action<string>> }

export type OuterCompanyThunkAction = ThunkAction<void, IOuterCompanyState, null, Action<string>>

export type OuterCompanyThunkDispatch = ThunkDispatch<IApplicationState, void, Action<string>>

export interface IOuterCompanyState {
  outerCompanyList: IOuterCompany[]
  activeContractor: IOuterCompany
}

export interface IOuterCompany {
  oguid: string
  name: string
  is_outer: boolean
}

export interface INewOuterCompany {
  name: string
  is_outer: boolean
}
