import { IActivePlannedOperation, IOrder, IOrderNotification, OrderNumberField } from '@store/modules/orders/types'

export const orderInitialState: IOrder<OrderNumberField> = {
  accomplish_date: 0,
  accomplish_user: {
    name: '',
    oguid: '',
    patronymic: '',
    position: '',
    surname: ''
  },
  account: {
    name: '',
    oguid: ''
  },
  address: '',
  assigned_date: 0,
  assigned_to: {
    name: '',
    oguid: '',
    patronymic: '',
    position: '',
    surname: ''
  },
  assigned_to_group: null,
  city: '',
  contact_name: '',
  contact_phone: '',
  contact_email: '',
  created_date: 0,
  created_user: {
    name: '',
    oguid: '',
    patronymic: '',
    position: '',
    surname: ''
  },
  decline_date: 0,
  decline_user: {
    name: '',
    oguid: '',
    patronymic: '',
    position: '',
    surname: ''
  },
  id: 0,
  is_copy_objects: false,
  is_postponed_execution: false,
  oguid: '',
  order_additional_volume: 0,
  order_comment: '',
  order_type: {
    name: '',
    oguid: ''
  },
  org_oguid: '',
  outer_company: {
    name: '',
    oguid: ''
  },
  outer_order_date: null,
  outer_order_number: '',
  outer_planned_date: null,
  outer_sum_with_nds: 0,
  pass_is_needed: false,
  request: {
    id: 0,
    name: '',
    oguid: '',
    request_date: 0,
    request_category: '',
    request_type: ''
  },
  start_date: 0,
  start_user: {
    name: '',
    oguid: '',
    patronymic: '',
    position: '',
    surname: ''
  },
  status: '',
  sub_account: {
    name: '',
    oguid: ''
  },
  terminal: {
    name: '',
    oguid: ''
  }
}

export const initialType = {
  allowed_object_type: null,
  allowed_operations_type: [],
  code: '',
  is_autoclose_request: false,
  is_objects_required: false,
  is_outer: false,
  is_payable: false,
  name: '',
  oguid: '',
  services: null
}

export const initialPlannedOperation: IActivePlannedOperation = {
  oguid: '',
  sort_index: '',
  description: '',
  destination_description: '',
  destination: null,
  maker_algorithm: '',
  objects_allowed: ''
}

export const initialOrderNotification: IOrderNotification = {
  oguid: '',
  request_oguid: '',
  request_name: '',
  request_number: '',
  request_date: 0,
  account_name: '',
  address: '',
  contact_name: '',
  contact_phone: '',
  contact_email: '',
  copy_to_email: null,
  car_plate: '',
  created_date: 0,
  driver_contact: '',
  is_read: false,
  forwarder_contact: null,
  quantity_info: null,
  planned_date: 0,
  planned_from_hour: '',
  planned_to_hour: '',
  track_number: null
}
