import React, { ReactElement, Suspense } from 'react'
import { Provider } from 'react-redux'

import { Loader } from '@infologistics/frontend-libraries'

import { LayoutRouter } from '@common/layout/components'
import store from '@store/configureStore'
import Startup from '@views/startup/components/Startup'

import '@infologistics/frontend-libraries/dist/styles/styles.css'
import { isDevelopment } from '@const/consts'
import ButtonInstance from '@common/ButtonInstance'

export default class App extends React.Component {
  public render (): ReactElement {
    return (
      <Provider store={store}>
        <Suspense fallback={<Loader loading />}>
          <Startup>
            <LayoutRouter />
          </Startup>

          { isDevelopment && <ButtonInstance />}
        </Suspense>
      </Provider>
    )
  }
}
