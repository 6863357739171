import { lazy } from 'react'

import { IRoute } from '@common/Routing/types'
import { Path, RolesWithoutTSD, RouteName } from '@const/consts'
import getLazyComponent from '@common/lazyLoading/lazyLoadingFunctions'
const Requests = lazy(() => import('./components/Requests'))
const Request = lazy(() => import('./components/Request/Request'))
const RequestDraft = lazy(() => import('./components/RequestDraft'))

export const routes: IRoute[] = [
  {
    component: getLazyComponent(Requests),
    exact: true,
    name: 'Requests',
    path: RouteName.REQUESTS,
    roles: RolesWithoutTSD
  },
  {
    component: getLazyComponent(Request),
    exact: true,
    name: 'Request',
    path: Path.REQUESTS_EDIT,
    roles: RolesWithoutTSD
  },
  {
    component: getLazyComponent(RequestDraft),
    exact: true,
    name: 'RequestDraft',
    path: RouteName.REQUEST_DRAFT,
    roles: RolesWithoutTSD
  }
]
