import { AxiosResponse } from 'axios'
import { Action, ActionCreator } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'

import { EmptyString, IApplicationState, Nullable } from '@store/types/commonTypes'
import { IServiceCalcAlgorithm } from '@store/modules/metadata/types'
import { UrlKey } from '@const/consts'

export interface IServicesState {
  data: IService[]
  service: IInitialService
  servicesInRequestAndOrder: Array<IServiceInRequestAndOrder<QuantityType>>
  activeServiceInRequestAndOrder: IServiceInRequestAndOrder<EmptyString<QuantityType>>
  availableServices: IAvailableService[]
}

export type ServicesThunkAction = ThunkAction<
  Promise<AxiosResponse> | Promise<void>,
  IApplicationState,
  void,
  Action<string>
>

export type ServicesThunkDispatch = ThunkDispatch<IServicesState, void, Action<string>>

export enum ServicesActionTypes {
  SET_SERVICES = 'SET_SERVICES',
  SET_SERVICE = 'SET_SERVICE',
  SET_SERVICES_IN_REQUEST_AND_ORDER = 'SET_SERVICES_IN_REQUEST_AND_ORDER',
  SET_AVAILABLE_SERVICES = 'SET_AVAILABLE_SERVICES',
  SET_ACTIVE_SERVICE_IN_REQUEST_AND_ORDER = 'SET_ACTIVE_SERVICE_IN_REQUEST_AND_ORDER'
}

export interface IActionSetServices extends Action<string> {
  type: ServicesActionTypes.SET_SERVICES
  payload: IService[]
}

export interface IActionSetService extends Action<string> {
  type: ServicesActionTypes.SET_SERVICE
  payload: INewService | IInitialService
}

export interface IActionSetServicesInRequestAndOrder extends Action<string> {
  payload: Array<IServiceInRequestAndOrder<QuantityType>>
  type: ServicesActionTypes.SET_SERVICES_IN_REQUEST_AND_ORDER
}

export interface IActionSetAvailableServices extends Action<string> {
  payload: IAvailableService[]
  type: ServicesActionTypes.SET_AVAILABLE_SERVICES
}

export interface IActionSetActiveServiceInRequestAndOrder extends Action<string> {
  payload: RequestServiceType
  type: ServicesActionTypes.SET_ACTIVE_SERVICE_IN_REQUEST_AND_ORDER
}

export type Actions =
  | IActionSetServices
  | IActionSetService
  | IActionSetServicesInRequestAndOrder
  | IActionSetAvailableServices
  | IActionSetActiveServiceInRequestAndOrder

type ActionKeys =
  | 'setServices'
  | 'setService'
  | 'setServicesInRequestOrOrder'
  | 'setAvailableServices'
  | 'setActiveServiceInRequestAndOrder'

export type ServicesActions = { [key in ActionKeys]: ActionCreator<Action<string>> }

export interface IService {
  oguid: string
  service_code: string
  service_group: string
  service_name: string
  service_hint: Nullable<string>
  unit: string
  is_default: boolean
  request_available: RequestAvailable
  request_category_available: RequestCategoryAvailable[]
  is_tsd_available: boolean
  service_calc_algorithm: IServiceCalcAlgorithm
  sort_order: number
  quantity_type: QuantityType
}

export enum RequestAvailable {
  NONE = 'none',
  OBJECTS = 'objects',
  ADDITIONAL_SERVICES = 'additional_services'
}

export enum RequestCategoryAvailable {
  PICKUP = 'pickup',
  DELIVERY = 'delivery',
  DIGIT_DELIVERY = 'digit_delivery'
}

export enum QuantityType {
  INT = 'int',
  MONEY = 'money',
  NUMBER = 'number'
}

export interface IEditService {
  service_code: string
  service_group: string
  service_name: string
  service_hint: Nullable<string>
  unit: string
  is_default: boolean
  request_available: RequestAvailable
  request_category_available: RequestCategoryAvailable[]
  is_tsd_available: boolean
  service_calc_algorithm: IServiceCalcAlgorithm
  sort_order: number
  quantity_type: QuantityType
}

export interface IInitialService {
  service_code: string
  service_group: string
  service_name: string
  service_hint: Nullable<string>
  unit: string
  is_default: boolean
  request_available: EmptyString<RequestAvailable>
  request_category_available: RequestCategoryAvailable[]
  is_tsd_available: boolean
  service_calc_algorithm: IServiceCalcAlgorithm
  sort_order: number | string
  quantity_type: EmptyString<QuantityType>
  oguid: string
}

export interface INewService extends IEditService {
  oguid: string
}

export interface IEditServiceProps {
  accountOguid: string
  data: IEditService
}

export interface IAddServiceProps {
  data: IEditService
}

export interface IEditedServiceInRequestAndOrder {
  quantity: number
}

export interface INewServiceInRequestAndOrder extends IEditedServiceInRequestAndOrder {
  service_oguid: string
}

export interface IAvailableService {
  oguid: string
  service_code: string
  service_group: string
  service_name: string
  service_hint: Nullable<string>
  unit: string
  is_default: boolean
  request_available: RequestAvailable
  request_category_available: RequestCategoryAvailable[]
  is_tsd_available: boolean
  service_calc_algorithm: IServiceCalcAlgorithm
  sort_order: number
  quantity_type: QuantityType
}

export interface IServiceInRequestAndOrder<T> {
  service_oguid: string
  service_code: string
  quantity: number
  service_group: string
  service_name: string
  service_hint?: string
  unit: string
  quantity_type: T
}

export type RequestServiceType =
  | IServiceInRequestAndOrder<QuantityType>
  | IServiceInRequestAndOrder<EmptyString<QuantityType>>

export interface IDeleteServiceInRequestOrOrder {
  urlKey: UrlKey
  oguid: string
  serviceOguid: string
}

export interface IEditServiceInRequestOrOrder extends IDeleteServiceInRequestOrOrder {
  data: IEditedServiceInRequestAndOrder
}

export interface IAddServiceInRequestOrOrder {
  urlKey: UrlKey
  oguid: string
  data: INewServiceInRequestAndOrder
}

export interface IRecalculate {
  account_oguid: Nullable<string>
  date_from: number
  date_to: Nullable<number>
}

export interface IRecalculateAnswer {
  code: number
  message: string
}
