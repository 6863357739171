import { Actions, EditableUser, IUsersState, UsersActionTypes } from './types'

const initialCurrentUser: EditableUser = {
  accounts: [],
  contacts: '',
  created_date: '',
  created_user: {
    name: '',
    oguid: '',
    patronymic: '',
    position: '',
    surname: ''
  },
  email: '',
  login: '',
  name: '',
  oguid: '',
  patronymic: '',
  position: '',
  role: '',
  status: '',
  sub_accounts: [],
  surname: '',
  terminals: []
}

const initialState: IUsersState = {
  accountsCurrentUser: [],
  allUsers: [],
  data: [],
  editableUser: initialCurrentUser,
  recipients: []
}

export function usersReducer (state: IUsersState = initialState, action: Actions): IUsersState {
  switch (action.type) {
    case UsersActionTypes.GET_USER: {
      return { ...state, editableUser: { ...action.payload } }
    }
    case UsersActionTypes.GET_USER_ACCOUNTS: {
      const { accounts } = action.payload
      return { ...state, accountsCurrentUser: accounts }
    }
    case UsersActionTypes.GET_USERS: {
      return { ...state, data: [...action.payload] }
    }
    case UsersActionTypes.GET_ALL_USERS: {
      return { ...state, allUsers: [...action.payload] }
    }
    case UsersActionTypes.UPDATE_USER: {
      return { ...state, editableUser: action.payload }
    }
    case UsersActionTypes.SET_RECIPIENTS: {
      return { ...state, recipients: [...action.payload] }
    }
    default:
      return state
  }
}
