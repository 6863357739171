import { Action } from 'redux'

export enum ModalActionTypes {
  HIDE_MODAL = 'HIDE_MODAL',
  SHOW_MODAL = 'SHOW_MODAL'
}

export interface IActionShowModal extends Action<string> {
  payload: string
  type: ModalActionTypes.SHOW_MODAL
}

export interface IActionHideModal extends Action<string> {
  type: ModalActionTypes.HIDE_MODAL
}

export type Actions = IActionShowModal | IActionHideModal

export interface IModalState {
  id: string
}
