import { Action, ActionCreator } from 'redux'
import { IActionHideModal, IActionShowModal, ModalActionTypes } from './types'

export const openModal: ActionCreator<Action> = (id: string): IActionShowModal => {
  return {
    payload: id,
    type: ModalActionTypes.SHOW_MODAL
  }
}

export const hideModal: ActionCreator<Action> = (): IActionHideModal => {
  return {
    type: ModalActionTypes.HIDE_MODAL
  }
}
