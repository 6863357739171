import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useRouteMatch, useHistory } from 'react-router'

import { ActionButton } from '@infologistics/frontend-libraries'

import CartBtn from '@common/layout/components/Header/components/Buttons/components/CartBtn'
import { RouteName } from '@const/consts'
import { logout } from '@store/modules/root/actions'
import { createUrl } from '@utils/utils'
import { AllServiceParams } from '@store/modules/navigation/types'

const Buttons: FC = () => {
  const { t } = useTranslation(['common'])
  const dispatch = useDispatch<any>()
  const match = useRouteMatch<AllServiceParams>()
  const history = useHistory<AllServiceParams>()

  const handleProfileClick = (): void => history.push(createUrl(match, RouteName.PROFILE))

  const handleLogout = (): void => {
    dispatch(logout())
    history.push(RouteName.SIGN_IN)
  }

  return (
    <>
      <CartBtn />
      <ActionButton
        title={t('common:profile')}
        icon='IconUser'
        onClick={handleProfileClick}
        externalClass='mr-4 p-1'
      />
      <ActionButton title={t('common:signout')} icon='IconSignOut' onClick={handleLogout} externalClass='p-1' />
    </>
  )
}

export default Buttons
