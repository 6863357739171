import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { NavLink, NavLinkProps } from 'react-router-dom'

import { IApplicationState, Nullable } from '@store/types/commonTypes'
import { compileOrgLink, createPath } from '@utils/utils'

const CustomNavLink: FC<NavLinkProps> = (props: NavLinkProps) => {
  const { to, ...rest } = props
  const alias: Nullable<string> = useSelector((state: IApplicationState) => state.organizations.activeOrganization.alias)
  const oguid: string = useSelector((state: IApplicationState) => state.organizations.activeOrganization.oguid)

  const link = compileOrgLink(alias, oguid)

  return <NavLink to={createPath(link, to)} {...rest} />
}

export default CustomNavLink
