import { Action, ActionCreator } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'

import { INavItem } from '@const/navSidebar'
import { IApplicationState } from '@store/types/commonTypes'

export enum NavigationActionTypes {
  CLOSE_SHORTCUT = 'CLOSE_SHORTCUT',
  OPEN_SHORTCUT = 'OPEN_SHORTCUT',
  SET_BADGES = 'SET_BADGES',
  SET_MENU_ITEMS = 'SET_MENU_ITEMS',
  SET_FILTERS = 'SET_FILTERS',
  SET_EDITED_FILTER = 'SET_EDITED_FILTER',
  SET_NEW_FILTER = 'SET_NEW_FILTER',
  DELETE_FILTER = 'DELETE_FILTER',
  SET_REQUESTS_ACTIVE_FILTER = 'SET_REQUESTS_ACTIVE_FILTER',
  SET_ORDERS_ACTIVE_FILTER = 'SET_ORDERS_ACTIVE_FILTER'
}

export interface INavigationState {
  badges: IBadges
  items: any[]
  shortcutOpen: boolean
  menuItems: INavItem[]
  userFilters: IUserFilter[]
  ordersActiveFilter: string
  requestsActiveFilter: string
}

interface IOrgParam {
  org: string
}

export interface IIdParam {
  id: string
}

export interface ITypeParam {
  type: string
}

export type AllRouteParams = IOrgParam & IIdParam & ITypeParam

export type AllServiceParams = IOrgParam

export interface INavigationActionCloseShortcut extends Action<string> {
  type: NavigationActionTypes.CLOSE_SHORTCUT
}

export interface INavigationActionOpenShortcut extends Action<string> {
  type: NavigationActionTypes.OPEN_SHORTCUT
}

export interface INavigationActionSetBadges extends Action<string> {
  type: NavigationActionTypes.SET_BADGES
  payload: IBadges
}

export interface IActionSetMenuItems extends Action<string> {
  payload: INavItem[]
  type: NavigationActionTypes.SET_MENU_ITEMS
}

export interface IActionSetFilters extends Action<string> {
  payload: IUserFilter[]
  type: NavigationActionTypes.SET_FILTERS
}

export interface IActionSetEditedFilter extends Action<string> {
  payload: IUserFilter
  type: NavigationActionTypes.SET_EDITED_FILTER
}

export interface IActionSetNewFilter extends Action<string> {
  payload: IUserFilter
  type: NavigationActionTypes.SET_NEW_FILTER
}

export interface IActionDeleteFilter extends Action<string> {
  payload: string
  type: NavigationActionTypes.DELETE_FILTER
}

export interface IActionSetRequestsActiveFilter extends Action<string> {
  payload: string
  type: NavigationActionTypes.SET_REQUESTS_ACTIVE_FILTER
}

export interface IActionSetOrdersActiveFilter extends Action<string> {
  payload: string
  type: NavigationActionTypes.SET_ORDERS_ACTIVE_FILTER
}

export type Actions =
  | INavigationActionCloseShortcut
  | INavigationActionSetBadges
  | INavigationActionOpenShortcut
  | IActionSetMenuItems
  | IActionSetFilters
  | IActionSetEditedFilter
  | IActionSetNewFilter
  | IActionDeleteFilter
  | IActionSetRequestsActiveFilter
  | IActionSetOrdersActiveFilter

type ActionKeys =
  | 'setMenuItems'
  | 'setBadges'
  | 'setFilters'
  | 'setEditedFilter'
  | 'setNewFilter'
  | 'deleteFilter'
  | 'setRequestsActiveFilter'
  | 'setOrdersActiveFilter'

export type NavigationActions = { [key in ActionKeys]: ActionCreator<Action<string>> }

export type NavigationThunkAction = ThunkAction<void, INavigationState, null, Action<string>>

export type NavigationThunkDispatch = ThunkDispatch<IApplicationState, void, Action<string>>

export interface IBadges extends IRequestAndOrderBadges {
  user_oguid: string
}

export interface IRequestAndOrderBadges {
  request: IRequestBadges
  order: IOrderBadges
}

export interface IRequestBadges {
  in_progress_orders_is_null: number
  in_progress_orders_new: number
  in_progress_orders_assigned_to: number
  in_progress_orders_started: number
  in_progress_orders_completed: number
  in_progress_orders_declined: number
  draft: number
  new: number
}

export interface IOrderBadges {
  orders_assigned_to: number
  orders_new: number
  orders_started: number
}

export interface IUserFilter {
  oguid: string
  route: string
  title: string
  isPersonal: boolean
  params: string
}

export interface INewFilter {
  route: string
  title: string
  isPersonal: boolean
  params: string
}
