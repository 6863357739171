import { AxiosError, AxiosResponse } from 'axios'
import { Action, ActionCreator } from 'redux'

import { SuccessCode } from '@const/consts'

import { INewReport, IReport, IReportType } from './types'
import {
  ActionKeys,
  IActionSetReports,
  IActionSetReportTypes,
  ReportsActions,
  ReportsActionTypes,
  ReportsThunkAction,
  ReportsThunkDispatch
} from './typesActions'
import reportsService from '@services/reports'
import { ISuccessfulRequest } from '@store/types/commonTypes'

// actions

const setReportsAction: ActionCreator<Action> = (data: IReport[]): IActionSetReports => ({
  payload: data,
  type: ReportsActionTypes.SET_REPORTS
})

const setReportTypesAction: ActionCreator<Action> = (data: IReportType[]): IActionSetReportTypes => ({
  payload: data,
  type: ReportsActionTypes.SET_REPORT_TYPES
})

export const actions: ReportsActions = {
  [ActionKeys.SET_REPORT_TYPES]: setReportTypesAction,
  [ActionKeys.SET_REPORTS]: setReportsAction
}

// thunks

export const getReportTypes: ActionCreator<ReportsThunkAction> = () => (
  dispatch: ReportsThunkDispatch
): Promise<AxiosResponse<IReportType[]>> =>
  reportsService
    .getReportTypes()
    .then((resp: AxiosResponse<IReportType[]>) => {
      if (resp.status === SuccessCode.GET) {
        const action = actions[ActionKeys.SET_REPORT_TYPES](resp.data)
        dispatch(action)
      }
      return resp
    })
    .catch((error: AxiosError) => Promise.reject(error))

export const getReports: ActionCreator<ReportsThunkAction> = (oguid: string) => (
  dispatch: ReportsThunkDispatch
): Promise<AxiosResponse<IReport[]>> =>
  reportsService
    .getReports(oguid)
    .then((resp: AxiosResponse<IReport[]>) => {
      if (resp.status === SuccessCode.GET) {
        const action = actions[ActionKeys.SET_REPORTS](resp.data)
        dispatch(action)
      }
      return resp
    })
    .catch((error: AxiosError) => Promise.reject(error))

export const postReport: ActionCreator<ReportsThunkAction> = (report: INewReport) =>
  (): Promise<AxiosResponse<IReport[]>> =>
    reportsService.postReport(report)

export const deleteReport: ActionCreator<ReportsThunkAction> = (oguid: string) =>
  (): Promise<AxiosResponse<ISuccessfulRequest>> =>
    reportsService.deleteReport(oguid)

export const getReportFile: ActionCreator<ReportsThunkAction> = (oguid: string) =>
  (): Promise<AxiosResponse<Blob>> =>
    reportsService.getReportFile(oguid, { responseType: 'blob' })
