import React, { FC, MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { ActionButton, ILibModalSizes } from '@infologistics/frontend-libraries'

import ModalWrapper from '@common/ModalWrapper/ModalWrapper'
import { openModal as showModal } from '@store/modules/modal/actions'
import { IApplicationState } from '@store/types/commonTypes'
import CartModal from '@views/modals/Cart/CartModal'

import { ModalId } from '@const/consts'

const CartBtn: FC = () => {
  const { t } = useTranslation(['cart'])
  const dispatch = useDispatch<any>()
  const cartCounter = useSelector((state: IApplicationState) => state.cart.length)

  const handleCartClick = (): ((e: MouseEvent | KeyboardEvent) => void) => dispatch(showModal(ModalId.CART))

  return (
    <>
      <ActionButton
        title={t('cart:cart')}
        icon='IconCart'
        onClick={handleCartClick}
        counter={cartCounter}
        counterTheme='danger'
        externalClass='mx-4'
      />

      <ModalWrapper id={ModalId.CART} size={ILibModalSizes.LARGE}>
        <CartModal />
      </ModalWrapper>
    </>
  )
}

export default CartBtn
