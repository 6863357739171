import { Action, ActionCreator } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AxiosResponse } from 'axios'

import { IApplicationState } from '@store/types/commonTypes'

export interface IServiceCalcAlgorithm {
  code: '' | ServiceCalcAlgorithmCode
  name: string
  category: string
}

export enum ServiceCalcAlgorithmCode {
  MANUAL = 'manual',
  ORDER_FOLDERS = 'order_folders',
  ORDER_BOXES = 'order_boxes',
  ORDER_DOCUMENTS = 'order_documents',
  ORDER_FACT = 'order_fact',
  ORDER_FACT_BY_80_BOXES = 'order_fact_by_80_boxes',
  ORDER_BOXES_ABOVE_1 = 'order_boxes_above_1',
  ORDER_BOXES_ABOVE_5 = 'order_boxes_above_5',
  ORDER_BOXES_ABOVE_10 = 'order_boxes_above_10',
  ORDER_BOXES_ABOVE_15 = 'order_boxes_above_15',
  ORDER_BOXES_ABOVE_20 = 'order_boxes_above_20',
  STORAGE_FILL = 'storage_full',
  STORAGE_FRACTION = 'storage_fraction',
  FIX_MONTHLY = 'fix_monthly'
}

export interface IMetadataState {
  serviceCalcAlgorithms: IServiceCalcAlgorithm[]
}

export enum MetadataActionTypes {
  SET_SERVICE_CALC_ALGORITHMS = 'SET_SERVICE_CALC_ALGORITHMS'
}

export interface IActionSetServiceCalcAlgorithms extends Action<string> {
  type: MetadataActionTypes.SET_SERVICE_CALC_ALGORITHMS
  payload: IServiceCalcAlgorithm[]
}

export type Actions = IActionSetServiceCalcAlgorithms

type ActionKeys = 'setServiceCalcAlgorithms'

export type MetadataActions = { [key in ActionKeys]: ActionCreator<Action<string>> }

export type MetadataThunkAction = ThunkAction<
  Promise<AxiosResponse> | Promise<void>,
  IApplicationState,
  void,
  Action<string>
>

export type MetadataThunkDispatch = ThunkDispatch<IMetadataState, void, Action<string>>
