import { IMetadataState, MetadataActionTypes, Actions } from '@store/modules/metadata/types'

export const initialState: IMetadataState = {
  serviceCalcAlgorithms: []
}

export default function metadataReducer (state: IMetadataState = initialState, action: Actions): IMetadataState {
  switch (action.type) {
    case MetadataActionTypes.SET_SERVICE_CALC_ALGORITHMS:
      return {
        ...state,
        serviceCalcAlgorithms: action.payload
      }
    default:
      return state
  }
}
