import cn from 'classnames'
import React, { Component, ReactNode } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Logo from '@common/layout/components/Header/components/Logo'
import OrganizationLink from '@common/navigation/components/CustomLinks/OrganizationLink'
import { RouteName } from '@const/consts'
import { PageText404 } from '@const/translations'
import { resetError404 } from '@store/modules/utils/actions'
import { getRouteFromMenuItems } from '@utils/utils'

import { AllPage404Props, IPage404PropsFromDispatch, IPage404PropsFromState } from './types'
import { IApplicationState } from '@store/types/commonTypes'

const style = require('./Page404.module.css')

const pageParts = ['one', 'two', 'three']

class Page404 extends Component<AllPage404Props> {
  public render = (): ReactNode => {
    const { t } = this.props

    return (
      <div id='content'>
        <div className='mt-5 col-xs-12 col-sm-12 col-md-12 col-lg-12'>
          <div className={cn('d-flex mx-auto mt-8 py-2 flex-column full-width', style.container)}>
            <div>
              <div className='mb-2'>
                <Logo />
              </div>
              <h3 className='ml-2 font'>
                <strong>{t('404:title')}</strong>
              </h3>

              <p className={cn(style.font_p, 'ml-2 mb-4 fw-300')}>{t('404:title_reasons')}</p>
              <ul className={cn('py-1 mb-4', style.list)}>{this.renderBody()}</ul>
              <p className={cn(style.font_p, 'ml-2 mb-5 fw-300')}>{t('404:footer')}</p>
              {this.renderLink()}
            </div>
          </div>
        </div>
      </div>
    )
  }

  private readonly renderBody = (): ReactNode => {
    const { t } = this.props
    return pageParts.map((item: string) => {
      return (
        <li key={item} className='ml-1 font-md'>
          {t(PageText404[item])}
        </li>
      )
    })
  }

  private readonly renderLink = (): ReactNode => {
    const { t, authorized, userRole } = this.props
    const path = authorized && userRole ? getRouteFromMenuItems(userRole) : RouteName.DEFAULT

    return (
      <OrganizationLink
        to={path}
        className={cn('ml-2 fl-button fl-button-primary justify-content-center', style.button)}
        onClick={this.props.resetError404}
      >
        {t('404:main')}
      </OrganizationLink>
    )
  }
}

const mapStateToProps = (state: IApplicationState): IPage404PropsFromState => ({
  authorized: state.user.isAuthenticated,
  userRole: state.organizations.activeOrganization.user_role
})

const mapDispatchToProps = (dispatch: any): IPage404PropsFromDispatch => ({
  resetError404: () => dispatch(resetError404())
})

const Page404Class = connect(mapStateToProps, mapDispatchToProps)(Page404)

export default withRouter(withTranslation('404')(Page404Class))
