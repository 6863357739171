import { setPagination } from '@store/modules/pagination/actions'
import { AxiosError, AxiosPromise, AxiosResponse } from 'axios'
import { Action, ActionCreator } from 'redux'

import { ILibPagination } from '@infologistics/frontend-libraries'

import { API_URL, SuccessCode } from '@const/consts'
import objectsService from '@services/objects'
import store from '@store/configureStore'
import { getOrganizationOguid } from '@utils/utils'
import { getPagination } from '@utils/pagination'

import urls from '@const/urls'
import { initialState } from '@store/modules/objects/const'
import { IParams, ISearchParams, ISuccessfulRequest } from '@store/types/commonTypes'
import { addCartItem } from '../cart/actions'
import {
  INewObject,
  IObject,
  IObjectHistoryElement,
  IObjectsWithStatuses, IQuantity,
  IStatuses,
  IUpdatedObjects,
  IUpdateObject,
  ObjectsActions,
  ObjectsActionTypes,
  ObjectsThunkAction,
  ObjectsThunkDispatch
} from './types'
import * as IObjectsActions from './typesActions'

const getURL = (): string => `${API_URL}orgs/${getOrganizationOguid()}`

export const resetObjectsAction: ActionCreator<Action> = (): IObjectsActions.IActionResetObjects => ({
  type: ObjectsActionTypes.RESET_OBJECTS
})

const setObjectHistoryAction: ActionCreator<Action> = (
  data: IObjectHistoryElement[],
  params: ILibPagination
): IObjectsActions.IActionSetObjectHistory => ({
  pagination: params,
  payload: data,
  type: ObjectsActionTypes.SET_OBJECT_HISTORY
})

const setObjectsAction: ActionCreator<Action> = (data: IObject[]): IObjectsActions.IActionSetObjects => ({
  payload: data,
  type: ObjectsActionTypes.SET_OBJECTS
})

const resetUpdatedObjectsAction: ActionCreator<Action> = (): IObjectsActions.IActionResetUpdatedObjects => ({
  type: ObjectsActionTypes.RESET_UPDATED_OBJECTS
})

const sendFileAction: ActionCreator<Action> = (data: IObject[]): IObjectsActions.IActionSendFile => ({
  payload: data,
  type: ObjectsActionTypes.SEND_FILE
})

const updateObjectsAction: ActionCreator<Action> = (data: IUpdatedObjects): IObjectsActions.IActionUpdateObjects => ({
  payload: data,
  type: ObjectsActionTypes.UPDATE_OBJECTS
})

const setQuantityAction: ActionCreator<Action> = (data: IQuantity): IObjectsActions.IActionSetQuantity => ({
  payload: data,
  type: ObjectsActionTypes.SET_QUANTITY
})

const setStatusesAction: ActionCreator<Action> = (data: IStatuses): IObjectsActions.IActionSetStatuses => ({
  payload: data,
  type: ObjectsActionTypes.SET_STATUSES
})

const setObjectAction: ActionCreator<Action> = (object: IObject): IObjectsActions.IActionSetObject => ({
  payload: object,
  type: ObjectsActionTypes.SET_OBJECT
})

export const actions: ObjectsActions = {
  resetObjects: resetObjectsAction,
  resetUpdatedObjects: resetUpdatedObjectsAction,
  sendFile: sendFileAction,
  setObject: setObjectAction,
  setObjectHistory: setObjectHistoryAction,
  setObjects: setObjectsAction,
  setQuantity: setQuantityAction,
  setStatuses: setStatusesAction,
  updateObjects: updateObjectsAction
}

// thunks

export const getObject: ActionCreator<ObjectsThunkAction> = (objectOguid: string) =>
  (dispatch: ObjectsThunkDispatch): Promise<AxiosResponse> =>
    objectsService.getObject(objectOguid)
      .then((resp: AxiosResponse) => {
        if (resp.status === SuccessCode.GET) {
          dispatch(actions.setObject(resp.data))
        }

        return resp
    })

export const getObjects: ActionCreator<ObjectsThunkAction> = (params: IParams | ISearchParams) =>
  (dispatch: ObjectsThunkDispatch): Promise<AxiosResponse<IObject[]>> =>
    objectsService.getObjects(params)
      .then((resp: AxiosResponse) => {
        if (resp.status === SuccessCode.GET) {
          dispatch(actions.setObjects(resp.data))
          dispatch(setPagination(getPagination(resp.headers)))
        }

        return resp
      })
      .catch((error: AxiosError) => Promise.reject(error))

const addObject = (
  url: string,
  data: INewObject[]
): Promise<AxiosResponse<ISuccessfulRequest>> =>
  objectsService.addObject(url, data).catch((error: AxiosError) => Promise.reject(error))

export const addObjects: ActionCreator<ObjectsThunkAction> = (data: INewObject[]) =>
  (): Promise<AxiosResponse<ISuccessfulRequest>> => {
    const url = urls.objects.list

    return addObject(url, data)
  }

export const addAttachedOrderObjects: ActionCreator<ObjectsThunkAction> = (data: INewObject[], order: string) =>
  (): Promise<AxiosResponse<ISuccessfulRequest>> => {
    const url = urls.orders.objects.replace('{{ oguid }}', order)

    return addObject(url, data)
  }

const deleteObject = (dispatch: ObjectsThunkDispatch, url: string, data?: string[]): Promise<AxiosResponse> =>
  objectsService.deleteObject(url, data).catch((error: AxiosError) => Promise.reject(error))

export const deleteObjects: ActionCreator<ObjectsThunkAction> = (objectsOguid: string[]) =>
  (dispatch: ObjectsThunkDispatch): Promise<AxiosResponse<ISuccessfulRequest>> => {
    const url = urls.objects.list
    const data = objectsOguid.slice()

    return deleteObject(dispatch, url, data)
  }

export const detachOrderObjects: ActionCreator<ObjectsThunkAction> = (data: string[], order: string) =>
  (dispatch: ObjectsThunkDispatch): Promise<AxiosResponse> => {
    const url = urls.orders.objects.replace('{{ oguid }}', order)

    return deleteObject(dispatch, url, data)
  }

export const sendFile: ActionCreator<ObjectsThunkAction> = (data: any, objectOguid: string) =>
  (dispatch: ObjectsThunkDispatch): Promise<AxiosResponse> =>
    objectsService.sendFile(data, objectOguid)
      .then((resp: AxiosResponse) => {
        if (SuccessCode.POST.includes(resp.status)) {
          const objects = (): IObject[] =>
            store.getState().objects.data.map((object: IObject) => {
              let updatedObject = null

              if (object.oguid === objectOguid) {
                updatedObject = { ...object }
                updatedObject.filename = data.get('file').name
              }

              return updatedObject ?? object
            })

          dispatch(actions.sendFile(objects()))
        }

        return resp
      })
      .catch((error: AxiosError) => Promise.reject(error))

export const getObjectHistory: ActionCreator<ObjectsThunkAction> = (objectOguid: string, params: IParams) =>
  (dispatch: ObjectsThunkDispatch): Promise<AxiosResponse> =>
    objectsService.getObjectHistory(objectOguid, params)
      .then((resp: AxiosResponse) => {
        if (resp.status === SuccessCode.GET) {
          const pagination = getPagination(resp.headers)
          dispatch(actions.setObjectHistory(resp.data, pagination))
        }

        return resp
      })
      .catch((error: AxiosError) => Promise.reject(error))

export const updateObjects: ActionCreator<ObjectsThunkAction> = (data: any) =>
  (dispatch: ObjectsThunkDispatch): Promise<AxiosResponse> =>
    objectsService.updateObjects(data)
      .then((resp: AxiosResponse<IUpdatedObjects>) => {
        if (SuccessCode.PUT.includes(resp.status)) {
          dispatch(actions.updateObjects(resp.data))
        }

        return resp
      })
      .catch((error: AxiosError) => Promise.reject(error))

export const resetUpdateObjects = (dispatch: ObjectsThunkDispatch): void => {
  dispatch(actions.resetUpdatedObjects())
}

export const getCartObjects = (params: IParams) =>
  (dispatch: ActionCreator<ObjectsThunkAction>): Promise<AxiosResponse> =>
    objectsService.getObjects(params)
      .then((resp: AxiosResponse) => {
        if (resp.status === SuccessCode.GET) {
          dispatch(addCartItem(resp.data))
        }

        return resp
      })

export const getRequestObjects = (
  requestOguid: string,
  params: IParams
): Promise<AxiosResponse<IObjectsWithStatuses>> =>
  objectsService.getRequestObjects(params, requestOguid)
    .then((resp: AxiosResponse<IObjectsWithStatuses>) => resp)

export const getObjectsAttachedToRequest: ActionCreator<ObjectsThunkAction> = (
  requestOguid: string,
  params: IParams,
  withoutStatuses?: boolean,
  withoutPagination?: boolean
) => (dispatch: ObjectsThunkDispatch): Promise<AxiosResponse> => {
  const url = urls.requests.objects.replace('{{ oguid }}', requestOguid)
  const objectsParams = { withoutStatuses, withoutPagination, withQuantity: false }

  return setObjects(dispatch, url, params, objectsParams)
}

export const getObjectsAttachedToOrder: ActionCreator<ObjectsThunkAction> = (
  orderOguid: string,
  params: IParams,
  withoutStatuses?: boolean,
  withoutPagination?: boolean,
) => (dispatch: ObjectsThunkDispatch): Promise<AxiosResponse> => {
  const url = urls.orders.objects.replace('{{ oguid }}', orderOguid)
  const objectsParams = { withoutStatuses, withoutPagination, withQuantity: true }

  return setObjects(dispatch, url, params, objectsParams)
}

export const getAllObjects = (
  isRequest: boolean,
  bindingOguid: string,
  status?: string
): AxiosPromise<IObjectsWithStatuses> => {
  const url = `${getURL()}/${isRequest ? 'requests' : 'orders'}/${bindingOguid}/objects`
  const params = status ? { page: -1, 'status.eq' : status } : { page: -1 }

  return objectsService.getObjectsWithStatuses(url, params)
}

interface IObjectsParams {
  withoutStatuses?: boolean,
  withoutPagination?: boolean,
  withQuantity?: boolean
}

const setObjects = (
  dispatch: ObjectsThunkDispatch,
  url: string,
  params: IParams,
  objectsParams: IObjectsParams
): Promise<AxiosResponse> => {
  const { withoutStatuses, withoutPagination, withQuantity } = objectsParams

  return objectsService.getObjectsWithStatuses(url, params)
    .then((resp: AxiosResponse<IObjectsWithStatuses>) => {
      if (resp.status === SuccessCode.GET) {
        dispatch(actions.setObjects(resp.data.objects ?? []))
        const page = getPagination(resp.headers).pageIndex

        if (resp.data.objects.length === 0 && page > 1) {
          const pageNum = page - 1
          const requestParams: IParams = { ...params, page: `${pageNum}`}

          objectsService.getObjectsWithStatuses(url, requestParams)
            .then((resp: AxiosResponse<IObjectsWithStatuses>) => {
              if (resp.status === SuccessCode.GET) {
                dispatch(actions.setObjects(resp.data.objects ?? []))
                !withoutPagination && dispatch(setPagination({
                    ...getPagination(resp.headers),
                    pageIndex: pageNum,
                    prevPage: pageNum === 1 ? null : pageNum - 1
                  }))
                withQuantity && dispatch(actions.setQuantity(resp.data.quantity ?? { ...initialState.quantity }))
                !withoutStatuses && dispatch(actions.setStatuses(resp.data.statuses ?? { ...initialState.statuses }))
              }
          })
        } else {
          !withoutPagination && dispatch(setPagination(getPagination(resp.headers)))
          withQuantity && dispatch(actions.setQuantity(resp.data.quantity ?? { ...initialState.quantity }))
          !withoutStatuses && dispatch(actions.setStatuses(resp.data.statuses ?? { ...initialState.statuses }))
        }
      }
      return resp
    }).catch((error: AxiosError) => Promise.reject(error))
}

// этот экшен не работает со стором, но используется при работе хука useLoading
export const updateObject: ActionCreator<ObjectsThunkAction> = (objectOguid: string, data: IUpdateObject) =>
  (): Promise<AxiosResponse<ISuccessfulRequest>> =>
    objectsService.updateObject(objectOguid, data).catch((error: AxiosError) => Promise.reject(error))
