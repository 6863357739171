import { Action } from 'redux'

import { IObject } from '@store/modules/objects/types'

export enum CartActionTypes {
  ADD_CART_ITEM = 'ADD_CART_ITEM',
  REMOVE_CART_ITEM = 'REMOVE_CART_ITEM',
  UPDATE_CART_ITEM = 'UPDATE_CART_ITEM',
  CLEAN_CART = 'CLEAN_CART'
}

export interface IActionAddCartItem extends Action<string> {
  payload: IObject[]
  type: CartActionTypes.ADD_CART_ITEM
}

export interface IActionRemoveCartItem extends Action<string> {
  payload: string
  type: CartActionTypes.REMOVE_CART_ITEM
}

export interface IActionUpdateCartItem extends Action<string> {
  payload: any
  type: CartActionTypes.UPDATE_CART_ITEM
}

export interface IActionCleanCart extends Action<string> {
  type: CartActionTypes.CLEAN_CART
}

export type Actions = IActionAddCartItem | IActionRemoveCartItem | IActionUpdateCartItem | IActionCleanCart
