import { AxiosRequestConfig, AxiosResponse } from 'axios'

import AbstractHttpService from '../abstractHttpService'

import urls from '@const/urls'
import { IParams, ISuccessfulRequest } from '@store/types/commonTypes'
import { IGroup, IGroupMember, INewGroup } from '@store/modules/groups/types'
import { ETag } from '@common/cache/types'
import { ParsedQuery } from 'query-string'

class GroupsService extends AbstractHttpService {
  public async getGroups (params?: IParams | ParsedQuery, headers?: ETag): Promise<AxiosResponse<IGroup[]>> {
    const url = urls.groups.list.details

    return await this._http.get(url, { params, headers })
  }

  public async addGroup (data: INewGroup): Promise<AxiosResponse<ISuccessfulRequest>> {
    const url = urls.groups.list.details

    return await this._http.post(url, data)
  }

  public async getGroup (groupOguid: string): Promise<AxiosResponse<IGroup>> {
    const url = urls.groups.single.group.replace('{{ group_oguid }}', groupOguid)

    return await this._http.get(url)
  }

  public async editGroup (groupOguid: string, data: INewGroup): Promise<AxiosResponse<ISuccessfulRequest>> {
    const url = urls.groups.single.group.replace('{{ group_oguid }}', groupOguid)

    return await this._http.put(url, data)
  }

  public async deleteGroup (groupOguid: string): Promise<AxiosResponse<ISuccessfulRequest>> {
    const url = urls.groups.single.group.replace('{{ group_oguid }}', groupOguid)

    return await this._http.delete(url)
  }

  public async getGroupMembers (
    groupOguid: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<IGroupMember[]>> {
    const url = urls.groups.list.members.replace('{{ group_oguid }}', groupOguid)

    return await this._http.get(url, config)
  }

  public async addGroupMembers (groupOguid: string, data: string[]): Promise<AxiosResponse<ISuccessfulRequest>> {
    const url = urls.groups.list.members.replace('{{ group_oguid }}', groupOguid)

    return await this._http.post(url, data)
  }

  public async deleteGroupMember (groupOguid: string, userOguid: string): Promise<AxiosResponse<ISuccessfulRequest>> {
    const url = urls.groups.single.member_delete
      .replace('{{ group_oguid }}', groupOguid)
      .replace('{{ user_oguid }}', userOguid)

    return await this._http.delete(url)
  }
}

export default new GroupsService()
