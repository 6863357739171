import { Action } from 'redux'

export enum StartupActionTypes {
  SET_LOADED = 'SET_LOADED'
}

export interface IActionSetLoaded extends Action<string> {
  payload: boolean
  type: StartupActionTypes.SET_LOADED
}

export interface IStartUpState {
  isLoaded: boolean
}
