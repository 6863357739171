import { AxiosRequestConfig, AxiosResponse } from 'axios'

import AbstractHttpService from '../abstractHttpService'

import urls from '@const/urls'
import { ITerminal } from '@store/modules/users/types'
import { IParams } from '@store/types/commonTypes'
import { ETag } from '@common/cache/types'
import { ILocation, ITerminalData } from '@store/modules/terminals/types'

class TerminalsService extends AbstractHttpService {
  private readonly url = urls.terminals

  public async getTerminals (params: IParams, headers?: ETag): Promise<AxiosResponse<ITerminal[]>> {
    const url = this.url.list
    const config: AxiosRequestConfig = { params, headers }

    return await this._http.get(url, config)
  }

  public async getTerminal (terminalOguid: string): Promise<AxiosResponse<ITerminalData>> {
    const url = this.url.single.replace('{{ terminal_oguid }}', terminalOguid)

    return await this._http.get(url)
  }

  public async addTerminal (data: string): Promise<AxiosResponse<string>> {
    const url = this.url.list

    return await this._http.post(url, data)
  }

  public async deleteTerminal (terminalOguid: string): Promise<AxiosResponse<string>> {
    const url = this.url.single.replace('{{ terminal_oguid }}', terminalOguid)

    return await this._http.delete(url)
  }

  public async editTerminal (terminalOguid: string, name: string): Promise<AxiosResponse<string>> {
    const url = this.url.single.replace('{{ terminal_oguid }}', terminalOguid)
    const data = { name }

    return await this._http.put(url, data)
  }

  public async getLocations (terminalOguid: string, params: IParams): Promise<AxiosResponse<ILocation[]>> {
    const url = this.url.locations.replace('{{ terminal_oguid }}', terminalOguid)
    const config: AxiosRequestConfig = { params }

    return await this._http.get(url, config)
  }

  public async getLocationsFile (terminalOguid: string, params: IParams): Promise<AxiosResponse<Blob>> {
    const url = this.url.file.replace('{{ terminal_oguid }}', terminalOguid)
    const config: AxiosRequestConfig = {
      responseType: 'blob',
      params
    }

    return await this._http.get(url, config)
  }

  public async addLocationsFileToTerminal (
    terminalOguid: string,
    data: any,
    params: IParams
  ): Promise<AxiosResponse<string[]>> {
    const url = this.url.file.replace('{{ terminal_oguid }}', terminalOguid)
    const config: AxiosRequestConfig = { params }

    return await this._http.post(url, data, config)
  }
}

export default new TerminalsService()
