import { lazy } from 'react'

import { IRoute } from '@common/Routing/types'
import { AllRoles, RouteName } from '@const/consts'
import getLazyComponent from '@common/lazyLoading/lazyLoadingFunctions'

const Profile = lazy(() => import('./components/Profile'))

export const routes: IRoute[] = [
  {
    component: getLazyComponent(Profile),
    exact: true,
    name: 'Profile',
    path: RouteName.PROFILE,
    roles: AllRoles
  }
]
