import React, { FC, ReactElement } from 'react'
import {
  DashedLine,
  IconSeal,
  LibTooltipAlignment,
  LibTooltipPosition,
  Tooltip
} from '@infologistics/frontend-libraries'
import { getObjectColor } from '@utils/utils'
import { useTranslation } from 'react-i18next'

import { ISealTooltipProps as IProps } from './types'

const SealTooltip: FC<IProps> = (props: IProps): ReactElement => {
  const { seal1, seal2 } = props
  const { t } = useTranslation(['common', 'archive'])
  const title = seal1 && seal2 ? t('common:popover.sealed') : t('common:popover.unsealed')

  return (
    <div className='font-xs mr-2'>
      <IconSeal color={getObjectColor(seal1, seal2)} />
      <Tooltip position={LibTooltipPosition.BOTTOM} alignment={LibTooltipAlignment.START} withoutArrow>
        <div className='mb-1'>{title}</div>
        <div>
          <DashedLine />
          <p className='mt-1 text-nowrap'>{`${t('archive:modals.seal_1')}: ${seal1 ?? ''}`}</p>
          <p className='text-nowrap'>{`${t('archive:modals.seal_2')}: ${seal2 ?? ''}`}</p>
        </div>
      </Tooltip>
    </div>
  )
}

export default SealTooltip
