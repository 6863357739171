import { cloneDeep } from 'lodash'
import React, { ReactElement } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { ITEMS_PER_PAGE_FOR_CART } from '@const/consts'
import { addCartItem, cleanCart, removeCartItem, updateCartItem } from '@store/modules/cart/actions'
import { hideModal } from '@store/modules/modal/actions'
import { IObject } from '@store/modules/objects/types'
import { IApplicationState } from '@store/types/commonTypes'
import { ILocalStorageCart } from '@views/archive/components/Archive/types'
import Table from '@views/modals/Cart/CartTable'
import { ICartModalPropsFromState as IPropsFromState, ICartModalPropsFromDispatch as IPropsFromDispatch } from './types'
import { Button } from '@infologistics/frontend-libraries/dist'
import { IConfirmNotificationOptions } from '@app/utils/types'
import { showConfirmNotification } from '@app/utils/utils'

const DEFAULT_PAGE = 1

interface IProps extends WithTranslation {
  onExport?: () => void
}

interface IState {
  page: number | string
}

type AllProps = IProps & IPropsFromState & IPropsFromDispatch

class CartModal extends React.Component<AllProps, IState> {
  constructor (props: AllProps) {
    super(props)

    this.state = {
      page: DEFAULT_PAGE
    }
  }

  public componentDidMount (): void {
    const { cart, objects, onUpdateCartItem } = this.props
    if (cart) {
      for (const cartObject of cart) {
        const { oguid } = cartObject
        const object = objects.data.find((item: IObject) => item.oguid === oguid)
        if (object && cartObject !== object) {
          onUpdateCartItem(oguid, object)
        }
      }
    }
  }

  public render (): ReactElement {
    const { cart, hideModal, t } = this.props

    // TODO: сделать экспорт из корзины
    return (
      <>
        <div className='modal-header'>
          <h4 className='m-0'>{t('cart:cart')}</h4>
        </div>

        <div className='modal-body'>{this.renderCartBody()}</div>

        <div className='modal-footer'>
          { /*
          <Button type='button' onClick={onExport} externalClass='mr-2' isDisabled={true}>
            {t('common:buttons.export')}
          </Button>
          */ }
          <Button type='button' theme='danger' onClick={this.handleRemoveAll} externalClass='mr-2' isDisabled={cart.length === 0}>
            {t('common:buttons.clear')}
          </Button>
          <Button type='button' onClick={hideModal}>
            {t('common:buttons.close')}
          </Button>
        </div>
      </>
    )
  }

  private readonly renderCartBody = (): ReactElement => {
    const { t, cart } = this.props
    const { page } = this.state

    return cart.length > 0 ? (
      <Table items={cart} onRemove={this.handleRemove} page={page} onSwitchPagination={this.onSwitchPagination} />
    ) : (
      <h5 className='m-0'>{t('cart:empty')}</h5>
    )
  }

  private readonly handleRemove = (barcode: string) => () => {
    const { onRemoveCartItem, t} = this.props

    const content = t('cart:notification.delete', {
      barcode
    })

    const notify: IConfirmNotificationOptions = {
      content,
      theme: 'danger',
      onSubmit: () => {
        onRemoveCartItem(barcode)

        this.clearCartInLocalStorage(barcode)
      },
    }

    showConfirmNotification(notify)
  }

  private readonly handleRemoveAll = (): void => {
    const { t, onCleanCart } = this.props

    const content = t('cart:notification.delete_all')

    const notify: IConfirmNotificationOptions = {
      content,
      theme: 'danger',
      onSubmit: () => {
        onCleanCart()

        this.clearCartInLocalStorage()
      },
    }

    showConfirmNotification(notify)
  }

  private readonly clearCartInLocalStorage = (barcode?: string): void => {
    const { userOguid, organizationOguid } = this.props

    const cartItems = localStorage.getItem(`cart_${userOguid}`)

    if (cartItems) {
      const parsedCartItems = JSON.parse(cartItems)
      const newCartItems = cloneDeep(parsedCartItems)

      const isOrganizationCartWrittenInLocalStorage = parsedCartItems[organizationOguid]

      if (isOrganizationCartWrittenInLocalStorage) {
        if (barcode) {
          const newBarcodes = newCartItems[organizationOguid].filter((item: string) => item !== barcode)

          newCartItems[organizationOguid] = [...newBarcodes]

          return this.setLocalStorage(newCartItems)
        }

        delete newCartItems[organizationOguid]

        return this.setLocalStorage(newCartItems)
      }
    }
  }

  private readonly setLocalStorage = (barcodes: ILocalStorageCart): void =>
    localStorage.setItem(`cart_${this.props.userOguid}`, JSON.stringify(barcodes))

  private readonly onSwitchPagination = (page: number | string): void => {
    if (page !== 'last') {
      return this.setState({
        page
      })
    }

    const pageIndex = Math.ceil(this.props.cart.length / ITEMS_PER_PAGE_FOR_CART)

    return this.setState({
      page: pageIndex
    })
  }
}

const mapStateToProps = (state: IApplicationState): IPropsFromState => ({
  cart: state.cart,
  objects: state.objects,
  organizationOguid: state.organizations.activeOrganization.oguid,
  userOguid: state.user.oguid
})

const mapDispatchToProps = (dispatch: any): IPropsFromDispatch => ({
  addCartItem: (data: IObject[]) => dispatch(addCartItem(data)),
  hideModal: () => dispatch(hideModal()),
  onCleanCart: () => dispatch(cleanCart()),
  onRemoveCartItem: (id: string) => dispatch(removeCartItem(id)),
  onUpdateCartItem: (oguid: string, object: IObject) => dispatch(updateCartItem(oguid, object))
})

const CartModalClass = connect(mapStateToProps, mapDispatchToProps)(CartModal)

export default withTranslation(['cart', 'common'])(CartModalClass)
