import { Dispatch } from 'redux'

import * as jwt from '@utils/jwt'

import { USER_LOGOUT } from './types'

export const logoutAction = (): { type: string } => ({ type: USER_LOGOUT })

export const logout = () => {
  return (dispatch: Dispatch) => {
    dispatch(logoutAction())
    jwt.clear()
  }
}
