import { GroupsActionTypes, IGroupsState, Actions, IGroup } from '@store/modules/groups/types'

export const initialActiveGroup: IGroup = {
  oguid: '',
  name: '',
  users_count: 0,
  terminals: null
}

export const initialState: IGroupsState = {
  data: [],
  activeGroup: { ...initialActiveGroup },
  members: []
}

export default function groupsReducer (state: IGroupsState = initialState, action: Actions): IGroupsState {
  switch (action.type) {
    case GroupsActionTypes.SET_GROUPS: {
      return {
        ...state,
        data: [...action.payload]
      }
    }
    case GroupsActionTypes.SET_GROUP: {
      return {
        ...state,
        activeGroup: { ...action.payload }
      }
    }
    case GroupsActionTypes.SET_MEMBERS: {
      return {
        ...state,
        members: [...action.payload]
      }
    }
    default: {
      return state
    }
  }
}
