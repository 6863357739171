import { AxiosRequestConfig, AxiosResponse } from 'axios'

import AbstractHttpService from '../abstractHttpService'
import urls from '@const/urls'
import {
  IEditedUser,
  INewUser,
  IRecipient,
  IRecipientsParams,
  IUser,
  IUserByLoginOrEmail,
  IUserData
} from '@store/modules/users/types'
import { Role, UserStatusesEnum } from '@const/consts'
import { IParams, ISuccessfulRequest } from '@store/types/commonTypes'
import { ETag } from '@common/cache/types'
import { IBadges } from '@store/modules/navigation/types'

class UsersService extends AbstractHttpService {
  private readonly url = urls.users

  public async getUserData (params: IParams): Promise<AxiosResponse<IUserByLoginOrEmail>> {
    const url = this.url.list
    const config: AxiosRequestConfig = { params }

    return await this._http.get(url, config, false)
  }

  public async getRecipients (params: IRecipientsParams): Promise<AxiosResponse<IRecipient[]>> {
    const url = this.url.recipients
    const config: AxiosRequestConfig = { params }

    return await this._http.get(url, config)
  }

  public async getAllUsers (params: IParams, headers?: ETag): Promise<AxiosResponse> {
    const url = this.url.list

    return await this._http.get(url, { params, headers })
  }

  public async getUser (userOguid: string): Promise<AxiosResponse<IUser>> {
    const url = this.url.single.replace('{{ user_oguid }}', userOguid)

    return await this._http.get(url)
  }

  public async addUser (data: INewUser): Promise<AxiosResponse<ISuccessfulRequest>> {
    const url = this.url.list

    return await this._http.post(url, data)
  }

  public async updateUser (userOguid: string, data: IEditedUser): Promise<AxiosResponse<ISuccessfulRequest>> {
    const url = this.url.single.replace('{{ user_oguid }}', userOguid)

    return await this._http.put(url, data)
  }

  public async deleteUser (userOguid: string): Promise<AxiosResponse<ISuccessfulRequest>> {
    const url = this.url.single.replace('{{ user_oguid }}', userOguid)

    return await this._http.delete(url)
  }

  public async getUserAccounts (userOguid: string, params: IParams): Promise<AxiosResponse<IUserData<UserStatusesEnum, Role>>> {
    const url = this.url.single.replace('{{ user_oguid }}', userOguid)
    const config: AxiosRequestConfig = { params }

    return await this._http.get(url, config)
  }

  public async addUserAccounts (userOguid: string, data: string[]): Promise<AxiosResponse<ISuccessfulRequest>> {
    const url = this.url.accounts.list.replace('{{ user_oguid }}', userOguid)

    return await this._http.post(url, data)
  }

  public async deleteUserAccount (userOguid: string, accountOguid: string): Promise<AxiosResponse<ISuccessfulRequest>> {
    const url = this.url.accounts.single
      .replace('{{ user_oguid }}', userOguid)
      .replace('{{ account_oguid }}', accountOguid)

    return await this._http.delete(url)
  }

  public async addUserSubAccounts (userOguid: string, data: string[]): Promise<AxiosResponse<ISuccessfulRequest>> {
    const url = this.url.subaccounts.list.replace('{{ user_oguid }}', userOguid)

    return await this._http.post(url, data)
  }

  public async deleteUserSubAccount (userOguid: string, subAccountOguid: string): Promise<AxiosResponse<ISuccessfulRequest>> {
    const url = this.url.subaccounts.single
      .replace('{{ user_oguid }}', userOguid)
      .replace('{{ sub_account_oguid }}', subAccountOguid)

    return await this._http.delete(url)
  }

  public async addUserTerminals (userOguid: string, data: string[]): Promise<AxiosResponse<ISuccessfulRequest>> {
    const url = this.url.terminals.list.replace('{{ user_oguid }}', userOguid)

    return await this._http.post(url, data)
  }

  public async deleteUserTerminal (userOguid: string, terminalOguid: string): Promise<AxiosResponse<ISuccessfulRequest>> {
    const url = this.url.terminals.single
      .replace('{{ user_oguid }}', userOguid)
      .replace('{{ terminal_oguid }}', terminalOguid)

    return await this._http.delete(url)
  }

  public async getBadges (userOguid: string): Promise<AxiosResponse<IBadges>> {
    const url = this.url.statistics.replace('{{ user_oguid }}', userOguid)

    return await this._http.get(url)
  }
}

export default new UsersService()
