import { AxiosRequestConfig, AxiosResponse } from 'axios'

import AbstractHttpService from '../abstractHttpService'
import { INewOuterCompany, IOuterCompany } from '@app/store/modules/outer_company/types'
import { ISearchParams } from '@app/store/types/commonTypes'
import urls from '@const/urls'

class OuterCompanyService extends AbstractHttpService {
  private readonly url = urls.contractors

  public getOuterCompanies = async (): Promise<AxiosResponse<IOuterCompany[]>> => {
    const url = this.url.list

    return await this._http.get(url)
  }

  public getOuterCompany = async (outerCompanyOguid: string): Promise<AxiosResponse<IOuterCompany[]>> => {
    const url = this.url.single.replace('{{ outer_company_oguid }}', outerCompanyOguid)

    return await this._http.get(url)
  }

  public getOuterCompaniesWithPagination = async (params: ISearchParams): Promise<AxiosResponse> => {
    const url = this.url.list
    const config: AxiosRequestConfig = { params }

    return await this._http.get(url, config)
  }

  public addOuterCompany = async (data: INewOuterCompany, config: AxiosRequestConfig): Promise<AxiosResponse> => {
    const url = this.url.list

    return await this._http.post(url, data, config)
  }

  public updateOuterCompany = async (
    data: IOuterCompany,
    config: AxiosRequestConfig
  ): Promise<AxiosResponse> => {
    const { oguid } = data
    const url = this.url.single.replace('{{ outer_company_oguid }}', oguid)

    return await this._http.put(url, data, config)
  }

  public deleteOuterCompany = async (outerCompanyOguid: string): Promise<AxiosResponse> => {
    const url = this.url.single.replace('{{ outer_company_oguid }}', outerCompanyOguid)

    return await this._http.delete(url)
  }
}

export default new OuterCompanyService()
