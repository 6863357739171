import { lazy } from 'react'

import { IRoute } from '@common/Routing/types'
import { RouteName } from '@const/consts'
import getLazyComponent from '@common/lazyLoading/lazyLoadingFunctions'

const Login = lazy(() => import('./components/Login'))
const Forgot = lazy(() => import('./components/Forgot'))

export const routes: IRoute[] = [
  {
    component: getLazyComponent(Login),
    exact: true,
    name: 'Login',
    path: RouteName.SIGN_IN
  },
  {
    component: getLazyComponent(Forgot),
    exact: true,
    name: 'Forgot',
    path: RouteName.FORGOT
  }
]
