import { applyMiddleware, combineReducers, createStore } from 'redux'
import thunk from 'redux-thunk'

import { composeWithDevTools } from 'redux-devtools-extension'

import servicesReducer from '@store/modules/services/reducer'
import { accountsReducer } from './modules/accounts/reducer'
import { barcodesReducer } from './modules/barcodes/reducer'
import { cartReducer } from './modules/cart/reducer'
import { loaderReducer } from './modules/loader/reducer'
import { modalReducer } from './modules/modal/reducer'
import { navigationReducer } from './modules/navigation/reducer'
import { objectsReducer } from './modules/objects/reducer'
import { operationsReducer } from './modules/operations/reducer'
import { ordersReducer } from './modules/orders/reducer'
import { organizationsReducer } from './modules/organizations/reducer'
import paginationReducer from './modules/pagination/reducer'
import { reportsReducer } from './modules/reports/reducer'
import { requestsReducer } from './modules/requests/reducer'
import startupReducer from './modules/startup/reducer'
import { terminalReducer } from './modules/terminals/reducer'
import { userReducer } from './modules/user/reducer'
import { outerCompanyReducer } from './modules/outer_company/reducer'
import { usersReducer } from './modules/users/reducer'
import utilsReduces from './modules/utils/reducer'
import metadataReducer from '@store/modules/metadata/reducer'
import groupsReducer from '@store/modules/groups/reducer'

import { rootReducer } from './modules/root/reducer'

export const appReducer = combineReducers({
  accounts: accountsReducer,
  app: startupReducer,
  barcodes: barcodesReducer,
  cart: cartReducer,
  groups: groupsReducer,
  loader: loaderReducer,
  metadata: metadataReducer,
  modal: modalReducer,
  navigation: navigationReducer,
  objects: objectsReducer,
  operations: operationsReducer,
  orders: ordersReducer,
  organizations: organizationsReducer,
  outer_company: outerCompanyReducer,
  pagination: paginationReducer,
  reports: reportsReducer,
  requests: requestsReducer,
  services: servicesReducer,
  terminals: terminalReducer,
  user: userReducer,
  users: usersReducer,
  utils: utilsReduces
})

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)))

export default store
