import { AxiosError, AxiosResponse } from 'axios'
import { Action, ActionCreator } from 'redux'

import userService from '@services/user'
import { ResponseCode, SuccessCode } from '@const/consts'
import { IOrganization } from '@store/modules/organizations/types'
import { INewUser, IUserSettingsState, IUserWithOrganizations } from '@store/modules/user/types'
import { UserActions, UserActionTypes, UserThunkAction, UserThunkDispatch } from './types'
import * as IUserActions from './typesActions'
import { IActionSetUserSettings } from './typesActions';

// actions

const userAuthenticatedAction: ActionCreator<Action> = (
  isAuthenticated: boolean = true
): IUserActions.IActionUserAuthenticated => ({
  payload: { isAuthenticated },
  type: UserActionTypes.USER_AUTHENTICATED
})

const changeInfoAction: ActionCreator<Action> = (data: INewUser): IUserActions.IActionChangeInfo => ({
  payload: data,
  type: UserActionTypes.CHANGE_INFO
})

const setOrganizationsAction: ActionCreator<Action> = (
  data: IOrganization[]
): IUserActions.IActionSetOrganizations => ({
  payload: data,
  type: UserActionTypes.SET_ORGANIZATIONS
})

const setOrganizationUserAction: ActionCreator<Action> = (
  data: IUserWithOrganizations
): IUserActions.IActionSetOrganizationUser => ({
  payload: data,
  type: UserActionTypes.SET_ORGANIZATION_USER
})

export const setUserSettingsAction: ActionCreator<Action> = (userSettings: IUserSettingsState): IActionSetUserSettings => ({
  payload: userSettings,
  type: UserActionTypes.SET_USER_SETTINGS
})

export const actions: UserActions = {
  changeInfo: changeInfoAction,
  setAuthenticated: userAuthenticatedAction,
  setOrganizationUser: setOrganizationUserAction,
  setOrganizations: setOrganizationsAction,
  setUserSettings: setUserSettingsAction,
}

// thunk

export const getUser: ActionCreator<UserThunkAction> = () =>
  (dispatch: UserThunkDispatch): Promise<AxiosResponse<IUserWithOrganizations>> =>
    userService.getUser()
      .then((resp: AxiosResponse<IUserWithOrganizations>) => {
        if (resp.status === SuccessCode.GET) {
          dispatch(actions.setOrganizations(resp.data.orgs))
          dispatch(actions.setOrganizationUser(resp.data))
        }

        return resp
      })
      .catch((error: any) => Promise.reject(error))

export const updateProfile: ActionCreator<UserThunkAction> = (
  data: INewUser,
  isChangePassword: boolean = false
) => (dispatch: UserThunkDispatch): Promise<AxiosResponse> =>
    userService.updateProfile(data)
      .then((resp: AxiosResponse) => {
        if (SuccessCode.PUT.includes(resp.status) && !isChangePassword) {
          dispatch(actions.changeInfo(data))
        }

        return resp
      })
      .catch((error: any) => Promise.reject(error))

export const getUserSettings: ActionCreator<UserThunkAction> = () => (dispatch: UserThunkDispatch): Promise<AxiosResponse> =>
    userService
      .getUserSettings()
      .then((resp: AxiosResponse) => {
        if (resp.status === ResponseCode.GET) {
          dispatch(actions.setUserSettings(resp.data))
        }

        return resp
      })
      .catch((err: AxiosError) => Promise.reject(err))

export const updateUserSettings: ActionCreator<UserThunkAction> = (userSettings: IUserSettingsState) => (dispatch: UserThunkDispatch): Promise<AxiosResponse> =>
    userService
      .updateUserSettings(userSettings)
      .then((resp: AxiosResponse) => {
        dispatch(actions.setUserSettings(userSettings))

        return resp
      })
      .catch((err: AxiosError) => Promise.reject(err))
