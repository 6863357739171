import React, { FC, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { IModalProps as IProps } from './types'
import { Modal } from '@infologistics/frontend-libraries'
import { hideModal } from '@store/modules/modal/actions'
import { IApplicationState } from '@store/types/commonTypes'

const ModalWrapper: FC<IProps> = (props: IProps): ReactElement => {
  const { children, closeText, onCloseModal } = props
  const dispatch = useDispatch<any>()
  const idFromState: string = useSelector((state: IApplicationState) => state.modal.id)
  const { t } = useTranslation(['common'])

  return (
    <Modal
      {...props}
      idFromState={idFromState}
      hideModal={() => dispatch(hideModal())}
      onCloseModal={onCloseModal}
      closeText={closeText ?? t('common:close')}
    >
      {children}
    </Modal>
  )
}

export default ModalWrapper
