import { Actions, BarcodesActionTypes, IBarcodeRange, IBarcodesState } from './types'

export const barcodeRange: IBarcodeRange = {
  id: 0,
  oguid: '',
  object_type: '',
  length: 0,
  prefix: '',
  involved: 0,
  value_from: 0,
  value_to: 0,
  account: null,
  sub_account: null,
  terminal: null,
  created_date: 0,
  updated_date: 0,
  created_user: {
    oguid: '',
    surname: '',
    name: '',
    patronymic: '',
    position: ''
  },
  updated_user: {
    oguid: '',
    surname: '',
    name: '',
    patronymic: '',
    position: '',
  }
}

const initialState: IBarcodesState = {
  data: [],
  barcodeRange
}

export function barcodesReducer (state: IBarcodesState = initialState, action: Actions): IBarcodesState {
  const { data } = state

  switch (action.type) {
    case BarcodesActionTypes.SET_BARCODES:
      return { ...state, data: [...action.payload] }

    case BarcodesActionTypes.SET_BARCODE_RANGE:
      return { ...state, barcodeRange: action.payload }

    case BarcodesActionTypes.RESET_BARCODE_RANGE:
      return { ...state, barcodeRange }

    case BarcodesActionTypes.UPDATE_BARCODE_RANGE: {
      const barcodes = data.map((item: IBarcodeRange) => {
        if (item.oguid !== action.payload.oguid) {
          return item
        }
        return { ...item, ...action.payload }
      })
      return { ...state, data: [...barcodes] }
    }

    default:
      return state
  }
}
