import React, { FC, ReactElement } from 'react'
import { useLocation } from 'react-router'
import { useTranslation } from 'react-i18next'

import { Pagination } from '@infologistics/frontend-libraries'

import { IPaginationProps as IProps } from './types'
import { AllServiceParams } from '@store/modules/navigation/types'

const PaginationWrapper: FC<IProps> = (props: IProps): ReactElement => {
  const { t } = useTranslation(['pagination'])
  const location = useLocation<AllServiceParams>()

  const texts = {
    by: t('pagination:by'),
    entries: t('pagination:entries'),
    firstPage: t('pagination:first_page'),
    lastPage: t('pagination:last_page'),
    next: t('pagination:next'),
    previous: t('pagination:previous'),
    showMaxLastItem: (options: object) => t('pagination:show_maxLastItem', Object.assign({}, options)),
    showRows: (options: object) => t('pagination:show_rows', Object.assign({}, options)),
    showTotal: (options: object) => t('pagination:show_total', Object.assign({}, options))
  }

  return <Pagination {...props} location={location} texts={texts} />
}

export default PaginationWrapper
