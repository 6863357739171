import React, { ReactElement, ReactNode } from 'react'
import { Route, RouteComponentProps } from 'react-router-dom'

import { IRoute } from '@common/Routing/types'
import { useLocation } from 'react-router'
import { AllRouteParams } from '@store/modules/navigation/types'

export const RouteWithSubRoutes = (route: IRoute): ReactElement => {
  const { path, exact } = route
  const { pathname, search } = useLocation<AllRouteParams>()
  const renderHandler = (renderProps: RouteComponentProps): ReactNode => <route.component {...renderProps} />

  return <Route key={`${pathname}${search}`} path={path} exact={exact} render={renderHandler} />
}
