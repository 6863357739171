import {
  Actions,
  IOrder,
  IOrdersState,
  OrderNumberField,
  OrdersActionTypes
} from '@store/modules/orders/types'
import { initialPlannedOperation, initialType, orderInitialState } from './consts'

const initialState: IOrdersState = {
  activeOperation: null,
  activeOrder: { ...orderInitialState },
  activeType: { ...initialType },
  data: [],
  operations: [],
  operationsDashboard: [],
  types: [],
  plannedOperations: [],
  activePlannedOperation: { ...initialPlannedOperation },
  history: [],
  orderNotifications: [],
}

export function ordersReducer (state: IOrdersState = initialState, action: Actions): IOrdersState {
  const { data } = state

  switch (action.type) {
    case OrdersActionTypes.RESET_ORDERS: {
      return { ...initialState, types: [...state.types] }
    }
    case OrdersActionTypes.SET_ORDERS: {
      return { ...state, data: [...action.payload] }
    }
    case OrdersActionTypes.SET_ORDER_TYPES: {
      return { ...state, types: [...action.payload] }
    }
    case OrdersActionTypes.SET_ORDER: {
      return { ...state, activeOrder: action.payload }
    }
    case OrdersActionTypes.SET_ACTIVE_ORDER: {
      return { ...state, activeOrder: action.payload }
    }
    case OrdersActionTypes.DELETE_ORDER: {
      const filteredOrders = data.filter((order: IOrder<OrderNumberField>) => order.oguid !== action.payload)
      return { ...state, data: filteredOrders }
    }
    case OrdersActionTypes.RESET_ACTIVE_ORDER: {
      return { ...state, activeOrder: { ...orderInitialState } }
    }
    case OrdersActionTypes.UPDATE_ORDER_STATE: {
      return { ...state, activeOrder: { ...state.activeOrder, ...action.payload } }
    }
    case OrdersActionTypes.SET_DASHBOARD: {
      return { ...state, operationsDashboard: [...action.payload] }
    }
    case OrdersActionTypes.SET_OPERATIONS: {
      return { ...state, operations: [...action.payload] }
    }
    case OrdersActionTypes.SET_ACTIVE_OPERATION: {
      return { ...state, activeOperation: { ...action.payload } }
    }
    case OrdersActionTypes.SET_ACTIVE_TYPE: {
      return { ...state, activeType: { ...action.payload } }
    }
    case OrdersActionTypes.RESET_ACTIVE_TYPE: {
      return { ...state, activeType: { ...initialType } }
    }
    case OrdersActionTypes.SET_PLANNED_OPERATIONS: {
      return { ...state, plannedOperations: [...action.payload] }
    }
    case OrdersActionTypes.SET_ACTIVE_PLANNED_OPERATION: {
      return { ...state, activePlannedOperation: { ...action.payload[0] } }
    }
    case OrdersActionTypes.SET_ORDER_HISTORY: {
      return { ...state, history: [...action.payload] }
    }
    case OrdersActionTypes.SET_ORDER_NOTIFICATIONS: {
      return { ...state, orderNotifications: [...action.payload] }
    }
    default:
      return state
  }
}
