import { Instance, Languages, lookupLocalStorage } from '@const/consts'
import { Actions, IUtilsState, UtilsActionTypes } from './types'

export const initialState: IUtilsState = {
  instance: window.localStorage.getItem(lookupLocalStorage.INSTANCE) as Instance ?? Instance.GLOBAL,
  isError404: false,
  isRefreshLocked: false,
  areMenusShown: true,
  isTabsLocked: false,
  isUserExist: false,
  language: window.localStorage.getItem(lookupLocalStorage.LANGUAGE) ?? Languages.EN
}

export default function utilsReducer (state: IUtilsState = initialState, action: Actions): IUtilsState {
  switch (action.type) {
    case UtilsActionTypes.TOGGLE_MENUS:
      return {
        ...state,
        areMenusShown: action.payload
      }

    case UtilsActionTypes.TOGGLE_TABS_LOCK:
      return {
        ...state,
        isTabsLocked: !state.isTabsLocked
      }

    case UtilsActionTypes.TOGGLE_REFRESH_LOCK:
      return {
        ...state,
        isRefreshLocked: !state.isRefreshLocked
      }
    case UtilsActionTypes.SET_ERROR404:
      return {
        ...state,
        isError404: true
      }
    case UtilsActionTypes.RESET_ERROR404:
      return {
        ...state,
        isError404: false
      }
    case UtilsActionTypes.SET_LANGUAGE:
      return {
        ...state,
        language: action.payload
      }
    case UtilsActionTypes.SET_INSTANCE:
      return {
        ...state,
        instance: action.payload
      }
    case UtilsActionTypes.SET_USER_EXIST:
      return {
        ...state,
        isUserExist: true
      }
    case UtilsActionTypes.RESET_USER_EXIST:
      return {
        ...state,
        isUserExist: false
      }

    default:
      return state
  }
}
