import { cloneDeep } from 'lodash'
import { Actions, INavigationState, IUserFilter, NavigationActionTypes } from './types'

export const initialState: INavigationState = {
  badges: {
    order: {
      orders_assigned_to: 0,
      orders_new: 0,
      orders_started: 0
    },
    request: {
      draft: 0,
      in_progress_orders_assigned_to: 0,
      in_progress_orders_completed: 0,
      in_progress_orders_declined: 0,
      in_progress_orders_is_null: 0,
      in_progress_orders_new: 0,
      in_progress_orders_started: 0,
      new: 0
    },
    user_oguid: ''
  },
  items: [],
  menuItems: [],
  ordersActiveFilter: '',
  requestsActiveFilter: '',
  shortcutOpen: false,
  userFilters: []
}

export function navigationReducer (state: INavigationState = initialState, action: Actions): INavigationState {
  switch (action.type) {
    case NavigationActionTypes.CLOSE_SHORTCUT: {
      return {
        ...state,
        shortcutOpen: false
      }
    }

    case NavigationActionTypes.OPEN_SHORTCUT: {
      return {
        ...state,
        shortcutOpen: true
      }
    }

    case NavigationActionTypes.SET_BADGES: {
      return { ...state, badges: { ...action.payload } }
    }

    case NavigationActionTypes.SET_MENU_ITEMS: {
      return {
        ...state,
        menuItems: [...action.payload]
      }
    }

    case NavigationActionTypes.SET_FILTERS: {
      return {
        ...state,
        userFilters: [...action.payload]
      }
    }

    case NavigationActionTypes.SET_NEW_FILTER: {
      return {
        ...state,
        userFilters: [...state.userFilters, action.payload]
      }
    }

    case NavigationActionTypes.SET_EDITED_FILTER: {
      const newUserFilters = cloneDeep(state.userFilters)
      const editedFilterIndex = newUserFilters.findIndex((filter: IUserFilter) => filter.oguid === action.payload.oguid)

      if (editedFilterIndex >= 0) {
        newUserFilters[editedFilterIndex] = action.payload
      }

      return {
        ...state,
        userFilters: newUserFilters
      }
    }

    case NavigationActionTypes.DELETE_FILTER: {
      const userFilters = state.userFilters.filter((filter: IUserFilter) => filter.oguid !== action.payload)

      return {
        ...state,
        userFilters
      }
    }

    case NavigationActionTypes.SET_REQUESTS_ACTIVE_FILTER: {
      return {
        ...state,
        requestsActiveFilter: action.payload
      }
    }

    case NavigationActionTypes.SET_ORDERS_ACTIVE_FILTER: {
      return {
        ...state,
        ordersActiveFilter: action.payload
      }
    }

    default:
      return state
  }
}
