export enum Day {
  TODAY = 'today',
  TOMORROW = 'tomorrow',
  YESTERDAY = 'yesterday'
}

export const DayValues = [Day.YESTERDAY, Day.TODAY, Day.TOMORROW]
export const DayShortValues = [Day.YESTERDAY, Day.TODAY]

export enum Week {
  NEXTWEEK = 'nextweek',
  PREVWEEK = 'prevweek',
  THISWEEK = 'thisweek'
}

export const WeekValues = [Week.PREVWEEK, Week.THISWEEK, Week.NEXTWEEK]
export const WeekShortValues = [Week.THISWEEK, Week.PREVWEEK]

export enum Month {
  NEXTMONTH = 'nextmonth',
  PREVMONTH = 'prevmonth',
  THISMONTH = 'thismonth'
}

export const MonthValues = [Month.PREVMONTH, Month.THISMONTH, Month.NEXTMONTH]
export const MonthShortValues = [Month.THISMONTH, Month.PREVMONTH]
