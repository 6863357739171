import { Action, ActionCreator } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'

import { FilterName, Role, UserStatusesEnum } from '@const/consts'
import { EmptyString, IApplicationState, ICreator, ILink, Nullable } from '@store/types/commonTypes'
import * as IUsersActions from './typesActions'

export enum UsersActionTypes {
  GET_ALL_USERS = 'GET_ALL_USERS',
  GET_USER = 'GET_USER',
  GET_USER_ACCOUNTS = 'GET_USER_ACCOUNTS',
  GET_USERS = 'GET_USERS',
  SET_RECIPIENTS = 'SET_RECIPIENTS',
  UPDATE_USER = 'UPDATE_USER'
}

export type Actions =
  | IUsersActions.IActionGetAllUsers
  | IUsersActions.IActionGetUser
  | IUsersActions.IActionGetUserAccounts
  | IUsersActions.IActionGetUsers
  | IUsersActions.IActionSetRecipients
  | IUsersActions.IActionUpdateUser

export enum ActionKey {
  GET_ALL_USERS = 'getAllUsers',
  GET_USER = 'getUser',
  GET_USER_ACCOUNTS = 'getUserAccounts',
  GET_USERS = 'getUsers',
  SET_RECIPIENTS = 'setRecipients',
  UPDATE_USER = 'updateUser'
}

export type UsersActions = { [key in ActionKey]: ActionCreator<Action<string>> }

export type UsersThunkAction = ThunkAction<void, IUsersState, void, Action<string>>

export type UsersThunkDispatch = ThunkDispatch<IApplicationState, void, Action<string>>

export interface IUser {
  accounts_str: string
  contacts: string
  created_date: string
  created_user: ICreator
  email: string
  name: string
  oguid: string
  patronymic: string
  position: string
  surname: string
  login: string
  role: Nullable<string>
  status: string
  terminals_str: Nullable<string>
}

export interface IUsersState {
  accountsCurrentUser: ILink[]
  allUsers: IUser[]
  data: IUser[]
  editableUser: EditableUser
  recipients: IRecipient[]
}

export type EditableUser = IUserData<EmptyString<UserStatusesEnum>, EmptyString<Role>>

export interface IUserData<S, R> {
  oguid: string
  email: string
  name: string
  surname: string
  patronymic: Nullable<string>
  contacts: string
  position: Nullable<string>
  status: S
  created_user: ICreator
  created_date: string
  accounts: ILink[]
  sub_accounts: ISubAccount[]
  terminals: ITerminal[]
  role: R
  login: string
}

export interface ITerminal {
  name: string
  oguid: string
  hasError?: boolean
}

export interface ISubAccount {
  oguid: string
  account_oguid: string
  name: string
}

export interface INewUser {
  contacts: string
  email: string
  login?: string
  role: EmptyString<Role>
  name: string
  password?: Nullable<string>
  patronymic: Nullable<string>
  position: Nullable<string>
  send_invite?: boolean
  status: UserStatusesEnum
  surname: string
}

export interface IEditedUser {
  position: Nullable<string>
  status: UserStatusesEnum
  role: EmptyString<Role>
}

export interface IExistingUser {
  data: {
    contacts: string
    email: string
    login: string
    name: string
    patronymic: string
    surname: string
  }
  input: string
  oguid: string
}

export type UserRole =
  | 'ROLE_CLIENT'
  | 'ROLE_PROVIDER_ADMIN'
  | 'ROLE_PROVIDER_OPERATOR_TSD'
  | 'ROLE_PROVIDER_EMPLOYEE'
  | 'ROLE_PROVIDER_DISPATCHER'

export interface IRecipient {
  contacts?: string
  email: string
  login: string
  name: string
  oguid: string
  patronymic?: string
  position?: string
  surname: string
  role: UserRole
}

export interface IRecipientsParams {
  [FilterName.IS_CLIENTS_ONLY_FILTER]: boolean
  [FilterName.ACCOUNT_OGUID_EQ]?: string
  [FilterName.SUB_ACCOUNT_OGUID_EQ]?: string
  [FilterName.TERMINAL_OGUID_EQ]?: string
  [FilterName.ORDER_OGUID_EQ]?: string
}

export interface IUserByLoginOrEmail {
  oguid: string
  login: string
  email: string
  name: Nullable<string>
  surname: string
  patronymic: Nullable<string>
  contacts: Nullable<string>
}
