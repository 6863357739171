import { IRoute } from '@common/Routing/types'
import { routes as administration } from './views/administration'
import { routes as archive } from './views/archive'
import { routes as auth } from './views/auth'
import { routes as barcodes } from './views/barcodes'
import { routes as operations } from './views/operations'
import { routes as orders } from './views/orders'
import { routes as reports } from './views/reports'
import { routes as requests } from './views/requests'
import { routes as user } from './views/user'

export const authRoutes: IRoute[] = [...auth]

export const privateRoutes: IRoute[] = [
  ...user,
  ...administration,
  ...requests,
  ...archive,
  ...operations,
  ...orders,
  ...barcodes,
  ...reports
]
