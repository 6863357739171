import { AnyAction } from 'redux'

import { appReducer } from '@store/configureStore'
import { IApplicationState } from '@app/store/types/commonTypes'

import { USER_LOGOUT } from './types'

// дополнительная обёртка над appReducer для удобства сброса стейта в начальное состояние при логауте
export const rootReducer = (state: IApplicationState | undefined, action: AnyAction): IApplicationState => {
  if (action.type === USER_LOGOUT) {
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}
