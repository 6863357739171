import cn from 'classnames'
import React, { FC, ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { OrganizationLength, Role, RouteName } from '@const/consts'
import { IOrganization } from '@store/modules/organizations/types'
import { createUrl } from '@utils/utils'

import { IconAngle, IconSquarePlus, ILibHorizontalPosition, Popover } from '@infologistics/frontend-libraries'

import Organization from './components/Organization'
import { IApplicationState } from '@store/types/commonTypes'
import { AllServiceParams } from '@store/modules/navigation/types'

const styles = require('./Organizations.module.css')

const Organizations: FC = () => {
  const { t } = useTranslation(['organization'])
  const history = useHistory<AllServiceParams>()
  const [isTemplatePopoverOpen, setIsTemplatePopoverOpen] = useState(false)

  const organizationsList: IOrganization[] = useSelector((state: IApplicationState) => state.organizations.organizationsList)
  const activeOrganization: IOrganization = useSelector((state: IApplicationState) => state.organizations.activeOrganization)
  const isScrollExisted = organizationsList.length > 10
  const isAdmin = activeOrganization.user_role === Role.ADMIN
  const isEnableDropdown = organizationsList.length > 1 || isAdmin

  const togglePopover = (): void => {
    if (isEnableDropdown) {
      setIsTemplatePopoverOpen(!isTemplatePopoverOpen)
    }
  }

  const closePopover = (isOpenFromPopover: boolean): void => {
    if (!isOpenFromPopover) {
      setIsTemplatePopoverOpen(false)
    }
  }

  const renderOrganizationsList = (): ReactNode =>
    organizationsList.map((organization: IOrganization) => (
      <Organization key={organization.oguid} organization={organization} />
    ))

  const renderNewOrganizationButton = (): ReactNode =>
    (
      <div>
        <hr className={styles.divider} />
        <button
          className={cn(styles.new, 'd-flex full-width align-items-center px-3 py-2 pointer')}
          onClick={handlerNewOrgClick}
        >
          <IconSquarePlus size='sm' externalClass='mr-2' />
          {t('organization:new')}
        </button>
      </div>
    )

  const getShortName = (organizationName: string): string =>
    organizationName.slice(0, OrganizationLength.name.MAX_LENGTH)

  const handlerNewOrgClick = (): void => {
    const { alias, oguid } = activeOrganization

    setIsTemplatePopoverOpen(false)
    return history.push(createUrl(alias || oguid, RouteName.ADD_ORG))
  }

  return (
    <Popover
      containerClasses={cn(styles.container)}
      buttonComponent={
        <button
          title={activeOrganization.name}
          className={cn(
            styles.name,
            'd-flex align-items-center pl-3',
            isEnableDropdown ? 'pointer' : styles.no_pointer
          )}
          onClick={togglePopover}
        >
          {getShortName(activeOrganization.name)}
          {isEnableDropdown && <IconAngle size='xs' color='brand-500' rotation={isTemplatePopoverOpen ? '180' : '0'} externalClass='ml-2 mt-1' />}
        </button>
      }
      withoutArrow
      horizontalPosition={ILibHorizontalPosition.LEFT}
      parentManagement={{
        isOpenFromParent: isTemplatePopoverOpen,
        callbackForClose: closePopover
      }}
    >
      <div className={cn({ [styles.scroll]: isScrollExisted }, 'scrollbar-content')}>
        <ul className={cn(styles.list, 'list-unstyled')}>{renderOrganizationsList()}</ul>
      </div>
      {isAdmin && renderNewOrganizationButton()}
    </Popover>
  )
}

export default Organizations
