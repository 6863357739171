import { AxiosError, AxiosResponse } from 'axios'
import { CachedFilter, ETagCode, paramsForFilters, SuccessCode } from '@const/consts'
import { IAxiosErrorData, IParams } from '@store/types/commonTypes'
import {
  cacheFilters, getFilterEtags,
  getUserInfo, showErrorNotification
} from '@utils/utils'
import accountsService from '@services/accounts'
import groupsService from '@services/groups'
import ordersService from '@services/orders'
import requestsService from '@services/requests'
import terminalsService from '@services/terminals'
import usersService from '@services/users'
import { ETag } from '@common/cache/types'

export const getAllUsersForCache = (params: IParams, headers?: ETag): Promise<AxiosResponse> =>
  usersService.getAllUsers(params, headers)
    .then(resp => {
      if (resp.status === SuccessCode.GET) {
        cacheFilters(getUserInfo(), CachedFilter.USERS, resp)
      }

    return resp
  })
    .catch((error: AxiosError) => Promise.reject(error))

export const getAccountsForCache = (params: IParams,  headers?: ETag): Promise<AxiosResponse> =>
  accountsService.getAccounts(params, headers)
    .then(resp => {
      if (resp.status === SuccessCode.GET) {
        cacheFilters(getUserInfo(), CachedFilter.ACCOUNTS, resp)
      }

    return resp
  })
    .catch((error: AxiosError) => Promise.reject(error))

export const getGroupsForCache = (params: IParams,  headers?: ETag): Promise<AxiosResponse> =>
  groupsService.getGroups(params, headers)
    .then(resp => {
      if (SuccessCode.GET === resp.status) {
        cacheFilters(getUserInfo(), CachedFilter.GROUPS, resp)
      }

      return resp
    })
    .catch((error: AxiosError) => Promise.reject(error))

export const getTerminalsForCache = (params: IParams, headers?: ETag): Promise<AxiosResponse> =>
  terminalsService.getTerminals(params, headers)
    .then(resp => {
      if (resp.status === SuccessCode.GET) {
        cacheFilters(getUserInfo(), CachedFilter.TERMINALS, resp)
      }

    return resp
  })
    .catch((error: AxiosError) => Promise.reject(error))

export const getRequestTypesForCache = (params: IParams, headers?: ETag): Promise<AxiosResponse> =>
  requestsService.getRequestTypes(params, headers)
    .then(resp => {
      if (resp.status === SuccessCode.GET) {
        cacheFilters(getUserInfo(), CachedFilter.REQUEST_TYPES, resp)
      }

    return resp
  })
    .catch((error: AxiosError) => Promise.reject(error))

export const getOrderTypesForCache = (params: IParams, headers?: ETag): Promise<AxiosResponse> =>
  ordersService.getOrderTypes(params, headers)
    .then(resp => {
      if (resp.status === SuccessCode.GET) {
        cacheFilters(getUserInfo(), CachedFilter.ORDER_TYPES, resp)
      }

    return resp
  })
    .catch((error: AxiosError) => Promise.reject(error))

export const getDataForFilters = (filters: CachedFilter[], user: string, isClean?: boolean): void => {
  const filterEtags = getFilterEtags(
    [
      CachedFilter.ACCOUNTS,
      CachedFilter.TERMINALS,
      CachedFilter.REQUEST_TYPES,
      CachedFilter.USERS,
      CachedFilter.ORDER_TYPES,
      CachedFilter.GROUPS
    ],
    user
  )

  const filterRequests = []

  if (filters.includes(CachedFilter.USERS)) {
    filterRequests.push(getAllUsersForCache(
      { ...paramsForFilters, 'status.eq': 'active'},
      !isClean ? filterEtags[CachedFilter.USERS] : undefined
    ))
  }

  if (filters.includes(CachedFilter.TERMINALS)) {
    filterRequests.push(getTerminalsForCache(
      paramsForFilters,
      !isClean ? filterEtags[CachedFilter.TERMINALS] : undefined
    ))
  }

  if (filters.includes(CachedFilter.ACCOUNTS)) {
    filterRequests.push(getAccountsForCache(
      paramsForFilters,
      !isClean ? filterEtags[CachedFilter.ACCOUNTS] : undefined
    ))
  }

  if (filters.includes(CachedFilter.GROUPS)) {
    filterRequests.push(getGroupsForCache(
      paramsForFilters,
      !isClean ? filterEtags[CachedFilter.GROUPS] : undefined
    ))
  }

  if (filters.includes(CachedFilter.REQUEST_TYPES)) {
    filterRequests.push(getRequestTypesForCache(
      paramsForFilters,
      !isClean ? filterEtags[CachedFilter.REQUEST_TYPES] : undefined
    ))
  }

  if (filters.includes(CachedFilter.ORDER_TYPES)) {
    filterRequests.push(
      getOrderTypesForCache(
        paramsForFilters,
        !isClean ? filterEtags[CachedFilter.ORDER_TYPES] : undefined
      ))
  }

  Promise.all(filterRequests).catch((error: AxiosError<IAxiosErrorData>) => {
    if (error.response && error.response.status !== ETagCode.NOT_MODIFIED) showErrorNotification(error)
  })
}
