import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import Header from '@common/layout/components/Header'
import { Navigation } from '@common/navigation'
import { IApplicationState } from '@app/store/types/commonTypes'

const Menu: FC = () => {
  const areMenusShown: boolean = useSelector((state: IApplicationState) => state.utils.areMenusShown)

  return (
    <>
      <Header />
      {areMenusShown ? <Navigation /> : null}
    </>
  )
}

export default Menu
