import { Action, ActionCreator } from 'redux'
import { AxiosError, AxiosResponse } from 'axios'

import {
  IActionSetServiceCalcAlgorithms,
  IServiceCalcAlgorithm,
  MetadataActions,
  MetadataActionTypes,
  MetadataThunkAction,
  MetadataThunkDispatch
} from '@store/modules/metadata/types'
import servicesService from '@services/services'

import { SuccessCode } from '@const/consts'

const setServiceCalcAlgorithmsAction: ActionCreator<Action> = (
  data: IServiceCalcAlgorithm[]
): IActionSetServiceCalcAlgorithms => ({
  payload: data,
  type: MetadataActionTypes.SET_SERVICE_CALC_ALGORITHMS
})

export const actions: MetadataActions = {
  setServiceCalcAlgorithms: setServiceCalcAlgorithmsAction
}

export const getServicesCalcAlgorithms: ActionCreator<MetadataThunkAction> = () =>
  (dispatch: MetadataThunkDispatch): Promise<AxiosResponse> =>
    servicesService.getServicesCalcAlgorithms()
    .then((resp: AxiosResponse<IServiceCalcAlgorithm[]>) => {
      if (SuccessCode.GET === resp.status) {
        dispatch(actions.setServiceCalcAlgorithms(resp.data))
      }

      return resp
    })
    .catch((error: AxiosError) => Promise.reject(error))
