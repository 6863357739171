import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Link, NavLinkProps } from 'react-router-dom'

import { IApplicationState, Nullable } from '@store/types/commonTypes'
import { compileOrgLink, createPath } from '@utils/utils'

const OrganizationLink: FC<NavLinkProps> = (props: NavLinkProps) => {
  const { to, ...rest } = props
  const authorized: boolean = useSelector((state: IApplicationState) => state.user.isAuthenticated)
  const alias: Nullable<string> = useSelector((state: IApplicationState) => state.organizations.activeOrganization.alias)
  const oguid: string = useSelector((state: IApplicationState) => state.organizations.activeOrganization.oguid)

  if (!authorized) return <Link to={to} {...rest} />

  const link = compileOrgLink(alias, oguid)
  return <Link to={createPath(link, to)} {...rest} />
}

export default OrganizationLink
