import { initial_draft_objects, RequestInitialState } from '@store/modules/requests/initialState'
import { Actions, IRequest, IRequestsState, RequestsActionTypes } from '@store/modules/requests/types'

export function requestsReducer (state: IRequestsState = RequestInitialState, action: Actions): IRequestsState {
  const { data } = state
  switch (action.type) {
    case RequestsActionTypes.SET_REQUESTS: {
      return { ...state, data: [...action.payload] }
    }
    case RequestsActionTypes.GET_REQUEST: {
      return { ...state, activeRequest: action.payload }
    }
    case RequestsActionTypes.SET_REQUEST: {
      return { ...state, activeRequest: action.payload }
    }
    case RequestsActionTypes.GET_REQUEST_DRAFT: {
      const draftObjects = action.payload.draft_objects ?? initial_draft_objects

      return { ...state, activeRequest: {...action.payload, draft_objects: draftObjects }}
    }
    case RequestsActionTypes.RESET_REQUEST: {
      return {
        ...state,
        activeRequest: { ...RequestInitialState.activeRequest },
        errorField: { ...RequestInitialState.errorField }
      }
    }
    case RequestsActionTypes.TOGGLE_ERROR_FIELD: {
      return { ...state, errorField: action.payload }
    }
    case RequestsActionTypes.SET_REQUEST_TIMESTAMP: {
      return { ...state, loadingTimestamp: action.payload }
    }
    case RequestsActionTypes.SET_REQUEST_TYPES: {
      return { ...state, types: [...action.payload] }
    }
    case RequestsActionTypes.DELETE_REQUEST: {
      const filteredRequests = data.filter((request: IRequest) => request.oguid !== action.payload)
      return { ...state, data: [...filteredRequests] }
    }
    case RequestsActionTypes.CHANGE_ACTIVE_ITEM: {
      return { ...state, draftData: action.payload }
    }
    case RequestsActionTypes.TOGGLE_TABLE: {
      return { ...state, draftData: action.payload }
    }
    case RequestsActionTypes.DELETE_REQUEST_DRAFT: {
      return { ...state, activeRequest:  { ...RequestInitialState.activeRequest } }
    }
    case RequestsActionTypes.SET_COMMENTS: {
      return { ...state, comments: [...action.payload] }
    }
    case RequestsActionTypes.DELETE_COMMENTS: {
      return { ...state, comments: RequestInitialState.comments }
    }
    case RequestsActionTypes.SET_OGUID: {
      return { ...state, oguid: action.payload }
    }
    case RequestsActionTypes.RESET_OGUID: {
      return { ...state, oguid: RequestInitialState.oguid }
    }
    case RequestsActionTypes.SET_REQUEST_HISTORY: {
      return { ...state, history: [...action.payload] }
    }
    default:
      return state
  }
}
