import { AxiosRequestConfig, AxiosResponse } from 'axios'

import AbstractHttpService from '../abstractHttpService'

import urls from '@const/urls'
import { IParams, ISearchParams, ISuccessfulRequest } from '@store/types/commonTypes'
import {
  INewObject,
  IObject,
  IObjectHistoryElement, IObjectsWithStatuses,
  IUpdatedObjects,
  IUpdateObject
} from '@store/modules/objects/types'

class ObjectsService extends AbstractHttpService {
  private readonly url = urls.objects

  public async getObject (objectOguid: string): Promise<AxiosResponse<IObject>> {
    const url = this.url.single.replace('{{ oguid }}', objectOguid)

    return await this._http.get(url)
  }

  public async getObjects (params: IParams | ISearchParams): Promise<AxiosResponse<IObject[]>> {
    const url = this.url.list
    const config: AxiosRequestConfig = { params }

    return await this._http.get(url, config)
  }

  public async getRequestObjects (
    params: IParams,
    requestOguid: string
  ): Promise<AxiosResponse<IObjectsWithStatuses>> {
    const config: AxiosRequestConfig = { params }
    const url = urls.requests.objects.replace('{{ oguid }}', requestOguid)

    return await this._http.get(url, config)
  }

  public async getObjectsWithStatuses (url: string, params: IParams): Promise<AxiosResponse<IObjectsWithStatuses>> {
    const config: AxiosRequestConfig = { params }

    return await this._http.get(url, config)
  }

  public async getFile (objectOguid: string): Promise<AxiosResponse<Blob>> {
    const url = this.url.file.single.replace('{{ oguid }}', objectOguid)
    const config: AxiosRequestConfig = { responseType: 'blob' }

    return await this._http.get(url, config)
  }

  public async sendFile (data: any, objectOguid: string): Promise<AxiosResponse<Blob>> {
    const url = this.url.file.single.replace('{{ oguid }}', objectOguid)

    return await this._http.post(url, data)
  }

  public async getTemplate (params: ISearchParams): Promise<AxiosResponse<Blob>> {
    const url = this.url.file.template
    const config: AxiosRequestConfig = { responseType: 'blob', params }

    return await this._http.get(url, config)
  }

  public async exportArchiveObjects (filters: object): Promise<AxiosResponse<Blob>> {
    const url = this.url.file.list
    const config: AxiosRequestConfig = { params: filters, responseType: 'blob' }

    return await this._http.get(url, config)
  }

  public async addObject (url: string, data: INewObject[]): Promise<AxiosResponse<ISuccessfulRequest>> {
    return await this._http.post(url, data)
  }

  public async updateObject (objectOguid: string, data: IUpdateObject): Promise<AxiosResponse<ISuccessfulRequest>> {
    const url = this.url.single.replace('{{ oguid }}', objectOguid)

    return await this._http.put(url, data)
  }

  public async updateObjects (data: any): Promise<AxiosResponse<IUpdatedObjects>> {
    const url = this.url.file.list

    return await this._http.put(url, data)
  }

  public async deleteObject (url: string, data?: string[]): Promise<AxiosResponse<ISuccessfulRequest>> {
    const config: AxiosRequestConfig = { data }

    return await this._http.delete(url, config)
  }

  public async getObjectHistory (objectOguid: string, params: IParams): Promise<AxiosResponse<IObjectHistoryElement[]>> {
    const url = this.url.history.replace('{{ oguid }}', objectOguid)
    const config: AxiosRequestConfig = { params }

    return await this._http.get(url, config)
  }
}

export default new ObjectsService()
