import { Action, ActionCreator, Dispatch } from 'redux'

import store from '@store/configureStore'
import { IObject } from '@store/modules/objects/types'

import {
  CartActionTypes,
  IActionAddCartItem,
  IActionCleanCart,
  IActionRemoveCartItem,
  IActionUpdateCartItem
} from './types'

export const removeCartItem: ActionCreator<Action> = (id: string): IActionRemoveCartItem => ({
  payload: id,
  type: CartActionTypes.REMOVE_CART_ITEM
})

export const cleanCart: ActionCreator<Action> = (): IActionCleanCart => ({
  type: CartActionTypes.CLEAN_CART
})

export const addCartItem: ActionCreator<Action> = (data: IObject[]): IActionAddCartItem => ({
  payload: data,
  type: CartActionTypes.ADD_CART_ITEM
})

const updateCartItemAction: ActionCreator<Action> = (data: any): IActionUpdateCartItem => ({
  payload: data,
  type: CartActionTypes.UPDATE_CART_ITEM
})

export const updateCartItem = (oguid: string, object: IObject) => {
  return (dispatch: Dispatch) => {
    const state = store.getState().cart
    const payload = state.map((item: IObject) => (item.oguid === oguid ? object : item))

    dispatch(updateCartItemAction(payload))
  }
}
