import { Action, ActionCreator } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'

import { ILibPagination } from '@infologistics/frontend-libraries'

import { IApplicationState, ILink, Nullable } from '@store/types/commonTypes'
import * as IObjectsActions from './typesActions'
import { ObjectType } from '@const/consts'

export enum ObjectsActionTypes {
  RESET_OBJECTS = 'RESET_OBJECTS',
  OBJECT_CHECK = 'OBJECT_CHECK',
  RESET_UPDATED_OBJECTS = 'RESET_UPDATED_OBJECTS',
  SEND_FILE = 'SEND_FILE',
  SET_OBJECTS = 'SET_OBJECTS',
  SET_OBJECT_HISTORY = 'SET_OBJECT_HISTORY',
  SWITCH_FOCUS = 'SWITCH_FOCUS',
  UPDATE_OBJECTS = 'UPDATE_OBJECTS',
  SET_QUANTITY = 'SET_QUANTITY',
  SET_STATUSES = 'SET_STATUSES',
  SET_OBJECT = 'SET_OBJECT'
}

export type Actions =
  | IObjectsActions.IActionResetObjects
  | IObjectsActions.IActionResetUpdatedObjects
  | IObjectsActions.IActionSendFile
  | IObjectsActions.IActionSetObjectHistory
  | IObjectsActions.IActionSetObjects
  | IObjectsActions.IActionUpdateObjects
  | IObjectsActions.IActionSetQuantity
  | IObjectsActions.IActionSetStatuses
  | IObjectsActions.IActionSetObject

type ActionKeys =
  | 'resetObjects'
  | 'resetUpdatedObjects'
  | 'sendFile'
  | 'setObjectHistory'
  | 'setObjects'
  | 'updateObjects'
  | 'setQuantity'
  | 'setStatuses'
  | 'setObject'

export type ObjectsActions = { [key in ActionKeys]: ActionCreator<Action<string>> }

export type ObjectsThunkAction = ThunkAction<void, IObjectsState, void, Action<string>>

export type ObjectsThunkDispatch = ThunkDispatch<IApplicationState, void, Action<string>>

export interface IObjectsState {
  data: IObject[]
  quantity: IQuantity
  objectHistory: IObjectHistoryElement[]
  paginationHistory: ILibPagination
  updatedObjects: IUpdatedObjects
  statuses: IStatuses
  editedObject: IObject
}

export interface IUpdatedObjects {
  not_found: number
  updated: number
}

export interface IObject {
  oguid: string
  barcode: string
  alt_barcode: string
  object_type: ObjectType
  object_transport: {
    oguid: string
    barcode: string
  }
  stationary_location: string
  status?: string
  seal1: Nullable<string>
  seal2: Nullable<string>
  location: string
  is_connected: boolean
  state: string
  state_date: number
  first_state_in_date: number
  terminate_date: Nullable<number>
  notes: string
  quantity: number
  account: ILink
  sub_account: ILink
  terminal: ILink
  base_terminal: ILink
  parent: {
    oguid: string
    barcode: string
  }
  filename: string
  path: string
  fields: IFields | Object
}

export interface IData {
  alt_barcode: string
  notes: string
  quantity: number
  account: string
  sub_account: string
  terminal: string
}

export interface INewObject {
  alt_barcode?: Nullable<string>
  barcode?: Nullable<string>
  notes?: Nullable<string>
  object_type?: Nullable<string>
  parent?: Nullable<string>
  quantity?: Nullable<number>
}

export type INewAttachedObject = INewObject[] | Array<{ barcode: string } | Array<{ parent: string }>>

export interface IUpdateObject {
  alt_barcode: string
  barcode: string
  notes: string
  account: string
  sub_account: Nullable<string>
  quantity: number
}

export interface IObjectHistoryElement {
  oguid: string
  barcode: string
  request_id: number
  request_oguid: string
  request_create_date: number
  request_create_user: IHistoryUser
  order_id: number
  order_oguid: string
  order_create_date: number
  order_create_user: IHistoryUser
  operation_type: string
  operation_new_destination: string
  operation_date: number
  operation_user: IHistoryUser
  operation_comment: Nullable<string>
  operation_status: Nullable<OperationStatus>
  operation_old_destination: string
  operation_connected_object: string
}

enum OperationStatus {
  SUCCESS = 'success',
  ERROR = 'error'
}

interface IHistoryUser {
  oguid: string
  surname: string
  name: string
  patronymic: string
}

export interface IStatuses {
  declared: number
  confirm: number
  by_fact: number
}

export interface IQuantity {
  boxes: number
  documents: number
  folders: number
  object_transports: number
  supplies: number
}

export interface IObjectsWithStatuses {
  quantity: IQuantity
  statuses: IStatuses
  objects: IObject[]
}

export interface IFields {
  [key: string]: Nullable<number | boolean | string>
}
