import { AxiosRequestConfig, AxiosResponse } from 'axios'

import AbstractHttpService from '../abstractHttpService'

import urls from '@const/urls'
import {
  IAddServiceInRequestOrOrder,
  IAvailableService,
  IDeleteServiceInRequestOrOrder,
  IEditService,
  IEditServiceInRequestOrOrder,
  IRecalculate,
  IRecalculateAnswer,
  IService,
  IServiceInRequestAndOrder,
  QuantityType
} from '@store/modules/services/types'
import { ISuccessfulRequest } from '@store/types/commonTypes'
import { UrlKey } from '@const/consts'
import { IServiceCalcAlgorithm } from '@store/modules/metadata/types'

class ServicesService extends AbstractHttpService {
  private readonly url = urls.services

  public async getServices (config?: AxiosRequestConfig): Promise<AxiosResponse<IService[]>> {
    const url = this.url.list

    return await this._http.get(url, config)
  }

  public async getService (serviceOguid: string): Promise<AxiosResponse<IService>> {
    const url = this.url.single.replace('{{ oguid }}', serviceOguid)

    return await this._http.get(url)
  }

  public async addService (data: IEditService): Promise<AxiosResponse<ISuccessfulRequest>> {
    const url = this.url.list

    return await this._http.post(url, data)
  }

  public async editService (serviceOguid: string, data: IEditService): Promise<AxiosResponse<ISuccessfulRequest>> {
    const url = this.url.single.replace('{{ oguid }}', serviceOguid)

    return await this._http.put(url, data)
  }

  public async deleteService (serviceOguid: string): Promise<AxiosResponse<ISuccessfulRequest>> {
    const url = this.url.single.replace('{{ oguid }}', serviceOguid)

    return await this._http.delete(url)
  }

  public async recalculateServices (data: IRecalculate): Promise<AxiosResponse<IRecalculateAnswer>> {
    const url = this.url.calculate

    return await this._http.post(url, data)
  }

  public async getServicesCalcAlgorithms (): Promise<AxiosResponse<IServiceCalcAlgorithm[]>> {
    const url = this.url.calc_algorithms

    return await this._http.get(url)
  }

  public async getServicesInRequestAndOrder (
    urlKey: UrlKey,
    oguid?: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<Array<IServiceInRequestAndOrder<QuantityType>>>> {
    const url = oguid
      ? urls[urlKey].services.list.replace('{{ oguid }}', oguid)
      : urls.requests.draft.services

    return await this._http.get(url, config)
  }

  public async getAvailableServices (
    urlKey: UrlKey,
    oguid?: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<IAvailableService[]>> {
    const urlTemp = urls[urlKey].available_services
    const url = oguid ? urlTemp.replace('{{ oguid }}', oguid) : urlTemp

    return await this._http.get(url, config)
  }

  public async addServiceInRequestOrOrder ({
    urlKey,
    oguid,
    data
  }: IAddServiceInRequestOrOrder): Promise<AxiosResponse<ISuccessfulRequest>> {
    const url = oguid
      ? urls[urlKey].services.list.replace('{{ oguid }}', oguid)
      : urls.requests.draft.services

    return await this._http.post(url, data)
  }

  public async editServiceInRequestOrOrder ({
    urlKey,
    oguid,
    serviceOguid,
    data
  }: IEditServiceInRequestOrOrder): Promise<AxiosResponse<ISuccessfulRequest>> {
    const urlTemp = urls[urlKey].services.single
    const url = oguid
      ? urlTemp.replace('{{ oguid }}', oguid).replace('{{ service_oguid }}', serviceOguid)
      : urlTemp.replace('{{ service_oguid }}', serviceOguid)

    return await this._http.put(url, data)
  }

  public async deleteServiceInRequestOrOrder ({
    urlKey,
    oguid,
    serviceOguid
  }: IDeleteServiceInRequestOrOrder): Promise<AxiosResponse<ISuccessfulRequest>> {
    const urlTemp = urls[urlKey].services.single
    const url = oguid === ''
      ? urlTemp.replace('{{ service_oguid }}', serviceOguid)
      : urlTemp.replace('{{ oguid }}', oguid).replace('{{ service_oguid }}', serviceOguid)

    return await this._http.delete(url)
  }
}

export default new ServicesService()
