import { Action } from 'redux'

export enum LoaderActionTypes {
  SHOW_LOADER = 'SHOW_LOADER',
  CLOSE_LOADER = 'CLOSE_LOADER'
}

export interface IActionShowLoader extends Action<string> {
  type: LoaderActionTypes.SHOW_LOADER
}

export interface IActionCloseLoader extends Action<string> {
  type: LoaderActionTypes.CLOSE_LOADER
}

export type Actions = IActionShowLoader | IActionCloseLoader

export interface ILoaderState {
  isLoading: boolean
}
