import { IUser } from '@store/modules/users/types'
import { IServiceCalcAlgorithm } from '../metadata/types'
import {
  AccountsActionTypes,
  Actions,
  IAccountData,
  IAccountsState,
  IAddress,
  IEditedAccountService,
  IEditedCustomField,
  ISubAccount
} from './types'

export const calcAlgorithm: IServiceCalcAlgorithm = {
  code: '',
  name: '',
  category: ''
}

export const accountService: IEditedAccountService = {
  oguid: '',
  service_oguid: '',
  quantity: null,
  unit_price: null,
  min_sum: null,
  date_from: null,
  unit_price_discounts: null,
  service_calc_algorithm: { ...calcAlgorithm },
  service_code: '',
  service_name_invoice: null,
  contract_name: null,
  contract_number: null,
  contract_date: null
}

export const initialCustomField: IEditedCustomField = {
  object_type: '',
  field_code: '',
  field_caption: '',
  field_type: '',
  sort_order: null,
  oguid: ''
}

const initialState: IAccountsState = {
  data: [],
  account_service: { ...accountService },
  account_services: [],
  sub_accounts: [],
  users_list: [],
  users_select: [],
  custom_fields: [],
  custom_field: { ...initialCustomField },
  active_handbook: '',
  dictionaries: [],
  object_account_dictionaries: {}
}

export function accountsReducer (state: IAccountsState = initialState, action: Actions): IAccountsState {
  const { data, sub_accounts, users_list, users_select } = state
  switch (action.type) {
    case AccountsActionTypes.SET_ACCOUNTS:
      return { ...state, data: [...action.payload] }

    case AccountsActionTypes.SET_ACCOUNT:
      return { ...state, data: [action.payload] }

    case AccountsActionTypes.REMOVE_ADDRESS_ACCOUNT: {
      const copyAccount = data.slice()

      copyAccount[0].addresses = copyAccount[0].addresses.filter(
        (address: any) => address.oguid !== action.payload.oguid
      )

      return {
        ...state,
        data: [copyAccount[0]]
      }
    }

    case AccountsActionTypes.ADD_ACCOUNT:
      return { ...state, data: [...data, action.payload] }

    case AccountsActionTypes.ADD_ADDRESS_ACCOUNT: {
      const newAddressAccount = data.slice()
      newAddressAccount[0].addresses.push(action.payload)

      return { ...state, data: [newAddressAccount[0]] }
    }

    case AccountsActionTypes.ADD_USER_VALID_TO_ACCOUNT: {
      const filteredUsersSelect = users_select.filter((item: IUser) => item.oguid !== action.payload[0].oguid)
      const newUsersList = users_list.slice()
      newUsersList.push(action.payload[0])

      return { ...state, users_list: newUsersList, users_select: filteredUsersSelect }
    }

    case AccountsActionTypes.REMOVE_USER_FROM_ACCOUNT: {
      const filteredUsersList = users_list.filter((item: IUser) => item.oguid !== action.payload.oguid)
      const newUsersSelect = users_select.slice()
      newUsersSelect.push(action.payload)

      return { ...state, users_list: filteredUsersList, users_select: newUsersSelect }
    }

    case AccountsActionTypes.UPDATE_ACCOUNT: {
      const accounts = data.map((item: IAccountData) => {
        if (item.oguid !== action.payload.oguid) {
          return item
        }
        return { ...item, ...action.payload }
      })
      return { ...state, data: [...accounts] }
    }

    case AccountsActionTypes.UPDATE_ADDRESS_ACCOUNT: {
      const editAddressAccount = data.slice()

      const index = editAddressAccount[0].addresses.findIndex((item: IAddress) => item.oguid === action.payload.oguid)
      if (index !== -1) {
        editAddressAccount[0].addresses[index] = action.payload
      }

      return { ...state, data: [editAddressAccount[0]] }
    }

    case AccountsActionTypes.SET_USERS_ACCOUNT: {
      const usersArr = action.payload.resp.data
      if (action.payload.isParamsEq) {
        return {
          ...state,
          users_list: usersArr
        }
      } else {
        return {
          ...state,
          users_select: usersArr
        }
      }
    }

    case AccountsActionTypes.SET_SUBACCOUNTS:
      return { ...state, sub_accounts: [...action.payload] }

    case AccountsActionTypes.SET_SUBACCOUNT:
      return { ...state, sub_accounts: [action.payload] }

    case AccountsActionTypes.REMOVE_SUBACCOUNT: {
      const filteredSubAccounts = sub_accounts.filter((item: ISubAccount) => item.oguid !== action.payload.oguid)
      return { ...state, sub_accounts: filteredSubAccounts }
    }

    case AccountsActionTypes.ADD_SUBACCOUNT:
      return { ...state, sub_accounts: [...sub_accounts, action.payload] }

    case AccountsActionTypes.UPDATE_SUBACCOUNT: {
      const subAccounts = sub_accounts.map((item: ISubAccount) => {
        if (item.oguid !== action.payload.oguid) {
          return item
        }
        return { ...item, ...action.payload }
      })
      return { ...state, sub_accounts: [...subAccounts] }
    }

    case AccountsActionTypes.CLEAN_SUB_ACCOUNTS: {
      return { ...state, sub_accounts: [] }
    }

    case AccountsActionTypes.SET_ACCOUNT_SERVICES: {
      return { ...state, account_services: [...action.payload] }
    }

    case AccountsActionTypes.SET_ACCOUNT_SERVICE: {
      return { ...state, account_service: { ...action.payload } }
    }

    case AccountsActionTypes.SET_ACCOUNT_CUSTOM_FIELD: {
      return { ...state, custom_field: { ...action.payload } }
    }

    case AccountsActionTypes.SET_ACCOUNT_CUSTOM_FIELDS: {
      return { ...state, custom_fields: [...action.payload] }
    }

    case AccountsActionTypes.CLEAN_ACCOUNT_CUSTOM_FIELDS: {
      return { ...state, custom_fields: [] }
    }

    case AccountsActionTypes.SET_ACCOUNT_ACTIVE_HANDBOOK: {
      return { ...state, active_handbook: action.payload }
    }

    case AccountsActionTypes.SET_ACCOUNT_DICTIONARIES: {
      return { ...state, dictionaries: [...action.payload] }
    }

    case AccountsActionTypes.SET_OBJECT_ACCOUNT_DICTIONARIES: {
      const object_account_dictionaries = { ...state.object_account_dictionaries, ...action.payload }

      return { ...state, object_account_dictionaries }
    }

    case AccountsActionTypes.CLEAN_OBJECT_ACCOUNT_DICTIONARIES: {
      return { ...state, object_account_dictionaries: { ...initialState.object_account_dictionaries } }
    }

    default:
      return state
  }
}
