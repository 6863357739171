import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import { Layout as LayoutLibrary, Loader } from '@infologistics/frontend-libraries'

import { privateRoutes } from '@app/routes'
import { RenderRoutes } from '@common/Routing/RenderRoutes'
import { EmptyString, IApplicationState } from '@store/types/commonTypes'
import { modifyRoutes } from '@utils/utils'
import { Role } from '@const/consts'

export const Layout: FC = () => {
  const isLoading: boolean = useSelector((state: IApplicationState) => state.loader.isLoading)
  const areMenusShown: boolean = useSelector((state: IApplicationState) => state.utils.areMenusShown)
  const language: string = useSelector((state: IApplicationState) => state.utils.language)
  const role: EmptyString<Role> = useSelector((state: IApplicationState) => state.organizations.activeOrganization.user_role)
  const widthClass = areMenusShown ? undefined : 'fl-layout-full'

  return (
    <LayoutLibrary externalClass={widthClass}>
      <RenderRoutes key={language} routes={modifyRoutes(privateRoutes, role)} />
      <Loader loading={isLoading} />
    </LayoutLibrary>
  )
}

export default Layout
